import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { likesAddRequest, likesRemoveRequest } from '../../redux/actions/likesActions';
import "./style.scss";

const LikeButton = ({item}) => {

    const [isLiked, setIsLiked] = useState(false);
    const [likesQtd, setLikesQtd] = useState();
    const likedPosts = useSelector((state) => state.likedPosts);
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const dispatch = useDispatch();

    useEffect(() => {
        setLikesQtd(item.likes)
    },[item])

    useEffect(() => {
        if(!userInfo){
            return
        }

        const checkLikedPost = likedPosts.filter((like) => {
            return like.id === item.id
        })
        if(checkLikedPost[0]){
            setIsLiked(true)
        }
    }, [likedPosts, item, userInfo])

    const handleRemoveLike = () => {
        dispatch(likesRemoveRequest(item, userInfo.token));
        setIsLiked(false);
        setLikesQtd(likesQtd -1);
    }
    const handleAddLike = () => {
        dispatch(likesAddRequest(item, userInfo.token));
        setIsLiked(true);
        setLikesQtd(likesQtd + 1);
    }

    return (
        <div className="like-button">
            {   userInfo ?
                isLiked ?
                <>
                    <AiFillLike onClick={() => {handleRemoveLike()}}/>
                    <span className="qtd">{likesQtd}</span>
                </>
                :
                <>
                    <AiOutlineLike onClick={() => {handleAddLike()}}/>
                    <span className="qtd">{likesQtd}</span>
                </>
                :
                <>
                    <AiFillLike />
                    <span className="qtd">{likesQtd}</span>
                </>
            }
        </div>
    )
}

export default LikeButton;