import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import "./styles.scss";

function Socials({ color = "#a1793f", link, text }) {
  const defaultFacebook = "https://www.facebook.com/MundoAkar/";
  const defaultTwitter = "https://twitter.com/?lang=pt-br";
  const encodedLink = encodeURI(link);
  const encodedText = encodeURIComponent(text);

  return (
    <div className="socials" style={{ color: color }}>
      <a
        href={
          link
            ? `https://www.facebook.com/sharer.php?u=${encodedLink}`
            : defaultFacebook
        }
        target="_blank"
        rel="noopener noreferrer"
        className="facebook"
        style={{ border: `2px solid ${color}` }}
      >
        <FaFacebookF size={20} color={color} />
      </a>
      <a
        href="https://www.instagram.com/mundo_akar/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        className="instagram"
        style={{ border: `2px solid ${color}` }}
      >
        <FaInstagram size={20} color={color} />
      </a>
      <a
        href={
          link
            ? `https://twitter.com/intent/tweet?url=${link}&text=${encodedText}`
            : defaultTwitter
        }
        className="twitter"
        target="_blank"
        rel="noopener noreferrer"
        style={{ border: `2px solid ${color}` }}
      >
        <FaTwitter size={20} color={color} />
      </a>
      <a
        href={
          link
            ? `https://api.whatsapp.com/send?phone=5585981651765&text=${encodedText} no link ${link}`
            : 'https://api.whatsapp.com/send?phone=5585981651765&text=Obrigada por contatar o Mundo Akar. Retornaremos em breve.⏰Nosso expediente é de 2ª à 6ª feira, de 8h às 20h e aos sábados de de 8h às 16h (horário de Brasília).'
        }
        className="whatsapp"
        target="_blank"
        rel="noopener noreferrer"
        style={{ border: `2px solid ${color}` }}
      >
        <FaWhatsapp size={20} color={color} />
      </a>
    </div>
  );
}

export default Socials;
