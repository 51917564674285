import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from 'react-redux';
import { getDate, getYear, getMonth } from 'date-fns';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import Service from '../../components/service';
import Loading2 from '../../components/loading2';
import { Constants } from "../../constants";
import "./style.scss";
import Advertisement from '../../components/advertisement';
import AccessControll from '../../services/access';


class SocialSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            date: "",
            loading: true,
        }
    }

    componentDidMount = () => {
        this.getServices();
        this.isTuesday();
        AccessControll('Agenda Social')
    }

    isTuesday = () => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        let day = new Date().getDate();
        while(new Date(year, month,  day).getDay() !== 2){
            day += 1
        }
        const date = new Date(year, month,  day)
        .toLocaleString('pt-BR', {dateStyle: "full"})
        .slice(0, -8)
        this.setState({
            date
        })
    }
    shuffleArray = (array) => {
        const aux = array;
        for (let i = aux.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [aux[i], aux[j]] = [aux[j], aux[i]];
        }
        return aux
    }
    getServices = async () => {
        const date = new Date();
        const year = getYear(date);
        const month = getMonth(date);
        const day = getDate(date);

        const config = {
            params: {
                year,
                month: month +1,
                day,
            }
        }


        await axios
            .get(Constants.ApiUrl + '/socialSchedules/services', config)
            .then((response) => {
                this.setState({
                    services: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {

        return (
            <div id="social-schedule">
                <Header />
                <Hero
                    center={true}
                    image={require("../../assets/images/main-image-withback.png")}
                    h1={"Faça já o seu agendamento!"}
                />

                <div className={"blog-box"}>
                    
                    <h3 >Atendimentos</h3>
                    <span className="week-day">{this.state.date}</span>
                    <div id="services" className="service-box" ref={ref => {
                            this.$ref = ref;
                        }}>
                        {this.state.loading ? (
                                <Loading2 loading={this.state.loading} />
                            ):
                            (
                                <div className={"attendance"}>
                                    {  this.state.services.length > 0 ?
                                        this.state.services.map((service) => {
                                            return (
                                                <Link key={service.id} to={{
                                                    pathname: "/atendimento-social",
                                                    state: {...service}
                                                }}>
                                                    <Service title={service.name} img={service.image_url} id={service.id}/>
                                                </Link>
                                            )
                                        }) : (
                                            <span className="attendance-text">Sem atendimentos disponíveis</span>
                                        )
                                    }
                                </div>
                    
                            )}
                    </div>
                    
                </div>

                <Advertisement local="social-attendance" />

                <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo
    }
}

export default connect(mapStateToProps)(SocialSchedule);