import React, { useEffect, useState } from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useSelector } from 'react-redux';
import { DatePicker,Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/header';
import { Constants } from "../../../constants";
import axios from "axios";
import Loading from '../../../components/loading';
import Button from '../../../components/button';
import "./style.scss";
const { RangePicker } = DatePicker;

const Reports = () => {

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const userData = useSelector(state => state.userLogin.userData);
    const userInfo = useSelector(state => state.userLogin.userInfo);
    const [userPlans, setUserPlans] = useState();
    const [ordersRange, setOrdersRange] = useState();
    const [schedulesRange, setSchedulesRange] = useState();
    const [valuesRange, setValuesRange] = useState();
    const [schedulesPlansRange, setSchedulesPlansRange] = useState();
    const [unfinishedSchedulesRange, setUnfinishedSchedulesRange] = useState();
    const [userTickets, setUserTickets] = useState();
    const [ticketsTotalPages, setTicketsTotalPages] = useState();

    const getUserPlans = async (user) => {

        setIsLoading(true)

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await axios.get(`${Constants.ApiUrl}/plans/user/${user.id}`, config)
                .then((response) => {
                    setUserPlans(response.data);
                    setIsLoading(false);
                })
                .catch((err) => {console.log(err); setIsLoading(false);});
    }

    const getUserTickets = async (user, page) => {

        setIsLoading(true)
        const LIMIT = 9;

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            params: {
                range: `[${(page -1)*LIMIT},${(page -1)*LIMIT + LIMIT}]`,
                sort: `["createdAt", "DESC"]`,
            }
        }

        await axios.get(`${Constants.ApiUrl}/tickets/client`, config)
                .then((response) => {
                    const range = response.headers["content-range"];
                    const total = range ? range.split('/').pop() : 0
                    setTicketsTotalPages(Math.ceil(total/10));
                    setUserTickets(response.data);
                    setIsLoading(false);
                })
                .catch((err) => {console.log(err); setIsLoading(false);});
    }

    useEffect(() => {
        getUserPlans(userInfo);
        getUserTickets(userInfo, 1)
    }, [userInfo]);

    const handleDownloadCompanyReports = async (type, array) => {
        const isoInitialDate = new Date(array[0]._d).toISOString();
        const isoFinalDate = new Date(array[1]._d).toISOString();

        const initialDateString = `${new Date(array[0]._d).getDate()}${(new Date(array[0]._d).getMonth()) + 1}${new Date(array[0]._d).getFullYear()}`;
        const finalDateString = `${new Date(array[1]._d).getDate()}${(new Date(array[1]._d).getMonth()) + 1}${new Date(array[1]._d).getFullYear()}`;

        const fileName = `report-${type}-${initialDateString}-${finalDateString}.xlsx`;

        const config = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/company?type=${type}&initial=${isoInitialDate}&final=${isoFinalDate}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                setIsLoading(false);
                console.log(err);
            }))
    }

    const handleDownloadProfessionalReports = async (type, array) => {
        const isoInitialDate = new Date(array[0]._d).toISOString();
        const isoFinalDate = new Date(array[1]._d).toISOString();

        const initialDateString = `${new Date(array[0]._d).getDate()}${(new Date(array[0]._d).getMonth()) + 1}${new Date(array[0]._d).getFullYear()}`;
        const finalDateString = `${new Date(array[1]._d).getDate()}${(new Date(array[1]._d).getMonth()) + 1}${new Date(array[1]._d).getFullYear()}`;

        const fileName = `report-${type}-${initialDateString}-${finalDateString}.xlsx`;

        const config = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            },
            responseType:'blob'
        }

        setIsLoading(true);

        await axios
            .get(`${Constants.ApiUrl}/reports/professional?type=${type}&initial=${isoInitialDate}&final=${isoFinalDate}`, config)
            .then((response) => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before.
            })
            .catch((err => {
                setIsLoading(false);
                console.log(err);
            }))
    }

    return (
        <div id="reports" >
            <Loading loading={isLoading}/>
            <Header/>
            <div className="user-box flex-vertical">
                <div className="user flex-horizontal align-center justify-between">
                    <div className="flex-horizontal">
                        <div className="user-img">
                            <img
                                alt="profile"
                                className="back"
                                src={require("../../../assets/images/solar-blue-circle.png")}
                            />
                            <img
                                alt={userData.name}
                                className="user-profile-img"
                                src={userData.image_url}
                            />
                        </div>
                        <div className="user-details">
                            <p className="title">{userData.name}</p>
                            <div className="flex-horizontal alingn-baseline">
                            <h2>Créditos</h2>
                            <h1>
                                {userData.credits
                                ? userData.credits
                                : 0}
                            </h1>
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            history.push("/minha-conta");
                        }}
                        className={"btn btn-primary"}
                        type="primary"
                    >
                    Voltar
                    </Button>
                </div>
            </div>
            
            <main className="data-container">
                <section className="plans" >
                    <h1>Planos</h1>
                    <div className="plans-container">
                    {userPlans?.map((plan) => {
                        const parsedDate = new Date(plan.plan.valid_until).toLocaleString('pt-BR', {hour12: "true", weekday: "short", day: "2-digit", month: "short"}).toUpperCase();
                        const parsedHour = new Date(plan.plan.valid_until).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});
                        return (
                            <div key={plan.id} className="plan-card">
                                <p>Plano: <span>{plan.plan.name}</span></p>
                                <p>Descrição: <span>{plan.plan.description}</span></p>
                                <p className="flex-vertical">Profissionais incluídos: 
                                    {plan.plan.professionals.map((professional, index) => {
                                        return (
                                            <span key={professional.id} >{professional.name} {professional.last_name}</span>
                                        )
                                    })}
                                </p>
                                <p className="flex-vertical">Terapias incluídas: 
                                    {plan.plan.services.map((service, index) => {
                                        return (
                                            <span key={service.id} >{service.name}</span>
                                        )
                                    })}
                                </p>
                                <p>Consultas restantes: <span>{plan.plan.quantity - plan.quantity_used}</span></p>
                                <p>Preço: <span>{plan.plan.price}</span></p>
                                <p>Validade: <span>{parsedDate} - {parsedHour}</span></p>
                            </div>
                        )
                        })
                    }
                    </div>
                </section>
                <section className="reports" style={{display: userData.role === 'company' ? '' : 'none'}}>
                    <h1>Relatórios</h1>
                    <div className="report">
                        <h2>Pedidos</h2>
                        <div className="range-picker">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    'Hoje': [moment(), moment()],
                                    'Última semana': [moment().subtract(1, 'weeks'), moment()],
                                    'Último mês': [moment().subtract(1, 'month'), moment()],
                                    'Últimos dois meses': [moment().subtract(1, 'months').startOf('month'), moment()],
                                    'Últimos três meses': [moment().subtract(2, 'months').startOf('month'), moment()],
                                }}
                                onChange={(array) => {
                                    setOrdersRange(array)
                                }}
                            />
                            <Button disabled={!ordersRange} onClick={() => {handleDownloadCompanyReports('orders', ordersRange)}} >Download</Button>
                        </div>
                    </div>
                    <div className="report">
                        <h2>Atendimentos - Mundo Akar Geral</h2>
                        <div className="range-picker">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    'Hoje': [moment(), moment()],
                                    'Última semana': [moment().subtract(1, 'weeks'), moment()],
                                    'Último mês': [moment().subtract(1, 'month'), moment()],
                                    'Últimos dois meses': [moment().subtract(1, 'months').startOf('month'), moment()],
                                    'Últimos três meses': [moment().subtract(2, 'months').startOf('month'), moment()],
                                }}
                                onChange={(array) => {
                                    setSchedulesRange(array);
                                }}
                            />
                            <Button disabled={!schedulesRange} onClick={() => {handleDownloadCompanyReports('schedules', schedulesRange)}} >Download</Button>
                        </div>
                    </div>
                </section>
                <section className="reports" style={{display: userData.role === 'professional' ? '' : 'none'}}>
                    <h1>Relatórios</h1>
                    <div className="report">
                        <h2>Valores recebidos</h2>
                        <div className="range-picker">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    'Hoje': [moment(), moment()],
                                    'Última semana': [moment().subtract(1, 'weeks'), moment()],
                                    'Último mês': [moment().subtract(1, 'month'), moment()],
                                    'Últimos dois meses': [moment().subtract(1, 'months').startOf('month'), moment()],
                                    'Últimos três meses': [moment().subtract(2, 'months').startOf('month'), moment()],
                                }}
                                onChange={(array) => {
                                    setValuesRange(array)
                                }}
                            />
                            <Button disabled={!valuesRange} onClick={() => {handleDownloadProfessionalReports('values', valuesRange)}} >Download</Button>
                        </div>
                    </div>
                    <div className="report">
                        <h2>Atendimentos pelo plano</h2>
                        <div className="range-picker">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    'Hoje': [moment(), moment()],
                                    'Última semana': [moment().subtract(1, 'weeks'), moment()],
                                    'Último mês': [moment().subtract(1, 'month'), moment()],
                                    'Últimos dois meses': [moment().subtract(1, 'months').startOf('month'), moment()],
                                    'Últimos três meses': [moment().subtract(2, 'months').startOf('month'), moment()],
                                }}
                                onChange={(array) => {
                                    setSchedulesPlansRange(array);
                                }}
                            />
                            <Button disabled={!schedulesPlansRange} onClick={() => {handleDownloadProfessionalReports('schedules-plans', schedulesPlansRange)}} >Download</Button>
                        </div>
                    </div>
                    <div className="report">
                        <h2>Consultas agendadas e não finalizadas</h2>
                        <div className="range-picker">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    'Hoje': [moment(), moment()],
                                    'Última semana': [moment().subtract(1, 'weeks'), moment()],
                                    'Último mês': [moment().subtract(1, 'month'), moment()],
                                    'Últimos dois meses': [moment().subtract(1, 'months').startOf('month'), moment()],
                                    'Últimos três meses': [moment().subtract(2, 'months').startOf('month'), moment()],
                                }}
                                onChange={(array) => {
                                    setUnfinishedSchedulesRange(array);
                                }}
                            />
                            <Button disabled={!unfinishedSchedulesRange} onClick={() => {handleDownloadProfessionalReports('unfinished-schedules', unfinishedSchedulesRange)}} >Download</Button>
                        </div>
                    </div>
                </section>
                <section className="operations">
                    <h1>Operações</h1>
                    <div className="tickets">
                        {
                            userTickets?.length > 0 ?
                            userTickets?.map((ticket) => {
                                
                                //voltar aqui e colocar as informações que a cliente pedir
                                // const ticketOrder = ticket.ticketOrder ? ticket.ticketOrder : null;
                                const ticketSocialSchedule = ticket.ticketSocialSchedule ? ticket.ticketSocialSchedule: null 
                                const ticketGeneralSchedule = ticket.ticketGeneralSchedule ? ticket.ticketGeneralSchedule : null;
                                const ticketEvent = ticket.ticketEvent ? ticket.ticketEvent : null;
                                // const ticketUsedCoupon = ticket.ticketUsedCoupon ? ticket.ticketUsedCoupon : null;
                                // const ticketUsedPlan = ticket.ticketUsedPlan ? ticket.ticketUsedPlan : null;

                                const parsedDate = new Date(ticketGeneralSchedule ? ticketGeneralSchedule.date : ticketEvent ? ticketEvent.date : ticketSocialSchedule ? ticketSocialSchedule.date : ticket.createdAt).toLocaleString('pt-BR', {hour12: "true", weekday: "short", day: "2-digit", month: "short"}).toUpperCase();
                                const parsedHour = new Date(ticketGeneralSchedule ? ticketGeneralSchedule.date : ticketEvent ? ticketEvent.date : ticketSocialSchedule ? ticketSocialSchedule.date : ticket.createdAt).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});

                                return (
                                    <div key={ticket.id} className="ticket-card">
                                        <p>Data: <span>{parsedDate} - {parsedHour}</span></p>
                                        {
                                            ticketEvent ?
                                            <p>Evento: ticketEvent.name</p>
                                            :
                                            <p>Terapia: <span>{ticketSocialSchedule ? ticketSocialSchedule.socialScheduleService.name : ticketGeneralSchedule.service_id.name}</span></p>
                                        }
                                        <p>Preço: <span>{ticket.price}</span></p>
                                        <p>Status: <span>{ticket.status}</span></p>
                                        <p>Presença: <span>{ticket.client_attended === false ? 'Não confirmada' : 'Confirmada'}</span></p>
                                        {
                                            ticket.canceled === true ?
                                            <>
                                                <p>Status: <span>{ticket.cancel_reason}</span></p>
                                                <p>Status: <span>{ticket.cancel_by}</span></p>
                                            </>
                                            :
                                            <p></p>
                                        }
                                    </div>
                                )
                            })
                            :
                            <h1>Você ainda não tem nenhum ingresso comprado...</h1>
                        }
                    </div>
                    <div className={"flex-horizontal justify-center"}>
                        <Pagination className="pagination-comp" onChange={(page) => {getUserTickets(userInfo, page)}} defaultCurrent={1} pageSize={true} total={ticketsTotalPages}/>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Reports;