import { GIFT_CARD_CART_ADD_SUCCESS, GIFT_CARD_CART_REMOVE_SUCCESS  } from "../constants/giftCardCartConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
    giftCards: [],
}

export const giftCardCartReducer = (state = initialState, action) => {

    switch (action.type){
        case GIFT_CARD_CART_ADD_SUCCESS:
            return { ...state, giftCards: [ ...state.giftCards, action.payload.giftCard] }
        case GIFT_CARD_CART_REMOVE_SUCCESS:
            return initialState
        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}