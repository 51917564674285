import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/header';
import Button from '../../../components/button';
import "./style.scss";

const AdminInfos = () => {

    const history = useHistory();
    const userData = useSelector(state => state.userLogin.userData);
    
    return (
        <div id="admin" >
            <Header/>
            <div className="user-box flex-vertical">
                <div className="user flex-horizontal align-center justify-between">
                    <div className="flex-horizontal">
                        <div className="user-img">
                            <img
                                alt="profile"
                                className="back"
                                src={require("../../../assets/images/solar-blue-circle.png")}
                            />
                            <img
                                alt={userData.name}
                                className="user-profile-img"
                                src={userData.image_url}
                            />
                        </div>
                        <div className="user-details">
                            <p className="title">{userData.name}</p>
                            <div className="flex-horizontal alingn-baseline">
                            <h2>Créditos</h2>
                            <h1>
                                {userData.credits
                                ? userData.credits
                                : 0}
                            </h1>
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            history.push("/minha-conta");
                        }}
                        className={"btn btn-primary"}
                        type="primary"
                    >
                    Voltar
                    </Button>
                </div>
            </div>
            
            <main className="data-container">
                <section className="admin-infos" >
                    <div className="item">
                        <h3>Sugestão de projetos</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                    <div className="item">
                        <h3>Manual de conduta do terapeuta</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                    <div className="item">
                        <h3>Manual para ebook e videos</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                    <div className="item">
                        <h3>Resultado da sua pesquisa de satisfação</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                    <div className="item">
                        <h3>Proposta de novo atendimento</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                    <div className="item">
                        <h3>Marque uma reunião com nossa equipe</h3>
                        <a className="link-button" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/u/3/d/1HykOwRKVlAo-sshkxli9t5FuOudRuN_4lX2yBeSJGGI/viewform?edit_requested=true">Acessar</a>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default AdminInfos;