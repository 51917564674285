import React from 'react';
import { Link } from "react-router-dom";
import { Skeleton } from 'antd'; 
import "./style.scss";

const Service = (props) => {

    const generateSlug = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    return (
        <div className="service flex-vertical align-center text-center">
            {
                props.isLoading ?
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                        <Skeleton.Image style={{ width: 200 }} />
                        <Skeleton.Button active={true} size="small" />
                    </div>
                    <Skeleton.Button active={true} size="small" style={{ width: 100 }} />
                </>
                :
                <>
                    <div>
                        <img src={props.img} alt={props.title} />
                        <p>{props.title}</p>
                    </div>
                    <Link className="more" to={{
                        pathname: `/terapias/${props.id}/${generateSlug(props.title)}`,
                        state: {...props}
                    }}>
                        <span>saiba mais</span>
                    </Link>
                </>
            }
        </div>
    );

}

export default Service;
