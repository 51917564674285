import { SCART_ADD_SUCCESS, SCART_REMOVE_SUCCESS  } from "../constants/socialCartConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
    services: [],
}

export const socialCartReducer = (state = initialState, action) => {

    switch (action.type){
        case SCART_ADD_SUCCESS:
            return { ...state, services: [ ...state.services, action.payload.service] }
        case SCART_REMOVE_SUCCESS:
            return { services: action.payload.services }
        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}