import React from 'react';
import Slide from 'react-slick';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const VideoCarousel = ({ videos }) => {

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineRight
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }
      
    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineLeft
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }

    const sliderSetings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                }
            }
        ],
        nextArrow: <SampleNextArrow className="slickArrow" />,
        prevArrow: <SamplePrevArrow className="slickArrow" />
    };

    return (
        <div id="video-carousel" >
            <Slide {...sliderSetings}>
                {
                    videos?.map((video, index) => {
                        return (
                            <div key={index}>
                                <iframe
                                key={index}
                                title="Inicio"
                                width="100%" 
                                height="100%" 
                                src={video} 
                                className="galery-video"
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; 
                                encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                                allowFullScreen>
                                </iframe>
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
    )

}

export default VideoCarousel;
