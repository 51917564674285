const cardNumberMask = (value) => {
    if (!value) return;

    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/^(\d{4})(\d)/g,"$1 $2")
        .replace(/^(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3")
        .replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3 $4");
}

export default cardNumberMask;
