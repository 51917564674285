import React from 'react';
import { Link } from "react-router-dom";
import "./style.scss";

const PostCard = ({id, thumbnail_url, title, abstract, date}) => {

    const parsedDate = new Date(date).toLocaleString('pt-BR', {dateStyle: "long"})

    return (
        <div className="post-card">
            <Link
                to={{
                    pathname: "/blog-post/" + id,
                }}>
                <div className="box">
                    <img alt={title} src={thumbnail_url} />
                    <p className="date">{parsedDate}</p>
                    <p className="title">{title}</p>
                    <p className="post-text">{abstract}</p>
                </div>
            </Link>
            {/* <div className="socials flex-horizontal justify-center">
                <div><img alt="facebook" src={require("../../assets/images/facebook-color.svg")} /></div>
                <div><img alt="instagram" src={require("../../assets/images/instagram-color.svg")} /></div>
                <div><img alt="twitter" src={require("../../assets/images/twitter-color.svg")} /></div>
            </div> */}
        </div>
    )
}

export default PostCard;