import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import axios from 'axios';
import EventShowcaseCard from '../../components/events/event-showcase-card';
import { Constants } from '../../constants';
import Advertisement from '../../components/advertisement';
import AccessControll from '../../services/access';

import "./style.scss";

const Events = () => {

    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getEvents = useCallback(async () => {
        setIsLoading(true);
        await axios.get(`${Constants.ApiUrl}/events`, {
                params: {
                    sort: `["createdAt", "DESC"]`,
                    range: `[0,9]`,
                    filter: {
                        "status": "Disponível",
                    }
                }
            })
            .then((response) => {
                setEvents(response.data);
                setIsLoading(false);
            })
            .catch((err)=> {
                console.log(err);
                setIsLoading(false);
            })
    }, [])

    useEffect(() => {
        getEvents();
        AccessControll('Home Projetos');
    }, [getEvents]);

    return (
        <div id="events">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/projects-main-image.png")}
                h1={"Eventos Mundo Akar"}
            />

            <div className={"blog-box"}>
                <section className={"others"} >
                    <h1>Eventos</h1>
                    <div className="cards">
                        {
                            events?.map((event) => {
                                return (
                                    <EventShowcaseCard 
                                        key={event.id}
                                        date={event.date}
                                        id={event.id}
                                        name={event.name}
                                        thumbnail_url={event.thumbnail_url}
                                        type={event.type}
                                        loading={isLoading}
                                    />
                                )
                            })
                        }
                        {
                            events.length === 0 &&
                            <span className="no-events-message">Não há nenhum evento disponível no momento</span>
                        }
                    </div>
                </section>
                <Advertisement local="events" />
            </div>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );
}

export default Events;