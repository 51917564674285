import React, { Component } from 'react';
import { Modal, Tabs } from 'antd';
import phoneMask from '../../utils/phoneMask'
import "./style.scss";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            professional: this.props.professional,
        }
    }
    
    componentDidMount = () => {
        if(!this.props.professional){
            Modal.warning({
                title: 'Opss!!',
                content: 'Profissional inexistente',
                onOK: () => {
                    this.props.history.push('/')
                }
            })
        }
    }

    render() {
        const { TabPane } = Tabs;
        return (
            <div id="profileProf">
                
                <div className="user-box flex-vertical">
                    <div className="user flex-horizontal align-center justify-between">
                        <div className="flex-horizontal">
                            <div className="user-img">
                                <img alt="profile" className="back" src={require("../../assets/images/solar-blue-circle.png")} />
                                <img alt={this.state.professional.name} className="user-profile-img" src={this.state.professional.image_url} />
                            </div>
                            <div className="user-details">
                                <p className="title">{this.state.professional.name}</p>
                            </div>
                        </div>
                    </div>          
                    
                        
                        <div className="personal-data">
                            
                            <div className="tab-container">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Dados do Profissional" key="1">
                                    <div className="tab-content">
                                        <div className="data flex-horizontal">
                                            <label>Nome:</label>
                                            <p>{this.state.professional.name}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Telefone:</label>
                                            <p>{phoneMask(this.state.professional.phone)|| "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Especialidade/Função:</label>
                                            <p>{this.state.professional.specialties || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>CRP/CRM/TRT:</label>
                                            <p>{this.state.professional.crp_crm_trt || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Descrição:</label>
                                            <p>{this.state.professional.obs || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Currículo:</label>
                                            <p>{this.state.professional.mini_curriculum || "Dado não cadastrado"}</p>
                                        </div>
                                    </div>

                                </TabPane>
                                                                
                            </Tabs>
                                
                            </div>
                        </div>
                    
                </div>
            </div>
        )
    }
}


export default (Profile);