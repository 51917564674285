import React, { useState } from 'react';
import { Input, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import axios from "axios";
import { Constants } from "../../constants";
import Header from '../../components/header';
import Footer from '../../components/footer';
import Button from '../../components/button';
import Loading from '../../components/loading';
import "./style.scss";

const { TextArea } = Input;
const { Option } = Select;

const Sugestion = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState();
    const [category, setCategory] = useState();
    const userData = useSelector(state => state.userLogin.userData);
    const userInfo = useSelector(state => state.userLogin.userInfo);

    const handleSendSugestion = async () => {

        setIsLoading(true);

        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        const body = {
            
            client: userInfo.id,
            content: content,
            category: category
        }

        await axios.post(
            Constants.ApiUrl + '/sugestions', body, config
            ).then(() => {
                Modal.success({title: 'Sucesso!', content:'Obrigado por contribuir com nossa plataforma!'})
                setIsLoading(false)
            })         
            .catch((error) => {
                console.log(error)
                Modal.error({
                    title: 'Ops!!',
                    content: error.response.data.message
                })
                setIsLoading(false);

            })
    }

    return(
        <div id="sugestions">
            <Loading loading={isLoading} />
            <Header/>
            <section className="sugestion-container" >
                <h1>Olá <strong>{ userData.name }</strong>, esperamos sua sugestão!</h1>

                <div className="input-group">
                    <label htmlFor="category">Categoria</label>
                    <Select
                        id="category"
                        name="categoria"
                        size='large'
                        className="form-field-select-sugestion"
                        onChange={(value) => setCategory(value)}
                        value={category}
                        placeholder="Selecionar"
                    >
                        <Option value="Assunto">Sugerir assunto</Option>
                        <Option value="Terapia">Sugerir terapia</Option>
                        <Option value="Conteúdo">Sugerir conteúdo</Option>
                        <Option value="Sugestão">Sugestão</Option>
                        <Option value="Elogio">Elogio</Option>
                        <Option value="Reclamação">Reclamação</Option>
                    </Select>
                    <label htmlFor="content">Comentário</label>
                    <TextArea rows={4} size="large" className="commet-input" onChange={(e) => {setContent(e.target.value)}}/>
                    <Button
                        loading={isLoading}
                        loadingSize={18}
                        onClick={() => {
                        handleSendSugestion();
                        }}
                        className={"submit-button"}
                        disabled={!content}
                    >
                        Enviar comentário
                    </Button>
                </div>
            </section>
            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    )
}

export default Sugestion;
