import React, { Component } from 'react';
import { Modal } from 'antd';
import axios from "axios";
import { connect } from 'react-redux';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Socials from '../../components/socials';
import Loading2 from '../../components/loading2';
import Loading from '../../components/loading';
import PostCard from '../../components/post-card';
import ProfileProf from '../profileProfessional';
import { Constants } from "../../constants";
import "./style.scss";
import AccessControll from '../../services/access';
import GaleryCarousel from '../../components/galery-carousel';
import VideoCarousel from '../../components/video-carousel';

class Social extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            professionals: [],
            depoiments: [],
            mainText: '',
            mainVideo: '',
            images: [],
            videos: [],
            tela: window.innerWidth,
            loading: true,
            loading2: true,
            modal: {
                visibility: false,
                professional: {}
            }
        }
    }

    componentDidMount = () => {
        this.getDepoiments();
        this.getContent();
        this.getProf();
        window.addEventListener('resize', () => {
			this.setState({tela: window.innerWidth})
		})
        AccessControll('Galeria');
    }

    shuffleArray = (array) => {
        const aux = array;
        for (let i = aux.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [aux[i], aux[j]] = [aux[j], aux[i]];
        }
        return aux
    }
    getContent = async () => {
        const id = this.props.match.params.id;
        await axios
            .get(Constants.ApiUrl + `/galery/${id}`)
            .then((response) => {
                this.setState({
                    mainText: response.data.main_text,
                    mainVideo: response.data.main_video_url,
                    images: [
                        response.data.image1_url,
                        response.data.image2_url,
                        response.data.image3_url,
                        response.data.image4_url,
                        response.data.image5_url,
                        response.data.image6_url,
                    ],
                    videos: [
                        response.data.video2_url,
                        response.data.video3_url,
                        response.data.video4_url,
                        response.data.video5_url,
                    ],
                    loading2: false
                })
            })
            .catch((error) => {
                console.log(error)
            });
    }
    getProf = async () => {
        await axios
            .get(Constants.ApiUrl + '/users/professionals')
            .then((response) => {
                if(response.data.length <= 6){
                    this.setState({
                        professionals: this.shuffleArray(response.data),
                        loading: false
                     })
                }else {
                    const shuffle = this.shuffleArray(response.data);
                    this.setState({
                        professionals: shuffle.slice(0,6),
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
    getDepoiments = async () => {
        await axios
            .get(Constants.ApiUrl + '/posts/category/depoimentos', { params: { range: '[0, 4]' } })
            .then((response) => {
                this.setState({depoiments: response.data })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {
        return (
            <>
                <Loading loading={this.state.loading2}/>

                <Header />
                <div className="social-experience-container">
                    <span className="title">Galeria</span>
                    <div className="group-content">
                        <iframe 
                            title="Inicio"
                            width="100%" 
                            height="100%" 
                            src={this.state.mainVideo} 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; 
                            encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen></iframe>
                        <div className="content">
                            <span className="content-title">Projetos Mundo Akar</span>
                            <p className="content-text">
                                {this.state.mainText}
                            </p>
                            <Socials />
                        </div>
                    </div>
                    <h3 className="title">Vídeos</h3>
                    <section className="carrosel2">
                        {
                            this.state.videos.length > 0 ?
                            <VideoCarousel videos={this.state.videos} />
                            :
                            <span>Videos indisponíveis</span>
                        }
                    </section>
                    <h3 className="title">Nosso espaço</h3>
                    {
                        this.state.images.length > 0 ?
                        <GaleryCarousel images={this.state.images}/>
                        :
                        <span>Imagens indisponíveis</span>
                    }

                    <h3 className="title">Depoimentos</h3>
                    {this.state.loading ? (
                        <Loading2 loading={this.state.loading} />
                    ):
                    (
                        <div className="testimonials-container">
                        {
                            this.state.depoiments.length > 0 ? (
                                <div className="testimonials">
                                    { this.state.depoiments.map(post => {
                                    return (
                                        <PostCard
                                            id={post.id}
                                            title={post.title}
                                            abstract={post.abstract}
                                            thumbnail_url={post.thumbnail_url}
                                            date={post.createdAt}
                                            key={post.id}
                                        />
                                    )
                                    })}
                                </div>
                            ) : (
                                <span className="testimonials-text">Sem depoimentos disponíveis</span>
                            )
                        }

                        </div>
                    )}
                    
                    <h3 className="title" >Profissionais</h3>
                    <Modal
                        title={<span className="modal-title">Perfil</span>}
                        visible={this.state.modal.visibility}
                        onCancel={() => this.setState({modal: {visibility: false}})}
                        closable={true}
                        footer={null}
                        destroyOnClose={true}
                        
                    >
                        <ProfileProf professional={this.state.modal.professional}/>
                        
                    </Modal>
                    {this.state.loading ? (
                        <Loading2 loading={this.state.loading} />
                    ):
                    (
                        <div className={"professionals"}>
                            {
                                this.state.professionals.map((prof)=> {
                                    return(
                                        <div key={prof.id} className="professional flex-horizontal align-center" onClick={()=> {
                                            this.setState({
                                                modal: {
                                                    visibility:true,
                                                    professional: prof
                                                }
                                            })
                                        }}>
                                            <div>
                                                <img alt="profile" className="back" src={require("../../assets/images/solar-blue-circle.png")} />
                                                <img alt="profile" className="user" src={prof.image_url} />
                                            </div>
                                            <div>
                                                <p className="title">{prof.name}</p>
                                                <p className="description">{prof.obs}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                    

                    </div>
                    <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo
    }
}

export default connect(mapStateToProps)(Social);