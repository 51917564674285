import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import Slide from 'react-slick';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Service from '../../components/service';
import PostCard from '../../components/post-card';
import Footer from '../../components/footer';
import Advertisement from '../../components/advertisement';
import { Pagination, Input } from 'antd';
import { Constants } from "../../constants";
import ProjectsCard from '../../components/projects-card';
import ProfessionalMainCard from '../../components/professionalMainCard';
import AccessControll from '../../services/access';

import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {

    const { Search } = Input;

    const [isLoading, setIsLoading] = useState(false);
    const [latestPosts, setLatestPosts] = useState([]);
    const [services, setServices] = useState();
    const [servicesTotalPages, setServicesTotalPages] = useState();
    const [projects, setProjects] = useState();
    const [professionals, setProfessionals] = useState();

    const professionalsSliderSetings = {
        dots: false,
        infinite: professionals?.length > 3,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <AiOutlineRight
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }
      
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <AiOutlineLeft
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }

    const getLatestPosts = useCallback(async () => {
        await axios
            .get(`${Constants.ApiUrl}/posts`, {
                params: { 
                    range: '[0,3]',
                    sort: `["createdAt", "DESC"]`,
                }
            })
            .then((response) => {
                setLatestPosts(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    const getProjects = useCallback(async () => {
        await axios
            .get(`${Constants.ApiUrl}/posts`, {
                params: { 
                    range: '[0,3]',
                    sort: `["createdAt", "DESC"]`,
                    filter: {
                        "category": "projetos",
                    }
                }
            })
            .then((response) => {
                setProjects(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    const getServices = useCallback(async (page, filterName) => {
        setIsLoading(true);
        const LIMIT = 8;
        await axios
            .get(`${Constants.ApiUrl}/services`, {
                params: {
                    range: `[${(page -1)*LIMIT},${page*LIMIT -1}]`,
                    sort: `["createdAt", "DESC"]`,
                    filter: {
                        "status": "Ativo",
                        "name": `${filterName ? filterName : ''}`,
                        "type": "GERAL"
                    }
                }
            })
            .then((response) => {
                const range = response.headers["content-range"];
                const total = range.split('/').pop();
                setServicesTotalPages(Math.ceil(total/8));
                setServices(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    }, [])

    const getProfessionals = useCallback(async () => {
        setIsLoading(true);
        await axios
            .get(`${Constants.ApiUrl}/users/professionals`, {
                params: {
                    range: ``,
                    sort: `["fn", "random"]`,
                    filter: {
                        'area' : 'general'
                    }
                }
            })
            .then((response) => {
                setProfessionals(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    }, [])

    useEffect(() => {
        getLatestPosts();
        getProjects();
        getServices(1);
        getProfessionals();
        AccessControll('Home');
    }, [getLatestPosts, getProjects, getServices, getProfessionals])

    return (
        <div id="home">
            <Header />
            <Hero
                image={require("../../assets/images/home-main-image.png")}
                h1={"Bem-vindo ao Mundo Akar"}
                h2={"seu mundo em equilíbrio"}
            />
            <p className="description">Imagine um lugar onde a busca pelo bem-estar seja o principal objetivo. 
            Sim, esse lugar existe e pode se tornar uma realidade extremamente positiva.
            É o Mundo Akar, uma clínica de medicina integrativa que tem como principal objetivo a busca pelo bem-estar emocional e físico. Tudo foi desenvolvido com a finalidade de levar ao autoconhecimento, possibilitando o experimento dos diversos métodos convencionais aliados à leveza das Práticas Integrativas e Complementares. 
            Aqui você terá os melhores tratamentos com profissionais que têm a visão integral do ser, em seus aspectos biopsicossocial e espiritual.
            Venha viver esse mundo e se conhecer melhor.</p>
            <section className="service-box">
                <div className="section-header">
                    <h3>Terapias</h3>
                    <div>
                        <Search allowClear className="search-box" placeholder="Buscar" onSearch={(value) => {getServices(1, value)}} />
                    </div>
                </div>
                <div className={"flex-horizontal flex-wrap justify-evenly"}>
                    {
                        services?.length > 0 ?
                        services?.map((service) => {
                            return (
                                <Service isLoading={isLoading} key={service.id} title={service.name} img={service.image_url} id={service.id} />
                            )
                        })
                        :
                        <h1>Não foi encontrada nenhuma terapia para o termo buscado</h1>
                    }
                </div>
                <div className={"flex-horizontal justify-center"}>
                    <Pagination className="pagination-comp" onChange={(page) => {getServices(page)}} defaultCurrent={1} pageSize={true} total={servicesTotalPages}/>
                </div>
            </section>
            <Advertisement local="home" />
            {/* <section className="professionals">
                <h1 className="title">Profissionais</h1>
                <Slide {...professionalsSliderSetings}>
                    {
                        professionals?.map((professional) => {
                            return (
                                <ProfessionalMainCard
                                    id={professional.id}
                                    image_url={professional.image_url}
                                    last_name={professional.last_name}
                                    name={professional.name}
                                    key={professional.id}
                                    occupation={professional.occupation}
                                />
                            )
                        })
                    }
                </Slide>
            </section> */}
            <section className="blog-box">
                <h3 className="section-header">
                    <Link
                        to={{
                            pathname: "/blog",
                        }}
                    >Blog</Link>
                </h3>
                <div className="flex-horizontal flex-wrap justify-around">
                    {latestPosts.length > 0 ?
                    latestPosts?.map((post) => {
                        return (
                            <PostCard
                                id={post.id}
                                title={post.title}
                                abstract={post.abstract}
                                thumbnail_url={post.thumbnail_url}
                                date={post.createdAt}
                                key={post.id}
                            />
                        )
                    })
                :
                    <h2 style={{ color: '#a1793f' }} >Não foi encontrado nenhum artigo recente</h2>
                }
                </div>
            </section>
            {/* <section className={"projects"}>
                <h3>Projetos Mundo Akar</h3>
                <div className={"flex-horizontal"}>
                    {
                        projects?.length >0 ?
                        projects.map((project) => {
                            return (
                                <ProjectsCard
                                    id={project.id}
                                    title={project.title}
                                    abstract={project.abstract}
                                    thumbnail_url={project.thumbnail_url}
                                    date={project.createdAt}
                                    key={project.id}
                                />
                            )
                        })
                        : <h1>Não temos nenhum projeto no momento.</h1>
                    }
                </div>
            </section> */}
            <section className="map flex-horizontal justify-center">
                <iframe 
                    title="Mundo Akar localização"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.20313814444!2d-38.49063788524081!3d-3.765923397260242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c748abb9145bb1%3A0x4c3f7e3211171450!2sRua%20Felipe%20Nery%2C%201000%20-%20Patriolino%20Ribeiro%2C%20Fortaleza%20-%20CE%2C%2060810-310!5e0!3m2!1sen!2sbr!4v1605200224650!5m2!1sen!2sbr" 
                    width="800" 
                    height="450" 
                    frameBorder="0" 
                    style={{border:0}}
                    allowFullScreen="" 
                    aria-hidden="false" 
                    tabIndex="0"></iframe>
            </section>
            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );

}

export default Home;
