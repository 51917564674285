/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Input, Select, Col, Modal, Tooltip } from 'antd';
import phoneMask from '../../utils/phoneMask';
import { Constants } from "../../constants";
import Button from '../button';
import { login } from '../../redux/actions/userActions';

import "./style.scss";

const RegisterLoginModal = ({ closeModal }) => {
  const user = useSelector(state => state.userLogin);
  const loading = useSelector(state => state.userLogin.loading);
  const loginError = useSelector(state => state.userLogin.error);
  const dispatch = useDispatch();
  const { userInfo } = user;
  const [loginMode, setIsLoginMode] = useState(true);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPoliciesModalOpen, setIsPoliciesModalOpen] = useState(false);

  const nomeRegex = RegExp(/^[a-zA-Z\u00C0-\u00FF ]+$/);
  const { Option } = Select;

  const handleTermsModalState = () => {
      setIsTermsModalOpen(!isTermsModalOpen);
  }

  const handlePoliciesModalState = () => {
      setIsPoliciesModalOpen(!isPoliciesModalOpen);
  }

  const formikLogin = useFormik({
		initialValues: {
      email: '',
      password: '',
		},
		validationSchema:  Yup.object().shape({
      email: Yup.string().required('Campo obrigatório').email('Digite um email válido'),
      password: Yup.string().required('Campo obrigatório'),
    }),
		onSubmit: async (values) => {
      dispatch(login(values.email, values.password));
    },
  });

  const formikRegister = useFormik({
    initialValues: {
        name: '',
        sobrenome: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        phone: '',
    },
    validationSchema:  Yup.object().shape({
      name: Yup.string().matches(nomeRegex, 'Números ou caracteres especias não são permitidos').required('Campo obrigatório'),
      sobrenome: Yup.string().matches(nomeRegex, 'Números ou caracteres especias não são permitidos').required('Campo obrigatório'),
      email: Yup.string().email('Digite um email válido').required('Campo obrigatório'),
      password: Yup.string().required('Campo obrigatório'),
      confirmPassword: Yup.string().when('password', (password, field) =>
      password ? field.required('Campo obrigatório').oneOf([Yup.ref('password')], 'Senhas não coincidem') : field),
      gender: Yup.string().required('Campo obrigatório'),
      phone: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        last_name: values.sobrenome,
        email: values.email,
        password: values.password,
        birth: values.birth,
        gender: values.gender,
        phone: values.phone,
      }

      await axios
        .post(Constants.ApiUrl + '/users/', body)
        .then((response) => {
          let user = response.data;
          dispatch(login(values.email, values.password))
          localStorage.setItem("userInfo", JSON.stringify(user));
        })
        .catch((error) => {
          Modal.error({
              title: 'Ops!!',
              content: error.response.data.message,
              onOk: closeModal(),
              onCancel: closeModal(),
          })
          console.log(error);
        })
    },
  })

  useEffect(() => {
    const signed = userInfo ? true : false;
    if(signed){
      Modal.success({
        title: 'Parabens!',
        content: 'Seu login foi efetuado e você pode continuar sua compra!',
        onOk: closeModal(),
        onCancel: closeModal(),
      })
    }
  },[userInfo]);

  return (
    <div id="login-modal">
      {
        loginMode ?
        <div className={"login-box flex-horizontal align-center"}>
            <Col span={12}>
                <img alt="login" src={require("../../assets/images/login.png")} />
            </Col>
            <form className="login-container" onSubmit={formikLogin.handleSubmit}>
                <h1>Login</h1>
                {loginError && loginError.length > 0 && <span className="login-error">{loginError}</span>}
                <div>
                    <label>Email</label>
                    <Input 
                        onChange={formikLogin.handleChange}
                        placeholder={"exemplo@email.com"}
                        id="email" 
                        name="email"
                        title="Seu email deve seguir o padrão exemplo@email.com"
                    />
                    {formikLogin.touched.email && formikLogin.errors.email ? (<div className="form-field-edit-validate">{formikLogin.errors.email}</div>) : null}
                </div>

                <div>
                    <label>Senha</label>
                    <Input.Password 
                        onChange={formikLogin.handleChange}
                        placeholder={"*********"}
                        type="password"
                        id="password"
                        name="password"
                    />
                    {formikLogin.touched.password && formikLogin.errors.password ? (<div className="form-field-edit-validate">{formikLogin.errors.password}</div>) : null}
                </div>

                <div className="button-group">
                    <Button
                      loading={loading}
                      loadingSize={20}
                      className="login-button"
                      type="submit"
                      >Login
                    </Button>
                </div>
                <span className="register-span" onClick={() => setIsLoginMode(false)} style={{ cursor: 'pointer', marginBottom: '5px' }}>
                  Ainda ão possui uma conta? Cadastre-se!
                </span>
                <Link to={{
                    pathname:"/forgot-password",
                    state: 'Recuperar Senha'
                    }}>
                    <span className="forgot-password">Esqueci minha senha</span>
                </Link>
            </form>
        </div>
        :
        <div className={"register-box flex-horizontal align-center"}>
          <form className="login-container" onSubmit={formikRegister.handleSubmit}>
            <div className="input-colunm">
              <label>Primeiro nome <Tooltip color="#ec923c" title="Seu nome não pode conter números ou caracteres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input
                id="name" 
                name="name" 
                type="text"
                placeholder="Primeiro nome" 
                onChange={formikRegister.handleChange}
                value={formikRegister.values.name}
              />
              {formikRegister.touched.name && formikRegister.errors.name ? (<div className="form-field-edit-validate">{formikRegister.errors.name}</div>) : null} 
            </div>
            <div className="input-colunm">
              <label>Sobrenome<Tooltip color="#ec923c" title="Seu sobrenome não pode conter números ou caracteres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input
                id="sobrenome" 
                name="sobrenome" 
                type="text"
                placeholder="Sobrenome" 
                onChange={formikRegister.handleChange}
                value={formikRegister.values.sobrenome}
              />
              {formikRegister.touched.sobrenome && formikRegister.errors.sobrenome ? (<div className="form-field-edit-validate">{formikRegister.errors.sobrenome}</div>) : null} 
            </div>
            <div className="input-colunm">
              <label>Email<Tooltip color="#ec923c" title="Seu email tem que seguir o modelo exemplo@exemplo.com ou exemplo@exemplo.com.br"><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input
                id="email" 
                name="email" 
                type="email"
                placeholder="exemplo@exemplo.com" 
                onChange={formikRegister.handleChange}
                value={formikRegister.values.email}
              />
              {formikRegister.touched.email && formikRegister.errors.email ? (<div className="form-field-edit-validate">{formikRegister.errors.email}</div>) : null}
            </div>
            <div className="input-colunm">
              <label>Telefone<Tooltip color="#ec923c" title="Digite apenas números."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input
                id="phone"
                type="text"
                name="phone"
                onChange={formikRegister.handleChange}
                maxLength="15"
                value={phoneMask(formikRegister.values.phone)}
              />
              {formikRegister.touched.phone && formikRegister.errors.phone ? (<div className="form-field-edit-validate">{formikRegister.errors.phone}</div>) : null} 
            </div>
            <div className="input-colunm">
              <label>Senha<Tooltip color="#ec923c" title="Senha obrigatória. recomendamos uma senha de no mínimo 6 dígitos contendo números, letras e carateres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input.Password
                id="password" 
                name="password" 
                type="password"
                onChange={formikRegister.handleChange}
                value={formikRegister.values.password}
              />
              {formikRegister.touched.password && formikRegister.errors.password ? (<div className="form-field-edit-validate">{formikRegister.errors.password}</div>) : null}
            </div>
            <div className="input-colunm">
              <label>Confirmar Senha<Tooltip color="#ec923c" title="Os dois campos da sua senha devem ser iguais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
              <Input.Password
                id="confirmPassword" 
                name="confirmPassword" 
                type="password"
                onChange={formikRegister.handleChange}
                value={formikRegister.values.confirmPassword}
              />
              {formikRegister.touched.confirmPassword && formikRegister.errors.confirmPassword ? (<div className="form-field-edit-validate">{formikRegister.errors.confirmPassword}</div>) : null}
            </div>
            <div className="input-group">
              <label>Gênero</label>
              <Select
                id="gender" 
                name="gender" 
                onChange={(value) => formikRegister.setFieldValue('gender', value)}
                value={formikRegister.values.gender}
              >
                <Option value="Masculino">Masculino</Option>
                <Option value="Feminino">Feminino</Option>
                <Option value="Outros">Outros</Option>
              </Select>
              {formikRegister.touched.gender && formikRegister.errors.gender ? (<div className="form-field-edit-validate">{formikRegister.errors.gender}</div>) : null}              
            </div>
          </form>
          <div className={"image-button-container"}>
            <div className="head-register">
                <h1>Abra sua conta</h1>
                <p onClick={() => setIsLoginMode(true)} style={{ cursor: 'pointer' }}>Já tenho conta</p>
            </div>
            <img alt="register" src={require("../../assets/images/register.png")} />
            
            <Button
              loading={loading}
              onClick={() => {
                  formikRegister.submitForm()
              }}
              className="register-button"
            >Cadastre-se</Button>
            <p>
            Ao clicar em Cadastre-se, você concorda com
            nossos <span style={{cursor: 'pointer'}} onClick={() => handleTermsModalState()}>Temos de uso</span> e <span style={{cursor: 'pointer'}} onClick={() => handlePoliciesModalState()}>Política de Dados e Privacidade</span>
            </p>
        </div>
        </div>
      }
    </div>
  )
}

export default RegisterLoginModal;