import history from '../../services/history'
import { PLAN_CART_ADD_SUCCESS, PLAN_CART_REMOVE_SUCCESS } from "../constants/planCartConstants"

export const planCartAddRequest = (plan) => async (dispatch) => {
        dispatch({
            type: PLAN_CART_ADD_SUCCESS,
            payload: { plan }
        })
        history.push('/pagamento-planos')

}

export const planCartRemovePlan = (plan) => async (dispatch) => {
    dispatch({
        type: PLAN_CART_REMOVE_SUCCESS,
        payload: { plan }
    })
}