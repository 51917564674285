import axios from 'axios';
import { Constants } from '../../constants';
import { FAVORITE_REMOVE_SUCCESS, FAVORITE_ADD_SUCCESS, FAVORITE_INITIAL_STATE_SUCCESS, FAVORITE_INITIAL_STATE_FAIL, FAVORITE_ADD_REQUEST, FAVORITE_REMOVE_REQUEST, FAVORITE_REMOVE_FAIL, FAVORITE_ADD_FAIL, FAVORITE_INITIAL_STATE_REQUEST } from "../constants/favoritesConstants";

export const getUserFavorites = (userInfo) => async (dispatch) => {

    if(userInfo){
        dispatch({
            type: FAVORITE_INITIAL_STATE_REQUEST,
        })
        await axios.get(`${Constants.ApiUrl}/favorites`, {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            },
        }).then(response => { 
            dispatch({
                type: FAVORITE_INITIAL_STATE_SUCCESS,
                payload: response.data
            })
        }).catch(err => {
            console.log(err);
            dispatch({
                type: FAVORITE_INITIAL_STATE_FAIL,
            })
        });

    }else{
        dispatch({
            type: FAVORITE_INITIAL_STATE_FAIL,
        })
    }
}

export const favoritesAddRequest = (item, type, token) => async (dispatch) => {

    dispatch({
        type: FAVORITE_ADD_REQUEST
    })

    await axios.patch(`${Constants.ApiUrl}/favorites/${type}`, null, {
        params: {
            itemId: item.id
        },
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(
        dispatch({
            type: FAVORITE_ADD_SUCCESS,
            payload: { item, type }
        })
    ).catch(err => {
        dispatch({
            type: FAVORITE_ADD_FAIL,
        })
        console.log(err);
    });
}

export const favoritesRemoveRequest = (item, type, token) => async (dispatch) => {

    dispatch({
        type: FAVORITE_REMOVE_REQUEST
    })
    await axios.patch(`${Constants.ApiUrl}/favorites/${type}`, null, {
        params: {
            itemId: item.id
        },
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(
        dispatch({
            type: FAVORITE_REMOVE_SUCCESS,
            payload: { item, type }
        })
    ).catch(err => {
        dispatch({
            type: FAVORITE_REMOVE_FAIL,
        })
        console.log(err);
    });
}