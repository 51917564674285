import React, { useState, useEffect, useCallback, useRef } from 'react';
import "./style.scss";
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import { Row, Col, Input, Button, Modal } from 'antd';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Constants } from '../../constants';
import GaleryCarousel from '../../components/galery-carousel';
import Loading from '../../components/loading';
import Socials from '../../components/socials';
import phoneMask from '../../utils/phoneMask';

const Coffee = () => {

    const formRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState();

    const { TextArea } = Input;

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema:  Yup.object().shape({
            name: Yup.string().required('campo obrigatório'),
            email: Yup.string().required('campo obrigatório').email('Digite um email válido'),
            phone: Yup.string().nullable(),
            message: Yup.string().required('campo obrigatório'),
    }),
        onSubmit: async (values) => {
            setIsLoading(true)
            const { name, email, phone, message, type = 'Orçamento Coffee Break'} = values	

            await axios.post(`${Constants.ApiUrl}/messages/contact`, {
                    name, email, phone, message, type
                })
                .then((response)=>{
                    setIsLoading(false)
                    const aux = Modal.success({
                        title: 'Sucesso!',
                        content: <p className="teste">{response.data.message}</p>,
                        onOk: ()=> {
                            aux.destroy();
                        },
                        maskClosable: true,
                    });
                })
                .catch((err)=> {
                    Modal.error({
                        title: "Ops!!",
                        content: err.response.data.message,
                        onOk: ()=> {
                            setIsLoading(false)
                        }
                    });
                })
        },

    })

    const getPageDetails = useCallback(async () => {
        setIsLoading(true)
        const config = {
            params: {
                filter: {
                    "name": "cafe",
                }
            }
        }
    
        await axios.get(`${Constants.ApiUrl}/pages`, config)
        .then((response) => {
            setDetails(response.data[0]);
            setIsLoading(false)
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        getPageDetails();
    }, [getPageDetails])

    return (
        <div id="coffee">
            <Loading loading={isLoading} />
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/coffee-main-image.png")}
                h1={"Uma infraestrutura moderna para receber você e seus pacientes"}
            />

            <div className={"blog-box"}>
                <h1 className={"page-title"}>Café</h1>
                <div className={"header"}>
                    {
                        details ?
                        <>
                            <img alt="main_image" className="back" src={details.main_image_url} />
                            <h3>{details.main_text}</h3>
                        </>
                        :
                        <span></span>
                    }
                </div>
                {
                    details ?
                    <GaleryCarousel images={details.images} />
                    :
                    <span></span>
                }                 
            </div>
            <section className={"service-form"}>
                <h3>Consulte disponibilidade para locação</h3>
                <form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className={"service-form-box flex-horizontal align-center"}>
                        <Col span={12}>
                            <div>
                                <label>Nome*</label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (<div className="form-field-edit-validate">{formik.errors.name}</div>) : null}
                            <div>
                                <label>Tel</label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={phoneMask(formik.values.phone)}
                                />
                            </div>
                            {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}
                            <div>
                                <label>Email*</label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                            {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                            <div>
                                <TextArea
                                    rows={5}
                                    placeholder={"Escreva aqui sua mensagem"}
                                    id="message"
                                    name="message"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                />
                            </div>
                            {formik.touched.message && formik.errors.message ? (<div className="form-field-edit-validate">{formik.errors.message}</div>) : null}
                            <div>
                            <Button type="submit" onClick={()=>formik.submitForm()} className={"btn btn-primary"}>Enviar</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </section>
            <Socials/>
            <Footer backgroundImage={require("../../assets/images/brown-footer.png")}/>
        </div>
    );
}

export default Coffee;