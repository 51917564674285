import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import { Constants } from "../../constants";
import axios from "axios";
import "./style.scss";
import PlanCard from '../../components/plan-card';
import AccessControll from '../../services/access';


const PlansMainPage = () => {

    const [plans, setPlans] = useState([]);

    const getPlans = useCallback(async () => {
        await axios.get(`${Constants.ApiUrl}/plans`, {
                params: {
                    sort: `["createdAt", "DESC"]`,
                    filter: {
                        "status": "Ativo",
                    }
                }
            })
            .then(response => {
                setPlans(response.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        getPlans();
        AccessControll('Home Planos')
    }, [getPlans])

    return (
        <div id="plans-main-page">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/kids-main-image.png")}
                h1={"Conheça nossos planos"}
            />
            <p className="description">A saúde mental dos seus colaboradores é o sucesso da sua empresa.
                O mundo do trabalho é elemento estruturante da vida das pessoas e das sociedades. Para os gestores é um desafio gerenciar e desenvolver colaboradores, mantendo os índices de produtividade e motivação de todas as gerações que se inter-relacionam. Cada vez mais os empregadores têm percebido que a promoção da saúde dentro das organizações gera benefícios tanto para o empregado quanto para o empregador.
                Pensando nisso, o Mundo Akar desenvolve programas de qualidade de vida, de acordo com a rotina de cada empresa, e campanhas que fomentem a saúde de forma ampla, visando a qualidade de vida e o autocuidado. 
                Isto mesmo! Investindo na saúde dos seus colaboradores, você melhora o clima organizacional e a saúde física, mental e emocional de seus colaboradores, criando um time mais engajado, feliz, saudável e satisfeito! 
                Entre em contato conosco e conheça as opções para a sua empresa. </p>
            <div className="blog-box">
                <h3>Nossos Planos</h3>
                <div className="flex-horizontal justify-around flex-wrap">
                    {
                        plans?.map((plan) => {
                            return (
                                <PlanCard
                                    key={plan.id}
                                    description={plan.description}
                                    id={plan.id}
                                    name={plan.name}
                                    price={plan.price}
                                    image_url={plan.image_url}
                                />
                            )
                        })
                    }
                </div>
            </div>

            <Footer backgroundImage={require("../../assets/images/blue-footer.png")}/>
        </div>
    );
}

export default PlansMainPage;