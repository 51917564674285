import React, { useCallback, useEffect, useState } from 'react';
import "./style.scss";
import Header from '../../components/header';
import Hero from '../../components/hero';
import PostCard from '../../components/post-card';
import Footer from '../../components/footer';

import { Constants } from "../../constants";
import axios from "axios";

const Kids = () => {
    const [posts, setPosts] = useState();

    const getposts = useCallback(async () => {
        await axios
            .get(Constants.ApiUrl + '/posts/category/kids', { params: { range: '[0,9]' } })
            .then((response) => {
                setPosts(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    useEffect(() => {
        getposts()
    }, [getposts])

    return (
        <div id="kids">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/kids-main-image.png")}
                h1={"Mundo Akar Kids"}
            />

            <div className={"blog-box"}>
                <h3>Mundo Akar Kids</h3>
                <div className="flex-horizontal justify-around flex-wrap">
                    {   posts?.map((post) => {
                            return (
                                <PostCard
                                    id={post.id}
                                    title={post.title}
                                    abstract={post.abstract}
                                    thumbnail_url={post.thumbnail_url}
                                    date={post.createdAt}
                                    key={post.id}
                                />
                            )
                        })
                    }
                </div>
            </div>

            <Footer backgroundImage={require("../../assets/images/blue-footer.png")}/>
        </div>
    );
}

export default Kids;
