import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";

const PlanCard = ({id, image_url, name, description, price}) => {

    return (
        <div className="plan-card">
            <Link
                to={{
                    pathname: "/planos/" + id,
                }}
                className="container"
                >
                <img alt={name} src={image_url} />
                <div>
                    <p className="name" >{name}</p>
                    <p className="description" >{description}</p> 
                </div>
            </Link>
        </div>
    )

}

export default PlanCard;