import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Input } from 'antd';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { login } from '../../redux/actions/userActions';
import "./style.scss";
import Header from '../../components/header';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            error: false
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { user, err, history } = this.props;
        
        if(user.userInfo){
            history.push('/');
        }
        if(prevProps.err !== err && err && err.length > 0){
            this.setState({error: true})
        }
    }

    handleLogin = async () => {
        this.setState({error: false})
        const { login } = this.props;
        const schemaValidate = Yup.object().shape({
            password: Yup.string().required(),
            email: Yup.string().email().required(),
        })
        const logon = {
            password: this.state.password,
            email: this.state.email
        }
        if(await schemaValidate.isValid(logon)){
            login(this.state.email, this.state.password);
            
        }else{
            this.setState({error: true})
        }
    }

    render() {
        let { loading } = this.props;
        
        return (
            <div id="login">
                <Header />
                <div className={"login-box flex-horizontal align-center"}>
                    <Col span={12}>
                        <img alt="login" src={require("../../assets/images/login.png")} />
                    </Col>
                    <div className="login-container">
                        <h1>Login</h1>
                        {this.state.error && <span className="login-error">Erro ao fazer login, verifique seus dados.</span>}
                        <div>
                            <label>Email</label>
                            <Input 
                                onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                }}
                                placeholder={"exemplo@email.com"}
                                id="email" 
                                name="email" 
                                type="email"
                            />
                        </div>

                        <div>
                            <label>Senha</label>
                            <Input.Password 
                                onChange={(e) => {
                                    this.setState({ password: e.target.value });
                                }}
                                placeholder={"*********"}
                                type="password"
                            />
                        </div>

                            <div className="button-group">
                                <Button 
                                    loading={loading}
                                    loadingSize={20}
                                    onClick={() => {
                                    this.handleLogin();
                                    }}
                                    className="login-button"
                                    type="primary"
                                >Login</Button>
                                <button type="primary" className="register-button">
                                    <Link to={'/registro'} >Cadastre-se</Link>
                                </button>
                            </div>
                            
                            <Link to={{
                                pathname:"/forgot-password",
                                state: 'Recuperar Senha'
                                }}>
                                <span className="forgot-password">Esqueci minha senha</span>
                            </Link>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userLogin,
        loading: state.userLogin.loading,
        err: state.userLogin.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => {
            dispatch(login(email, password));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
