import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import { FiEdit } from 'react-icons/fi';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Loading from '../../components/loading';
import FavoriteButton from '../../components/favoriteButton';
import { Input, Button, Collapse } from 'antd';
import { Constants } from "../../constants";
import axios from "axios";
import LikeButton from '../../components/likeButton';
import Advertisement from '../../components/advertisement';

import "./style.scss";
import AccessControll from '../../services/access';
import Socials from '../../components/socials';

const BlogPost  = (props) => {
    const [post, setPost] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingComment, setIsLoadingComment] = useState(false);
    const [comments, setComments] = useState();
    const [comment, setComment] = useState({});
    const [commentReply, setCommentReply] = useState({});
    const [commentToEdit, setCommentToEdit] = useState({});
    const [commentEditMode, setCommentEditMode] = useState(false);
    const userInfo = useSelector(state => state.userLogin.userInfo);

    const { Panel } = Collapse;

    const getPost = useCallback(async () => {
        setIsLoading(true);
        const id = props.match.params.id;
        await axios
            .get(Constants.ApiUrl + '/posts/' + id)
            .then((response) => {
                setPost(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
            });
    }, [props.match.params.id])

    //acredito que dê pra pegar os comentários junto com o post de uma vez...
    const getComments = useCallback(async () => {
        const id = props.match.params.id;
        await axios
            .get(Constants.ApiUrl + '/comments/post/' + id)
            .then((response) => {
                setComments(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [props.match.params.id])

    const handleClickComment = async () => {
        setIsLoadingComment(true);
        const id = props.match.params.id;
        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        
        await axios
            .post(Constants.ApiUrl + '/comments', {
                userId: user.id,
                postId:id,
                content: comment.content
            }, config)
            .then((response) => {
                const auxComment = {
                    user: { name: user.userName },
                    author: response.data.author,
                    post: response.data.post,
                    content: response.data.content,
                    id: response.data.id,
                    createdAt: response.data.createdAt,
                    updatedAt: response.data.updatedAt,
                }
                setComments([...comments, auxComment]);
                setComment({});
                setIsLoadingComment(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoadingComment(false);
            });
    }

    const handleReply = async () => {
        setIsLoadingComment(true);
        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        
        await axios
            .post(Constants.ApiUrl + '/comments/reply', {
                userId: user.id,
                commentId: commentReply.commentId,
                content: commentReply.content
            }, config)
            .then((response) => {
                const auxReply = {
                    user: { name: user.userName },
                    author: response.data.author,
                    comment: response.data.comment,
                    content: response.data.content,
                    id: response.data.id,
                    createdAt: response.data.createdAt,
                    updatedAt: response.data.updatedAt,
                }
                const commentsAux = comments;
                commentsAux.map((item) => {
                    if(item.id === auxReply.comment){
                        item.replies = [...item.replies, auxReply]
                    }
                    return item
                })
                setComments(commentsAux);
                setCommentReply({});
                setIsLoadingComment(false);
                return 0
            })
            .catch((error) => {
                console.log(error)
                setIsLoadingComment(false);
            });
    }

    const handleDeleteComment = async (comment, reply) => {
        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        
        if(reply){
            await axios
            .delete(`${Constants.ApiUrl}/comments/replies/${comment.id}`, config)
            .then(() => {
                const commentsAux = comments.map((item) => {
                    if(item.id === comment.comment){
                        const auxReplies = item.replies.filter((reply) => reply.id !== comment.id);
                        item.replies = auxReplies;
                    }
                    return item;
                });
                setComments(commentsAux);
            })
            .catch((error) => {
                console.log(error)
            });
        }else{
            await axios
            .delete(`${Constants.ApiUrl}/comments/${comment.id}`, config)
            .then(() => {
                const auxComments = comments.filter((item) => { return item.id !== comment.id })
                setComments(auxComments);
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }

    const handleEditComment = async (commentToEdit, reply) => {
        setIsLoadingComment(true);
        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        
        await axios
            .put(`${Constants.ApiUrl}/comments/${commentToEdit.id}`, {
                content: commentToEdit.content
            }, config)
            .then(() => {
                const auxComments = comments.map((item) => {
                    if(item.id === commentToEdit.id){
                        item.content = commentToEdit.content;
                    }
                    return item;
                })
                setComments(auxComments);
                setCommentToEdit({});
                setCommentEditMode(false);
                setIsLoadingComment(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoadingComment(false);
            });
    }

    useEffect(() => {
        getPost();
        getComments();
        AccessControll('Post do Blog');
    }, [getPost, getComments])

    return (
        <div id="blog-post">
            <Loading loading={isLoading} />
            <Header />
            <section className="post-content">
                <div className="post-header">
                    <img src={post ? post.thumbnail_url : ''} alt="post-thumbnail"/>
                    <div className="post-title-author-box">
                        <div className="post-title">
                            {post?.title}
                        </div>
                        <div className="post-author">Autor(a): {post?.author_name}</div>
                    </div>
                </div>
                <div className="post-body" dangerouslySetInnerHTML={{__html:post?.body}} />
            </section>

            <Socials link={window.location.href} text={post?.title} />

            <Advertisement local="single-post" tags={post ? post.tags : null} />

            <section className={"comment-form"}>
                <div className="likes-area" >
                {
                    post &&
                    <>
                        <FavoriteButton type="post" item={post} />
                        <LikeButton item={post} />
                    </>
                }
                </div>
                <div className={"categories"}>
                    <p className="section-title">Categorias: </p>
                    <p className="categories-category">{post?.category}</p>
                </div>
                <div className={"tags"}>
                    <p className="section-title">Tags:</p>
                    <div className="tags-tag">{post?.tags && post?.tags.map((tag, index)=> {
                        return (
                                <span key={index}>{tag}</span>
                        )
                    })}</div>
                </div>
                <span className="section-title">Comentários:</span>

                <div className="comments-container">
                    {comments?.length > 0 ? comments?.map((comment)=> {
                        const date = new Date(comment.createdAt).toLocaleString('pt-BR', {dateStyle: "short", timeStyle: 'short' });
                        const replies = comment.replies;
                        return (
                            <>
                                <div className="comment" key={comment.id}>
                                    <div className="comment-group">
                                        <span className="name">{comment.user.name}</span>
                                        <div>
                                            <span>{date}</span>
                                            {
                                                userInfo && userInfo.id === comment.author &&
                                                <>
                                                    <FiTrash2 color='#c53030' className="delete-button" onClick={() => handleDeleteComment(comment)} />
                                                    <FiEdit color='#c7c7c7' className="delete-button" onClick={() => { 
                                                        setCommentEditMode(true); 
                                                        setCommentToEdit(comment)
                                                    }}/>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {
                                        commentEditMode && commentToEdit.id === comment.id ? 
                                        <>
                                            <Input.TextArea 
                                            rows={2} 
                                            placeholder="Escreva um commentário..."
                                            onChange={(e)=> setCommentToEdit({
                                                content: e.target.value,
                                                id: comment.id
                                            })}
                                            value={commentToEdit.content}
                                            disabled={userInfo ? false : true}
                                            />
                                            
                                            <div className={"flex-horizontal"}>
                                                <Button type="primary" disabled={isLoadingComment} className={"edit-button"} onClick={() => {handleEditComment(commentToEdit)}}>
                                                    Editar
                                                </Button>
                                                <Button type="primary" disabled={isLoadingComment} className={"edit-button"} onClick={() => {setCommentEditMode(false)}}>
                                                    Cancelar
                                                </Button>
                                            </div>
                                        </> :
                                            <p>{comment.content}</p>
                                    }
                                    <Collapse className="reply-collapse" bordered={false}>
                                        <Panel header="responder" key="1" >
                                            <div className="reply-form">
                                                <Input.TextArea 
                                                    rows={2} 
                                                    placeholder="Escreva sua resposta"
                                                    onChange={(e)=> setCommentReply({ 
                                                        user: { name: userInfo ? userInfo.userName : ''} ,
                                                        content: e.target.value,
                                                        commentId: comment.id,
                                                        createdAt: new Date()
                                                    })}
                                                    disabled={userInfo ? false : true}
                                                />
                                                
                                                <div className={"flex-horizontal"}>
                                                    {userInfo ?
                                                        (<Button type="primary" disabled={isLoadingComment} className={"btn btn-primary"} onClick={() => {handleReply()}}>
                                                        Responder
                                                        </Button>)
                                                        :
                                                        
                                                        (
                                                            <div className="cadastro-group">
                                                                <span>Para fazer um comentário é necessário ter uma conta e estar logado.</span>
                                                                <span>Caso não tenha conta cadastre-se clicando no botão abaixo.</span>
                                                                <Link to={'/registro'}>
                                                                    Cadastre-se
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                                {
                                    replies?.map((reply) => {
                                        const replyDate = new Date(reply.createdAt).toLocaleString('pt-BR', {dateStyle: "short", timeStyle: 'short' });
                                        return (
                                            <div className="comment-reply" key={reply.id}>
                                                <div className="comment-group">
                                                    <span className="name">{reply.user.name}</span>
                                                    <div>
                                                        <span>{replyDate}</span>
                                                        {
                                                            userInfo && userInfo.id === reply.author &&
                                                            <FiTrash2 color='#c53030' className="delete-button" onClick={() => handleDeleteComment(reply, true)} />
                                                        }
                                                    </div>
                                                </div>
                                                <p>{reply.content}</p>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )
                    }):
                    <strong>Não há comentários para essa postagem.</strong>
                        
                    }
                </div>
                {post?.comentable === true ?
                    <>
                        <p>COMENTE SEUS PENSAMENTOS</p>
                        <Input.TextArea 
                            rows={2} 
                            placeholder="Escreva um commentário..."
                            onChange={(e)=> setComment({ 
                                user: { name: userInfo ? userInfo.userName : ''} ,
                                content: e.target.value,
                                createdAt: new Date()
                            })}
                            value={comment.content}
                            disabled={userInfo ? false : true}
                        />
                        
                        <div className={"flex-horizontal"}>
                            {userInfo ?
                                (<Button type="primary" disabled={isLoadingComment} className={"btn btn-primary"} onClick={() => {handleClickComment()}}>
                                Comentar
                                </Button>)
                                :
                                
                                (
                                    <div className="cadastro-group">
                                        <span>Para fazer um comentário é necessário ter uma conta e estar logado.</span>
                                        <span>Caso não tenha conta cadastre-se clicando no botão abaixo.</span>
                                        <Link to={'/registro'}>
                                            Cadastre-se
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </>
                    :
                    <strong>A inclusão de novos comentários nesta postagem está desativada.</strong>
                    

                }
            </section>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")} />
        </div>
    )
}

export default BlogPost;
