import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineClockCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Modal } from 'antd';
import axios from 'axios';
import { isBefore } from 'date-fns';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Button from '../../../components/button';
import Socials from '../../../components/socials';
import Loading from '../../../components/loading';
import RegisterLoginModal from '../../../components/RegisterLoginModal';
import { eventCartAddRequest } from '../../../redux/actions/eventCartActions';
import { Constants } from '../../../constants';
import AccessControll from '../../../services/access';
import "./style.scss";

const Event = (props) => {
    const [event, setEvent] = useState();
    const [registerLoginModalIsOpen, setRegisterLoginModalIsOpen] = useState(false);
    const userLogin = useSelector(state => state.userLogin);
    const dispatch = useDispatch();
    const { userInfo } = userLogin;
    const signed = userInfo ? true : false;
    const [isLoading, setIsLoading] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const getEvent = useCallback(async () => {
        setIsLoading(true);
        const id = props.match.params.eventId;
        await axios
            .get(`${Constants.ApiUrl}/events/${id}`)
            .then((response) => {
                setEvent(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [props.match.params.eventId])

    useEffect(()=> {
        getEvent();
        AccessControll('Evento');
    }, [getEvent])

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleDiscount = (discountType, discount, price) => {
        let discountedPrice;

        if(discountType === 'PERCENTAGE'){
            discountedPrice = price - price*(discount/100)
        }else{
            discountedPrice = price - discount;
        }

        return discountedPrice > 0 ? `R$ ${discountedPrice}` : `Grátis`;
    }

    const handleCloseRegisterLoginModalState = () => {
        setRegisterLoginModalIsOpen(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //style={scrollPosition > 750 && window.innerWidth > 900 ? {position: "fixed", top: "20px", left: window.innerWidth - window.innerWidth/2.93} : {}}
    //usei isso pra fazer o card de compra do evento ficar fixado ao topo pois não consegui utilizar somente a propriedade stiky

    return (
        <div id="event-container">
            <Loading loading={isLoading} />
            <Header/>
            <Modal
                title={<span className="modal-title" style={{ paddingRight: '20px' }}>Cadastre-se ou faça login para prosseguir com sua compra.</span>}
                visible={registerLoginModalIsOpen}
                onCancel={() => setRegisterLoginModalIsOpen(false)}
                closable={true}
                footer={null}
                destroyOnClose={true}
                width="800px"
            >
                <RegisterLoginModal closeModal={handleCloseRegisterLoginModalState}/>
            </Modal>
            {
                event &&
                <section className="event-content">
                    <div className="event-header">
                        <img src={event.thumbnail_url} alt={event.name} />
                        <Socials link={window.location.href} text={event.name} />
                        <div className="event-title-author-box">
                            <div style={{marginBottom: '25px'}}>
                                <h1 className="event-title" >{event.name}</h1>
                                <h2 className="event-author">Organização: {event.organizer}</h2>
                                <div className="event-times">
                                    <div>
                                        <AiOutlineClockCircle fontSize={14} />
                                        <span>{new Date(event.date).toLocaleString('pt-BR', {dateStyle: "long"})}</span>
                                    </div>
                                    <div>
                                        <span>, {new Date(event.date).toLocaleString('pt-BR', {timeStyle: "short"})}</span>
                                        <span>até</span>
                                        <span>{new Date(event.conclusion).toLocaleString('pt-BR', {timeStyle: "short"})}</span>
                                    </div>
                                </div>
                                <h3>Vagas disponíveis: <span>{event.max_clients - event.scheduled_clients}</span></h3>
                                <h3>Status: <span>{event.status}</span></h3>
                                <h3>Evento {event.type}</h3>
                            </div>
                            <div>
                                <div
                                    className="extra-fields"
                                    style={scrollPosition > 750 && window.innerWidth > 900 ? {position: "fixed", top: "20px", left: window.innerWidth - window.innerWidth/2.93} : {}}
                                    >
                                    <table>
                                        <tbody>
                                        <tr className="tickets-table-title">
                                        <th colSpan="2">
                                        Ingressos:
                                        </th>
                                        </tr>
                                        {
                                            event.eventTicketTypes.map((type) => {
                                                return (
                                                    <tr key={type.id} className="event-tickets">
                                                        <td>
                                                            <p>{type.name} { type.description ? <Tooltip color="#a1793f" title={type.description}><span><AiFillQuestionCircle /></span></Tooltip> : ''}</p>
                                                            {
                                                                event && event.with_discount_until && isBefore(new Date(), new Date(event.with_discount_until)) ?
                                                                <>
                                                                    <p>Preço Promocional: <span>{handleDiscount(event.discount_type, event.discount, type.price)}</span></p>
                                                                    <p>Promoção válida até: <span>{`${new Date(event.with_discount_until).toLocaleString('pt-BR', {month: 'long', day: '2-digit'})} às ${new Date(event.with_discount_until).toLocaleString('pt-BR', {timeStyle: "short"})}`}</span></p>
                                                                </>
                                                                :
                                                                <p>Preço: <span>{type.price === 0 ? `Grátis` : `R$ ${type.price}`}</span></p>
                                                            }
                                                            <p className="ticket-sold-date">Vendas até: {`${new Date(type.sold_until).toLocaleString('pt-BR', {month: 'long', day: '2-digit'})} às ${new Date(type.sold_until).toLocaleString('pt-BR', {timeStyle: "short"})}`}</p>
                                                        </td>
                                                        <td>
                                                        <Button disabled={isBefore(new Date(type.sold_until), new Date()) || type.quantity === type.quantity_sold} onClick={()=> {signed ? dispatch(eventCartAddRequest(event, type)) : setRegisterLoginModalIsOpen(true)}} >
                                                            {isBefore(new Date(type.sold_until), new Date()) || type.quantity === type.quantity_sold ? 'Indisponível' : 'Comprar'}
                                                        </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    {/* <Button disabled={event.status !== 'Disponível' ? true : false} onClick={()=> {dispatch(eventCartAddRequest(event))}} >
                                        {event.status === 'Disponível' ? 'Comprar ingresso' : 'Indisponível'}
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-body" dangerouslySetInnerHTML={{__html:event.description}} />
                </section>
            }
            <Footer backgroundImage={require("../../../assets/images/orange-footer.png")} />
        </div>
    )
}

export default Event;
