import history from '../../services/history'
import { GENERAL_CART_ADD_SUCCESS, GENERAL_CART_REMOVE_SUCCESS } from "../constants/generalCartConstants"

export const generalCartAddRequest = (schedule) => async (dispatch) => {
        dispatch({
            type: GENERAL_CART_ADD_SUCCESS,
            payload: { schedule }
        })
        history.push('/pagamento-geral')

}

export const generalCartRemoveRequest = (schedules) => async (dispatch) => {
    dispatch({
        type: GENERAL_CART_REMOVE_SUCCESS,
        payload: { schedules }
    })
}