import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; 
import 'antd/dist/antd.css';
import './styles.scss';
import Router from './routes';
import { store, persistedStore } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <ConfigProvider locale={ptBR}>
          <Router />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);