import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import GaleryCarousel from '../../components/galery-carousel';
import axios from 'axios';
import EventShowcaseCard from '../../components/events/event-showcase-card';
import { Constants } from '../../constants';
import Advertisement from '../../components/advertisement';
import AccessControll from '../../services/access';

import "./style.scss";
import Socials from '../../components/socials';
import ProjectsCard from '../../components/projects-card';

const Projects = () => {

    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState();
    const [details, setDetails] = useState();

    const getEvents = useCallback(async () => {
        setIsLoading(true);
        await axios.get(`${Constants.ApiUrl}/events`, {
                params: {
                    sort: `["createdAt", "DESC"]`,
                    range: `[0,9]`
                }
            })
            .then((response) => {
                setEvents(response.data);
                setIsLoading(false);
            })
            .catch((err)=> {
                console.log(err);
                setIsLoading(false);
            })
    }, [])

    const getProjects = useCallback(async () => {
        setIsLoading(true);
        await axios
            .get(`${Constants.ApiUrl}/posts`, {
                params: { 
                    range: '[0,7]',
                    sort: `["createdAt", "DESC"]`,
                    filter: {
                        "category": "projetos",
                    }
                }
            })
            .then((response) => {
                setProjects(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
            });
    }, [])

    const getPageDetails = useCallback(async () => {
        setIsLoading(true)
        const config = {
            params: {
                filter: {
                    "name": "projetos",
                }
            }
        }

        await axios.get(`${Constants.ApiUrl}/pages`, config)
        .then((response) => {
            setDetails(response.data[0]);
            setIsLoading(false)
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        getPageDetails();
        getEvents();
        getProjects();
        AccessControll('Home Projetos');
    }, [getPageDetails, getEvents, getProjects]);

    return (
        <div id="projects">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/projects-main-image.png")}
                h1={"Eventos e Projetos Mundo Akar"}
            />

            <div className={"blog-box"}>
                {/* <h3 className={"page-title"}>Eventos e projetos</h3>
                <div className={"header"}>
                    {
                        details ?
                        <>
                            <img alt="main_image" className="back" src={details.main_image_url} />
                            <div>
                                <h3>{details.main_text}</h3>
                                <Socials/>
                            </div>
                        </>
                        :
                        <span></span>
                    }
                </div>
                {
                    details ?
                    <GaleryCarousel images={details.images} />
                    :
                    <span></span>
                } */}
                <section className={"others"} >
                    <h1>Eventos</h1>
                    <div className="cards">
                        {
                            events?.map((event) => {
                                return (
                                    <EventShowcaseCard 
                                        key={event.id}
                                        date={event.date}
                                        id={event.id}
                                        name={event.name}
                                        thumbnail_url={event.thumbnail_url}
                                        type={event.type}
                                        loading={isLoading}
                                    />
                                )
                            })
                        }
                        {
                            events.length === 0 &&
                            <span className="no-events-message">Não há nenhum evento disponível no momento</span>
                        }
                    </div>
                </section>
                <Advertisement local="events" />
                {/* <section className={"others"} >
                    <h1>Conheça nossos outros projetos</h1>
                    <div className={"flex-horizontal"}>
                    {
                        projects?.length >0 ?
                        projects.map((project) => {
                            return (
                                <ProjectsCard
                                    id={project.id}
                                    title={project.title}
                                    abstract={project.abstract}
                                    thumbnail_url={project.thumbnail_url}
                                    date={project.createdAt}
                                    key={project.id}
                                />
                            )
                        })
                        : <h1>Não temos nenhum projeto no momento.</h1>
                    }
                </div>
                </section> */}
            </div>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );
}

export default Projects;