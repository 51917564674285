import React, { useState, useEffect } from 'react';
import { Input, Modal, Rate } from 'antd';
import { parseISO } from 'date-fns';
import axios from "axios";
import { Constants } from "../../constants";
import Header from '../../components/header';
import Footer from '../../components/footer';
import Button from '../../components/button';
import Loading from '../../components/loading';
import "./style.scss";
import AccessControll from '../../services/access';

const { TextArea } = Input;

const ScheduleReview = ({ match }) => {

    const [schedule, setSchedule] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [rating, setRating] = useState();
    const [comment, setComment] = useState();

    const getScheduleData = async (scheduleId) => {
        setIsLoading(true);
        await axios.get(
            `${Constants.ApiUrl}/schedules/review/${scheduleId}`
            ).then((response) => {
                console.log(response.data);
                setIsLoading(false);
                setSchedule(response.data);
            }).catch((error) => {
                setIsLoading(false);
                Modal.error({
                    title: 'Ops!!',
                    content: error.response.data.message
                })
                console.log(error);
            })
    }

    const handleReviewReq = async () => {

        setIsLoading(true);

        const body = {
            rating: rating,
            client: schedule.client,
            comment: comment,
            schedule: schedule.id,
        }

        await axios.post(
            Constants.ApiUrl + '/reviews', body
            ).then(() => {
                Modal.success({title: 'Sucesso!', content:'Obrigado por avaliar nossos serviços!'})
                setIsLoading(false)
            })         
            .catch((error) => {
                console.log(error)
                Modal.error({
                    title: 'Ops!!',
                    content: error.response.data.message
                })
                setIsLoading(false);

            })
    }

    useEffect(() => {
        getScheduleData(match.params.scheduleId);
        AccessControll('Avaliar agendamento');
    }, [match.params.scheduleId])

    return(
        <div id="schedule-review">
            <Loading loading={isLoading} />
            <Header/>
            <section className="review-container" >
                {schedule ?
                    <>
                        <h1>Olá <strong>{ schedule.client_id.name }</strong>, avalie sua consulta!</h1>
                        <h2><strong>{ schedule.service_id.name }</strong> com <strong>{ schedule.professional_id.name } { schedule.professional_id.last_name }</strong> </h2>
                            <h2>{parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "long"})} às {parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: 'short' })}</h2>
                        <div className="input-group">
                            <Rate className="rate-input" onChange={(e) => {setRating(e)}}/>
                            <label htmlFor="comment">Comentário <smal>(opcional)</smal></label>
                            <TextArea rows={4} size="large" className="commet-input" onChange={(e) => {setComment(e.target.value)}}/>
                            <Button
                                loading={isLoading}
                                loadingSize={18}
                                onClick={() => {
                                handleReviewReq();
                                }}
                                className={"submit-button"}
                                disabled={!rating}
                            >
                                Enviar Avaliação
                            </Button>
                        </div>
                    </>
                    :
                    <h1>Não foi possível encontrar sua consulta</h1>

                }
            </section>
            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    )
}

export default ScheduleReview;

//{parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})} às {parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "short", timeStyle: 'short' })}