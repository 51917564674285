import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  IoClipboardOutline,
  IoSchoolOutline,
  IoAccessibilityOutline,
} from "react-icons/io5";
import { ImBlog } from "react-icons/im";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Loading from "../../components/loading";
import FavoriteButton from "../../components/favoriteButton";
import Button from "../../components/button";
import PostCard from "../../components/post-card";
import "./style.scss";

import { Constants } from "../../constants";
import axios from "axios";
import AccessControll from "../../services/access";
import EventShowcaseCard from "../../components/events/event-showcase-card";

const { TabPane } = Tabs;

const ServiceDetails = (props) => {
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceProfessionals, setServiceProfessionals] = useState();
  const [loading, setLoading] = useState();
  const [relatedPosts, setRelatedPosts] = useState();
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [relatedEvents, setRelatedEvents] = useState();
  const history = useHistory();

  const getServiceInfos = useCallback(async () => {
    const service = props.match.params.serviceId;
    setLoading(true);
    const details = await axios
      .get(`${Constants.ApiUrl}/services/${service}`)
      .then((response) => {
        setServiceDetails(response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
    await axios
      .get(`${Constants.ApiUrl}/services/${service}/professionals`)
      .then((response) => {
        response.data.forEach((data) => {
          setServiceProfessionals(data.serviceProfessionals);
          console.log(data.serviceProfessionals);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${Constants.ApiUrl}/posts/related`, {
        params: { tags: details.tags },
      })
      .then((response) => {
        setRelatedPosts(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${Constants.ApiUrl}/events/related`, {
        params: { tags: details.tags },
      })
      .then((response) => {
        setRelatedEvents(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.serviceId]);

  useEffect(() => {
    getServiceInfos();
    AccessControll("Detalhes Terapia");
  }, [getServiceInfos]);

  const generateSlug = (str) => {
    return str.replace(/\s+/g, "-").toLowerCase();
  };

  return (
    <div id="service-details">
      <Loading loading={loading} />
      <Header />
      <div className="service-details-container">
        {serviceDetails && (
          <section className={"service-banner flex-horizontal"}>
            <img
              className="main-service-image"
              src={serviceDetails.image_url}
              alt={generateSlug(serviceDetails.name)}
            />
            <div>
              <h1>
                {serviceDetails.name}
                <div className="fav-schedule-button">
                  <FavoriteButton type="service" item={serviceDetails} />
                  <Button
                    className="schedule"
                    // onClick={() => {history.push('/agenda-geral', {...serviceDetails})}}
                  >
                    <a
                      href={`https://api.whatsapp.com/send?phone=5585981651765&text=Gostaria de agendar uma consulta na terapia ${serviceDetails.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex-vertical">
                        <span>Agendar</span>
                        <span>consulta</span>
                      </div>
                    </a>
                  </Button>
                </div>
              </h1>
              <p>{serviceDetails.description}</p>
            </div>
          </section>
        )}
        <section className="details-pane-buttons">
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("1");
            }}
          >
            <IoClipboardOutline />
            <span>Mais</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("2");
            }}
          >
            <IoAccessibilityOutline />
            <span>Profissionais</span>
            <span>relacionados</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("3");
            }}
          >
            <IoSchoolOutline />
            <span>Cursos ofertados</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("4");
            }}
          >
            <ImBlog />
            <span>Conteúdo</span>
          </div>
        </section>
        <Tabs
          className="details-tabpane"
          animated={true}
          activeKey={activeTabPane}
        >
          <TabPane tab="Tab 1" key="1">
            {serviceDetails && (
              <section className="indications">
                <h1>Detalhes</h1>
                <h2>Indicações e benefícios:</h2>
                <p>{serviceDetails.indications_benefits}</p>
              </section>
            )}
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            {serviceProfessionals ? (
              <h1 className={"professionals-title"}>
                {serviceProfessionals.length > 1 ? (
                  <span>
                    Foram encontrados {serviceProfessionals.length}{" "}
                    profissionais disponíveis para consulta nessa terapia!
                  </span>
                ) : serviceProfessionals.length === 1 ? (
                  <span>
                    Foi encontrado 1 profissional disponível para consulta nessa
                    terapia!
                  </span>
                ) : (
                  <span>
                    Não foram encontrados profissionais disponíveis para essa
                    terapia.
                  </span>
                )}
              </h1>
            ) : (
              <h1 className={"professionals-title"}>
                Não foram encontrados profissionais disponíveis para essa
                terapia.
              </h1>
            )}
            {serviceProfessionals && (
              <section className={"professionals"}>
                {serviceProfessionals.map((prof) => {
                  return (
                    <div
                      key={prof.id}
                      className="professional flex-horizontal align-center"
                    >
                      <div>
                        <img
                          alt="profile"
                          className="back"
                          src={require("../../assets/images/solar-blue-circle.png")}
                        />
                        <img
                          alt="profile"
                          className="user"
                          src={prof.image_url}
                        />
                      </div>
                      <div>
                        <p className="title">
                          {prof.name} {prof.last_name}
                        </p>
                        <p className="description">{prof.occupation}</p>
                        <Link
                          className="more"
                          to={{
                            pathname: `/profissionais/${prof.id}/${generateSlug(
                              prof.name
                            )}`,
                            state: { ...prof },
                          }}
                        >
                          <span>saiba mais</span>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </section>
            )}
          </TabPane>
          <TabPane tab="Tab 3" key="3">
            <section className="courses">
              {relatedEvents?.length >= 1 ? (
                <div className="flex-horizontal flex-wrap justify-around">
                  {relatedEvents.map((event) => {
                    return (
                      <EventShowcaseCard
                        key={event.id}
                        date={event.date}
                        id={event.id}
                        name={event.name}
                        thumbnail_url={event.thumbnail_url}
                        type={event.type}
                        loading={loading}
                      />
                    );
                  })}
                </div>
              ) : (
                <h1>Não foi encontrado nenhum curso relacionado.</h1>
              )}
            </section>
          </TabPane>
          <TabPane tab="Tab 4" key="4">
            <section className="related-posts">
              {relatedPosts?.length >= 1 ? (
                <div className="flex-horizontal flex-wrap justify-around">
                  {relatedPosts.map((post) => {
                    return (
                      <PostCard
                        id={post.id}
                        title={post.title}
                        abstract={post.abstract}
                        thumbnail_url={post.thumbnail_url}
                        date={post.createdAt}
                        key={post.id}
                      />
                    );
                  })}
                </div>
              ) : (
                <h1>Não foi encontrado nenhum conteúdo relacionado.</h1>
              )}
            </section>
          </TabPane>
        </Tabs>
      </div>
      <Footer
        backgroundImage={require("../../assets/images/orange-footer.png")}
      />
    </div>
  );
};

export default ServiceDetails;
