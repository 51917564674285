import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";

const ProfessionalCard = ({ id, name, last_name, image_url, occupation }) => {

    const generateSlug = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    return (  
        <div className="professional-card flex-horizontal align-center">
            <div>
                <img alt="profile" className="back" src={require("../../assets/images/solar-brown-circle.svg")} />
                <img alt="profile" className="user" src={image_url} />
            </div>
            <div>
                <p className="name">{name} {last_name}</p>
                <p>{occupation}</p>
                <Link
                    className="more"
                    to={{
                    pathname: `/profissionais/${id}/${generateSlug(name)}`,
                }}>
                    <span>saiba mais</span>
                </Link>
            </div>
        </div>
    )
    
}

export default ProfessionalCard;
