import React, { useEffect, useState, useRef, useCallback } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Row, Col, Input, Button, Modal } from 'antd';
import Slide from 'react-slick';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Constants } from "../../constants";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import Loading from '../../components/loading';
import AccessControll from '../../services/access';

const Room = (props) => {

    const { TextArea } = Input;

    const [room, setRoom] = useState({});
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const getRoom = useCallback(async () => {
        const id = props.match.params.id;
        await axios
            .get(`${Constants.ApiUrl}/rooms/${id}`)
            .then((response) => {
                setRoom(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, [props.match.params.id])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
            />
        );
    }
      
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
            />
        );
    }

    useEffect(() => {
        getRoom();
        AccessControll('Sala');
    }, [getRoom])

    const sliderSetings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema:  Yup.object().shape({
            name: Yup.string().required('campo obrigatório'),
            email: Yup.string().required('campo obrigatório').email('Digite um email válido'),
            phone: Yup.string().nullable(),
            message: Yup.string().required('campo obrigatório'),
    }),
        onSubmit: async (values) => {
            setLoading(true)
            const { name, email, phone, message, type = 'Solicitação de Orçamento de Sala'} = values	

            await axios.post(`${Constants.ApiUrl}/messages/contact`, {
                    name, email, phone, message, type
                })
                .then((response)=>{
                    setLoading(false)
                    const aux = Modal.success({
                        title: 'Sucesso!',
                        content: <p className="teste">{response.data.message}</p>,
                        onOk: ()=> {
                            aux.destroy();
                        },
                        maskClosable: true,
                    });
                })
                .catch((err)=> {
                    Modal.error({
                        title: "Ops!!",
                        content: err.response.data.message,
                        onOk: ()=> {
                            setLoading(false)
                        }
                    });
                })
        },

    })

    return (
        <div id="room">
            <Loading loading={loading} />
            <Header />
            <section className="room-content">
                <div className="room-header">
                    <img src={room.thumbnail_url} alt="room-thumbnail"/>
                    <div className="room-title-author-box">
                        <div className="room-title">
                            {room.name}
                        </div>
                    </div>
                </div>
                <div className="room-description" dangerouslySetInnerHTML={{__html:room.description}} />
            </section>
            {
                room.images && 
                <section className="room-galery">
                    <h1>Galeria</h1>
                    <Slide {...sliderSetings}>
                        <div>
                            <img src={room.images[0]} alt="foto-da-sala" />
                        </div>
                        <div>
                            <img src={room.images[1]} alt="foto-da-sala" />
                        </div>
                        <div>
                            <img src={room.images[2]} alt="foto-da-sala" />
                        </div>
                        <div>
                            <img src={room.images[3]} alt="foto-da-sala" />
                        </div>
                        <div>
                            <img src={room.images[4]} alt="foto-da-sala" />
                        </div>
                        <div>
                            <img src={room.images[5]} alt="foto-da-sala" />
                        </div>
                    </Slide>
                </section>
            }
            <section className={"service-form"}>
                <h3>Consulte disponibilidade para locação</h3>
                <form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className={"service-form-box flex-horizontal align-center"}>
                        <Col span={12}>
                            <div>
                                <label>Nome*</label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (<div className="form-field-edit-validate">{formik.errors.name}</div>) : null}
                            <div>
                                <label>Tel</label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                            </div>
                            {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}
                            <div>
                                <label>Email*</label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                            {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                            <div>
                                <TextArea
                                    rows={5}
                                    placeholder={"Escreva aqui sua mensagem"}
                                    id="message"
                                    name="message"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                />
                            </div>
                            {formik.touched.message && formik.errors.message ? (<div className="form-field-edit-validate">{formik.errors.message}</div>) : null}
                            <div>
                            <Button type="submit" onClick={()=>formik.submitForm()} className={"btn btn-primary"}>Enviar</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </section>

            <Footer backgroundImage={require("../../assets/images/blue-footer.png")} />
        </div>
    )
}

export default Room;
