/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "./style.scss";

export default class Loading extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const antIcon = <LoadingOutlined className='loading-svg2' spin />;
        return (
            <div className="loaging-container2" style={{display: this.props.loading ? '' : 'none' }}>
                <Spin indicator={antIcon} />
            </div>
        )
    }
}

