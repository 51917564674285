import React, { useCallback, useEffect, useState } from 'react';
import Slide from 'react-slick';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { Constants } from "../../constants";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const Advertisement = ({ local, tags }) => {

    const [ads, setAds] = useState();

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineRight
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }
      
    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineLeft
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }

    const sliderSetings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        cssEase: "linear",
        nextArrow: <SampleNextArrow className="slickArrow" />,
        prevArrow: <SamplePrevArrow className="slickArrow" />
    };

    const getAds = useCallback(async (local) => {
        await axios
            .get(`${Constants.ApiUrl}/advertisements`,
            { 
                params: 
                { 
                    range: '[0,10]',
                    filter: {
                        "active": "true",
                        "local": `${local}`,
                        "tags": `${tags ? tags : ''}`
                    }
                }
            })
            .then((response) => {
                setAds(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [tags])

    const handleAdClick = async (id) => {
        await axios.patch(`${Constants.ApiUrl}/advertisements/${id}`, null, null);
    }

    useEffect(() => {
        getAds(local);
    }, [getAds, local])

    return (
        <div id="advertisement" >
            <div className={"advertisement-desktop"} >
                <Slide {...sliderSetings}>
                    {
                        ads?.map((ad) => {
                            return (
                                <div key={ad.id}>
                                    <a onClick={() => {handleAdClick(ad.id)}} target="_blank" rel="noopener noreferrer" href={ad.link}>
                                        <img src={ad.desktop_image_url} alt={ad.name} />
                                    </a>
                                </div>
                            )
                        })
                    }
                </Slide>
            </div>
            <div className={"advertisement-mobile"} >
                <Slide {...sliderSetings}>
                    {
                        ads?.map((ad) => {
                            return (
                                <div key={ad.id}>
                                    <a target="_blank" rel="noopener noreferrer" href={ad.link}>
                                        <img src={ad.mobile_image_url} alt={ad.name} />
                                    </a>
                                </div>
                            )
                        })
                    }
                </Slide>
            </div>
        </div>
    )

}

export default Advertisement;