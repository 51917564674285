/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Upload, message, Modal, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import axios from 'axios';
import cpfMask from '../../../utils/cpfMask';
import phoneMask from '../../../utils/phoneMask';
import cepMask from '../../../utils/cepMask';
import { Constants } from "../../../constants";
import moment from 'moment';
import Button from '../../../components/button';
import { isBefore } from 'date-fns';
import "./style.scss";

function EditUser ({ userData, state }) {
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [imageUrl, setImageUrl] = useState(userData.image_url)
  const [curriculum, setCurriculum] = useState()
  const [diplomas, setDiplomas] = useState()
  const dispatch = useDispatch();
  const userInfo = useSelector((state)=> state.userLogin.userInfo);
  const { Option } = Select;
  const nomeRegex = RegExp(/^[a-zA-Z\u00C0-\u00FF ]+$/)
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Avatar</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Somente arquivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Aquivo deve ser menor que 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>{
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const curriculumRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
        setCurriculum(file);
    }, 0);
  }

  const diplomasRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
          onSuccess("ok");
          setDiplomas(file);
      }, 0);
  }

  function beforeUploadCurriculum(file) {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('Somente arquivos PDF!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Aquivo deve ser menor que 2MB!');
    }
    return isPdf && isLt2M;
}

function beforeUploadDiploma(file) {
    const isZip = file.type === 'application/x-zip-compressed';
    if (!isZip) {
      message.error('Somente arquivos .zip');
      file.status = 'error';
      file.response = 'Somente arquivos .zip'
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Aquivo deve ser menor que 5MB!');
      file.status = 'error';
      file.response = 'Aquivo deve ser menor que 5MB!'
    }
    return isZip && isLt2M;
}

  const handleAutoComplete = async (cep) => {
        
    await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
            const address = response.data;

            formik.setFieldValue('av', address.logradouro);
            formik.setFieldValue('bairro', address.bairro);
            formik.setFieldValue('cidade', address.localidade);
            formik.setFieldValue('estado', address.uf);
        })
        .catch(err => {
            console.log(err);
        })
  }

  useEffect(()=> {
    async function getStates(){
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      const ufInitials = response.data.map(uf => uf.sigla);
      setStates(ufInitials.sort())
    }
    getStates()
  },[])
  
  const formik = useFormik({
		initialValues: {
			pnome: userData.name || '',
      sobrenome: userData.last_name || '',
      email: userData.email || '',
      phone: userData.phone || '',
      cpf: userData.cpf_cnpj || '',
      cep: userData.zip_code || '',
      av: userData.street || '',
      numero: userData.street_number || '',
      comp:userData.complement || '',
      bairro: userData.neighborhood || '',
      cidade: userData.city || '',
      estado: userData.state || '',
      obs: userData.obs || '',
      birth: userData.birth || '',
      gender: userData.gender || '',
      company_name: userData.company_name || '',
      company_field: userData.company_field || '',
      business_contact: userData.business_contact || '',
      financial_contact: userData.financial_contact || '',
      employee_quantity: userData.employee_quantity || 0,
		},
		validationSchema:  Yup.object().shape({
      pnome: Yup.string().matches(nomeRegex, 'Números não são permitiros').nullable(),
      sobrenome: Yup.string().nullable().matches(nomeRegex, 'Números não são permitidos'),
      email: Yup.string().email('Digite um email válido').nullable(),
      phone: Yup.string().nullable(),
      cpf: Yup.string().nullable(),
      cep: Yup.string().nullable(),
      av: Yup.string().nullable(),
      numero: Yup.number('Digite um número').nullable(),
      comp: Yup.string().nullable(),
      bairro: Yup.string().nullable(),
      cidade: Yup.string().nullable(),
      estado: Yup.string().nullable(),
      obs: Yup.string().nullable(),
      company_name: Yup.string().nullable(),
      company_field: Yup.string().nullable(),
      business_contact: Yup.string().nullable(),
      financial_contact: Yup.string().nullable(),
      employee_quantity: Yup.number().nullable(),
    }),
		onSubmit: async (values) => {
      const {
        pnome,
        sobrenome,
        email,
        phone,
        cpf,
        cep,
        av,
        numero,
        comp,
        bairro,
        cidade,
        estado,
        obs,
        birth,
        gender,
        company_name,
        company_field,
        business_contact,
        financial_contact,
        employee_quantity
      } = values

      const formValues = {
        name: pnome,
        last_name: sobrenome,
        email: email,
        birth: birth,
        gender: gender,
        cpf_cnpj: cpf.replace(/(\.)/g, "").replace('-', ''),
        phone: phone.replace(/(\ )/g, "").replace('-', ''),
        zip_code: cep,
        street :av,
        street_number :numero,
        complement :comp,
        neighborhood :bairro,
        city :cidade,
        state: estado,
        mini_curriculum: obs,
        company_name: company_name,
        company_field: company_field,
        employee_quantity: employee_quantity,
        business_contact: business_contact,
        financial_contact: financial_contact,
        image64: imageUrl,
    }

    let formData = new FormData();

    Object.keys(formValues).forEach((key) => {
        formData.append(key, formValues[key])
    })

    formData.append('curriculum', curriculum);
    formData.append('diplomas', diplomas)

      try {
        const response = await axios.put(Constants.ApiUrl + '/users/' + userData.id, formData,
        {
          headers: {
              'Authorization': `Bearer ${userData.token}`,
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
          },
        }  
        )
        const date = new Date(response.data.birth)

        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()

        const parsedData = `${day}/${month}/${year}`
        state.setState({userData: response.data, parsedBirth: parsedData})
        dispatch({
          type: 'USER_LOGIN_SUCCESS',
          payload: {
            data: userInfo,
            userData: {
                name: pnome,
                email: email,
                last_name: sobrenome,
                phone: phone.replace(/(\ )/g, "").replace('-', ''),
                cpf_cnpj: cpf.replace(/(\.)/g, "").replace('-', ''),
                zip_code: cep,
                street: av,
                street_number: numero,
                complement: comp,
                neighborhood: bairro,
                city: cidade,
                state: estado,
                company_name,
                company_field,
                business_contact,
                financial_contact,
                employee_quantity,
                image_url: response.data.image_url,
            }
          }
        })
        setLoadingUpdate(false);
        Modal.success({
          title: "Perfil Atualizado com sucesso!"
        });
        state.setState({
          visible: false,
        });
        
      } catch (error) {
        Modal.warning({
          title: "Erro ao autalizar Perfil!",
          content: error
        })
      }
		},
    
  })

  useEffect(()=> {
    async function getCities(){
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${formik.values.estado}/municipios`)
      const cityName = response.data.map(city => city.nome);
      setCities(cityName.sort())
    }
    getCities()
  }, [formik.values.estado])

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
}
function handleDisableDate(currentDate) {
  const year = new Date().getFullYear() -18;
  const limitDate = new Date(year, 12, 31, 12, 59, 59)
  if (isBefore(limitDate, new Date(currentDate))){
      return true
  }else {
      return false
  }
}
function handleDatePickerDefaultValue(currentDate) {
  const year = new Date().getFullYear() -18;
  
  return moment(`${year}-12-31`, 'YYYY-MM-DD')
}
function handleClick() {
  setLoadingUpdate(true)
}
  return (
      <form onSubmit={formik.handleSubmit}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
        <div className="input-grid-group">
          <div className="input">
            <label htmlFor="pname" className="label">Primeiro nome*</label>
            <Input 
              id="pnome"
							name="pnome"
							type="text"
							onChange={formik.handleChange}
							value={formik.values.pnome}/>
              {formik.touched.pnome && formik.errors.pnome ? (<div className="form-field-edit-validate">{formik.errors.pnome}</div>) : null}
          </div>
          <div className="input">
            <label htmlFor="sobrenome" className="label">Sobrenome*</label>
            <Input id="sobrenome" name="sobrenome" type="text" onChange={formik.handleChange}
							value={formik.values.sobrenome} className="form-input"/>
             {formik.touched.sobrenome && formik.errors.sobrenome ? (<div className="form-field-edit-validate">{formik.errors.sobrenome}</div>) : null} 
          </div>
        </div>
        <div className="input">
          <label htmlFor="email" className="label">Email*</label>
          <Input id="email" name="email" onChange={formik.handleChange}
							value={formik.values.email} type="text" className="form-input"/>
          {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
        </div>
        <div className="input-grid-group" style={{display: userData.role === 'company' ? 'none' : ''}}>
          <div className="input">
            <label htmlFor="birth" className="label">Data de nascimento*</label>
            <DatePicker
              id="birth"
              name="birth"
              format="DD/MM/YYYY"
              defaultPickerValue={handleDatePickerDefaultValue()}
              disabledDate={(currentDate)=> handleDisableDate(currentDate)}
              placeholder="Sex, 27 dez 2002"
              onChange={(date, _) => formik.setFieldValue('birth', moment(date))}
              value={moment(formik.values.birth)}
            />
            {formik.touched.birth && formik.errors.birth ? (<div className="form-field-edit-validate">{formik.errors.birth}</div>) : null}
          </div>
          <div className="input">
            <label htmlFor="gender" className="label">Gênero*</label>
            <Select
                  id="gender"
                  name="gender"
                  size='middle'
                  showSearch
                  className="form-field-select-signup"
                  onChange={(value)=> formik.setFieldValue('gender', value)}
                  value={formik.values.gender}
                >
                  <Option value="Masculino">Masculino</Option>
                  <Option value="Feminino">Feminino</Option>
                  <Option value="Outros">Outros</Option>
            </Select>
            {formik.touched.gender && formik.errors.gender ? (<div className="form-field-edit-validate">{formik.errors.gender}</div>) : null}
          </div>
        </div>
        <div className="input-grid-group">
          <div className="input">
            <label htmlFor="phone" className="label">Telefone | Whatsapp*</label>
            <Input id="phone" type="text" name="phone" onChange={formik.handleChange}
              maxLength="15" 	value={phoneMask(formik.values.phone)} placeholder="Somente números" className="form-input"/>
            {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}    
          </div>
          <div className="input">
            <label htmlFor="cpf" className="label">{userData.role !== 'client' ? "CPF/CNPJ*" : "CPF"}</label>
            <Input id="cpf" type="text" name="cpf" onChange={formik.handleChange}
              maxLength="14"	value={cpfMask(formik.values.cpf)} placeholder="Somente números" className="form-input"/>
            {formik.touched.cpf && formik.errors.cpf ? (<div className="form-field-edit-validate">{formik.errors.cpf}</div>) : null}    
          </div>
        </div>
        <div className="input-grid-group" style={{display: userData.role === 'company' ? '' : 'none'}}>
          <div className="input">
            <label htmlFor="company_name" className="label">Nome da Empresa</label>
            <Input id="company_name" type="text" name="company_name" onChange={formik.handleChange}
              value={formik.values.company_name} className="form-input"/>
            {formik.touched.company_name && formik.errors.company_name ? (<div className="form-field-edit-validate">{formik.errors.company_name}</div>) : null}    
          </div>
          <div className="input">
            <label htmlFor="company_field" className="label">Área de atuação</label>
            <Input id="company_field" type="text" name="company_field" onChange={formik.handleChange}
              value={formik.values.company_field} className="form-input"/>
            {formik.touched.company_field && formik.errors.company_field ? (<div className="form-field-edit-validate">{formik.errors.company_field}</div>) : null}    
          </div>
        </div>
        <div className="input-grid-group" style={{display: userData.role === 'company' ? '' : 'none'}}>
          <div className="input">
            <label htmlFor="business_contact" className="label">Contato comercial</label>
            <Input id="business_contact" type="text" name="business_contact" onChange={formik.handleChange}
              value={formik.values.business_contact} placeholder="Nome e telefone ou email" className="form-input"/>
            {formik.touched.business_contact && formik.errors.business_contact ? (<div className="form-field-edit-validate">{formik.errors.business_contact}</div>) : null}    
          </div>
          <div className="input">
            <label htmlFor="financial_contact" className="label">Contato financeiro</label>
            <Input id="financial_contact" type="text" name="financial_contact" onChange={formik.handleChange}
              value={formik.values.financial_contact} placeholder="Nome e telefone ou email" className="form-input"/>
            {formik.touched.financial_contact && formik.errors.financial_contact ? (<div className="form-field-edit-validate">{formik.errors.financial_contact}</div>) : null}    
          </div>
        </div>
        <div className="input-grid-group" style={{display: userData.role === 'company' ? '' : 'none'}}>
          <div className="input" style={{display: userData.role === 'company' ? '' : 'none'}}>
            <label htmlFor="employee_quantity" className="label">Número de funcionários</label>
            <Input id="employee_quantity" type="number" name="employee_quantity" onChange={formik.handleChange}
                value={formik.values.employee_quantity} className="form-input"/>
            {formik.touched.employee_quantity && formik.errors.employee_quantity ? (<div className="form-field-edit-validate">{formik.errors.employee_quantity}</div>) : null}    
          </div>
        </div>
        <div className="input" style={{display: userData.role === 'professional' ? '' : 'none'}}>
          <label htmlFor="obs" className="label">Descrição</label>
          <Input id="obs" type="text" name="obs" onChange={formik.handleChange}
							value={formik.values.obs} placeholder="Breve resumo sobre você." className="form-input"/>
          {formik.touched.obs && formik.errors.obs ? (<div className="form-field-edit-validate">{formik.errors.obs}</div>) : null}    
        </div>
        <div className="input-group" style={{display: userData.role === 'professional' ? '' : 'none'}}>
          <div className="input">
            <label htmlFor="curriculo" className="label">Currículo</label>
            <Upload
              name="curriculum"
              className="form-input"
              showUploadList={true}
              multiple={false}
              customRequest={curriculumRequest}
              beforeUpload={beforeUploadCurriculum}
            >
              <span className="upload-button">Upload</span>
            </Upload>
          </div>
          <div className="input">
            <label htmlFor="diplomas" className="label">Diplomas</label>
            <Upload
              name="diplomas"
              className="form-input"
              showUploadList={true}
              multiple={false}
              customRequest={diplomasRequest}
              beforeUpload={beforeUploadDiploma}
            >
              <span className="upload-button">Upload</span>
            </Upload>
          </div>
        </div>
        <span className="label">Endereço</span>
        <div className="input">
          <label htmlFor="cep" className="label">CEP*</label>
          <Input id="cep" type="text" name="cep" onChange={formik.handleChange}
							maxLength="9" onBlur={e => {handleAutoComplete(e.target.value)}} value={cepMask(formik.values.cep)} placeholder="Somente números" className="form-input"/>
          {formik.touched.cep && formik.errors.cep ? (<div className="form-field-edit-validate">{formik.errors.cep}</div>) : null}
        </div>
        <div className="input">
          <label htmlFor="av" className="label">Av./Rua*</label>
          <Input id="av" type="text" name="av" onChange={formik.handleChange}
            value={formik.values.av} className="form-input"/>
          {formik.touched.av && formik.errors.av ? (<div className="form-field-edit-validate">{formik.errors.av}</div>) : null}
        </div>
        <div className="input-group">
          <div className="input">
            <label htmlFor="numero" className="label">Numero*</label>
            <Input id="numero" type="number" name="numero" onChange={formik.handleChange}
							value={formik.values.numero} className="form-input"/>
            {formik.touched.numero && formik.errors.numero ? (<div className="form-field-edit-validate">{formik.errors.numero}</div>) : null}
          </div>
          <div className="input">
            <label htmlFor="comp" className="label">Complemento*</label>
            <Input id="comp" type="text" name="comp" onChange={formik.handleChange}
							value={formik.values.comp} className="form-input"/>
            {formik.touched.comp && formik.errors.comp ? (<div className="form-field-edit-validate">{formik.errors.comp}</div>) : null}
          </div>
        </div>
        <div className="input-group">
          <div className="input">
            <label htmlFor="bairro" className="label">Bairro*</label>
            <Input id="bairro" type="text" name="bairro" onChange={formik.handleChange}
							value={formik.values.bairro} className="form-input"/>
            {formik.touched.bairro && formik.errors.bairro ? (<div className="form-field-edit-validate">{formik.errors.bairro}</div>) : null}
          </div>
          <div className="input" >
            <label htmlFor="cidade" className="label">Cidade*</label>
            <Select
                  id="cidade"
                  name="cidade"
                  size='middle'
                  showSearch
                  className="form-field-select-signup"
                  onChange={(value) => formik.setFieldValue('cidade', value)}
                  value={formik.values.cidade}
                >
                  {cities.map((city) => (
                  <Option key={city} value={city}>{city}</Option>
                ))}
                </Select>
            {formik.touched.cidade && formik.errors.cidade ? (<div className="form-field-edit-validate">{formik.errors.cidade}</div>) : null}
          </div>
          <div className="input">
            <label htmlFor="estado" className="label">Estado*</label>
            <Select
                  id="estado"
                  name="estado"
                  size='middle'
                  showSearch
                  className="form-field-select-signup"
                  onChange={(value)=> formik.setFieldValue('estado', value)}
                  value={formik.values.estado}
                >
                  {states.map((uf) => (
                  <Option key={uf} value={uf}>{uf}</Option>
                ))}
                </Select>
            {formik.touched.estado && formik.errors.estado ? (<div className="form-field-edit-validate">{formik.errors.estado}</div>) : null}
          </div>
        </div>
        <section>
          <span className="update-user-msg" style={{display: userData.role === 'professional' ? '' : 'none'}}>
            Mantenha seu cadastro sempre atualizado; Para
            modificação de demais itens, entre em contato com nossa
            administração.
          </span>
        </section>
        <Button loadingSize={20} loading={loadingUpdate} type="submit" onClick={handleClick}>
          ATUALIZAR
        </Button>
      </form>
  )
}

export default EditUser;