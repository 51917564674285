import { PLAN_CART_ADD_SUCCESS, PLAN_CART_REMOVE_SUCCESS  } from "../constants/planCartConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
    plans: [],
}

export const planCartReducer = (state = initialState, action) => {

    switch (action.type){
        case PLAN_CART_ADD_SUCCESS:
            return { ...state, plans: [ ...state.plans, action.payload.plan] }
        case PLAN_CART_REMOVE_SUCCESS:
            return initialState
        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}