const cnpjMask = (value = '') => {
    if (value){
        return value
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/,"$1.$2")
        .replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
        .replace(/\.(\d{3})(\d)/,".$1/$2")
        .replace(/(\d{4})(\d)/,"$1-$2")
    } else {
        return '';
    } 
};
  
export default cnpjMask;
