import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Row, Col, Input, Modal } from 'antd';
import { isAfter, parseISO } from 'date-fns'
import { Constants } from "../../constants";
import { planCartAddRequest } from '../../redux/actions/plansCartActions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaWhatsapp } from "react-icons/fa";
import Header from '../../components/header';
import Footer from '../../components/footer';
import Loading from '../../components/loading';
import ProfessionalCard from '../../components/professional-card';
import Service from '../../components/service';
import Button from '../../components/button';
import AccessControll from '../../services/access';
import phoneMask from '../../utils/phoneMask';
import Socials from '../../components/socials';

import "./style.scss";
const PlansDetails = (props) => {

    const [plan, setPlan] = useState({});
    const [professionals, setProfessionals] = useState([]);
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const { TextArea } = Input;

    const formRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: 'Gostaria de mais detalhes sobre o plano...',
        },
        validationSchema:  Yup.object().shape({
            name: Yup.string().required('campo obrigatório'),
            email: Yup.string().required('campo obrigatório').email('Digite um email válido'),
            phone: Yup.string().nullable(),
            message: Yup.string().required('campo obrigatório'),
    }),
        onSubmit: async (values) => {
            setIsLoading(true)
            const { name, email, phone, message, type = 'Dúvida em geral'} = values	

            await axios.post(`${Constants.ApiUrl}/messages/contact`, {
                    name, email, phone, message, type
                })
                .then((response)=>{
                    setIsLoading(false)
                    const aux = Modal.success({
                        title: 'Sucesso!',
                        content: <p className="teste">{response.data.message}</p>,
                        onOk: ()=> {
                            aux.destroy();
                        },
                        maskClosable: true,
                    });
                })
                .catch((err)=> {
                    Modal.error({
                        title: "Ops!!",
                        content: err.response.data.message,
                        onOk: ()=> {
                            setIsLoading(false)
                        }
                    });
                })
        },

    })

    const getPlan = useCallback(async () => {
        const plan = props.match.params.planId;
        setIsLoading(true);
        const planDetails = await axios.get(`${Constants.ApiUrl}/plans/${plan}`)
            .then((response) => {
                setPlan(response.data);
                return response.data;
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
        const auxServices = planDetails.services;
        const auxProfessionals = planDetails.professionals;

        if(auxProfessionals){
            const config = {
                params: {
                    filter: {
                        id: [...auxProfessionals]
                    }
                }
            }
            await axios.get(`${Constants.ApiUrl}/users/professionals`, config)
                .then((response) => {
                    setProfessionals(response.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        if(auxServices){
            const config = {
                params: {
                    filter: {
                        id: [...auxServices]
                    }
                }
            }
            await axios.get(`${Constants.ApiUrl}/services`, config)
                .then((response) => {
                    setServices(response.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        setIsLoading(false);
    }, [props.match.params.planId])

    useEffect(() => {
        getPlan();
        AccessControll('Detalhes Plano');
    }, [getPlan])

    return (
        <div id="plan-detail" >
            <Loading loading={isLoading} />
            <Header />
            <section className="plan-detail-header">
                <img src={plan.image_url} alt={plan.name}/>
                {/* <div>
                    <h1>{plan.name}</h1>
                    <h2>{plan.description}</h2>
                    <h2>{`Validade: ${new Date(plan.valid_until).toLocaleString('pt-BR', {dateStyle: 'short',})}`}</h2>
                    <h2>{`Disponível até: ${new Date(plan.sold_until).toLocaleString('pt-BR', {dateStyle: 'short',})}`}</h2>
                    <h2>Preço: R$ {plan.price}</h2>
                    <Button disabled={isAfter(parseISO(plan.sold_until), Date.now())} onClick={() => {dispatch(planCartAddRequest(plan))}} >Comprar</Button>
                </div> */}
            </section>
            <section className="plan-description">
                <h1>Detalhes</h1>
                <h2>{plan.description}</h2>
            </section>
            <section className="related-services">
                <h1 className="title">Terapias incluídas nesse plano</h1>
                <div className="container" >
                    {
                        services ?
                        services.map((service) => {
                            
                            return (
                                <Service
                                    key={service.id}
                                    title={service.name}
                                    img={service.image_url}
                                    id={service.id}
                                />
                            );
                        })
                        :
                        <h1>Este plano não contém terapias</h1>
                    }
                </div>
            </section>
            <section className="related-professionals">
                <h1 className="title">Profissionais incluídos nesse plano</h1>
                <div className="container" >
                    {
                        professionals ?
                        professionals.map((professional) => {
                            return (
                                <ProfessionalCard
                                    id={professional.id}
                                    image_url={professional.image_url}
                                    last_name={professional.last_name}
                                    name={professional.name}
                                    occupation={professional.occupation}
                                    key={professional.id}
                                />
                            );
                        })
                        :
                        <h1>Este plano não contém profissionais</h1>
                    }
                </div>
            </section>

            <section className="contact-form">
                <h1 className="form-title">
                    Entre em contato conosco pelo formulário abaixo ou pelo nosso 
                    <a
                        href={'https://api.whatsapp.com/send?phone=5585981651765&text=Digite aqui sua mensagem e nossa equipe terá o prazer de te atender.'}
                        className="whatsapp"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaWhatsapp size={40} color='#ec923c' />
                    </a>
                </h1>
                <form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className={"contact-form-box flex-horizontal align-center"}>
                        <Col span={12}>
                            <div>
                                <label>Nome*</label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (<div className="form-field-edit-validate">{formik.errors.name}</div>) : null}
                            <div>
                                <label>Tel</label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={phoneMask(formik.values.phone)}
                                />
                            </div>
                            {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}
                            <div>
                                <label>Email*</label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                            {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                            <div>
                                <TextArea
                                    rows={5}
                                    placeholder={"Escreva aqui sua mensagem"}
                                    id="message"
                                    name="message"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                />
                            </div>
                            {formik.touched.message && formik.errors.message ? (<div className="form-field-edit-validate">{formik.errors.message}</div>) : null}
                            <div>
                            <Button type="submit" onClick={()=>formik.submitForm()} className={"btn btn-primary"}>Enviar</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </section>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")} />
        </div>
    )
}

export default PlansDetails;