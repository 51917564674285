import React from 'react';
import { Link } from "react-router-dom";
import "./style.scss";

const RoomCard = ({id, thumbnail_url, name}) => {

    return (
        <div className="room-card">
            <Link
                to={{
                    pathname: "/salas/" + id,
                }}>
                <div className="box">
                    <img alt={name} src={thumbnail_url} />
                    <p className="name">{name}</p>
                </div>
            </Link>
        </div>
    )
}

export default RoomCard;