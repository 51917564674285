import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  IoClipboardOutline,
  IoSchoolOutline,
  IoAccessibilityOutline,
} from "react-icons/io5";
import { ImBlog } from "react-icons/im";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Loading from "../../components/loading";
import Service from "../../components/service";
import Button from "../../components/button";
import PostCard from "../../components/post-card";
import FavoriteButton from "../../components/favoriteButton";
import "./style.scss";

import { Constants } from "../../constants";
import axios from "axios";
import AccessControll from "../../services/access";
import EventShowcaseCard from "../../components/events/event-showcase-card";

const { TabPane } = Tabs;

const ProfessionalDetails = (props) => {
  const [professionalDetails, setProfessionalDetails] = useState();
  const [professionalServices, setProfessionalServices] = useState();
  const [professionalPosts, setProfessionalPosts] = useState();
  const [loading, setLoading] = useState();
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [relatedEvents, setRelatedEvents] = useState();
  const history = useHistory();

  const getProfessionalInfos = useCallback(async () => {
    const professional = props.match.params.professionalId;
    setLoading(true);
    await axios
      .get(`${Constants.ApiUrl}/user-services/${professional}`)
      .then((response) => {
        setProfessionalDetails(response.data);
        setProfessionalServices(response.data.serviceProfessionals);
        setProfessionalPosts(response.data.posts);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${Constants.ApiUrl}/events/related`, {
        params: { professionals: [professional] },
      })
      .then((response) => {
        setRelatedEvents(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, [props.match.params.professionalId]);

  useEffect(() => {}, [professionalDetails]);

  useEffect(() => {
    getProfessionalInfos();
    AccessControll("Detalhes profissional");
  }, [getProfessionalInfos]);

  return (
    <div id="professional-details">
      <Loading loading={loading} />
      <Header />
      <div className="professional-details-container">
        {professionalDetails && (
          <section className="professional-banner">
            <div className="flex-horizontal">
              <img
                src={professionalDetails.image_url}
                alt={professionalDetails.name}
              />
              <div>
                <h1>
                  {professionalDetails.name} {professionalDetails.last_name}
                </h1>
                <p>{professionalDetails.obs}</p>
                <p>{professionalDetails.occupation}</p>
                <p>Etc</p>
              </div>
            </div>
            <div className="flex-horizontal">
              <FavoriteButton type="professional" item={professionalDetails} />
              <div>
                <Button
                  className="schedule"
                  //   onClick={() => {
                  //     history.push("/agenda-geral");
                  //   }}
                >
                  <a
                    href={`https://api.whatsapp.com/send?phone=5585981651765&text=Gostaria de agendar uma consulta com o(a) profissional ${professionalDetails.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex-vertical">
                      <span>Agendar</span>
                      <span>consulta</span>
                    </div>
                  </a>
                </Button>
              </div>
            </div>
          </section>
        )}
        <section className="details-pane-buttons">
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("1");
            }}
          >
            <IoClipboardOutline />
            <span>Biografia</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("2");
            }}
          >
            <IoAccessibilityOutline />
            <span>Terapias ofertadas</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("3");
            }}
          >
            <IoSchoolOutline />
            <span>Cursos ofertados</span>
          </div>
          <div
            className="pane-button-frame"
            onClick={() => {
              setActiveTabPane("4");
            }}
          >
            <ImBlog />
            <span>Conteúdo</span>
          </div>
        </section>
        <Tabs
          className="details-tabpane"
          animated={true}
          activeKey={activeTabPane}
        >
          <TabPane tab="Tab 1" key="1">
            {professionalDetails && (
              <section className="biografy">
                <h1>Biografia</h1>
                <h2>Profissão</h2>
                <p>{professionalDetails.occupation}</p>
                <h2>Especialidades</h2>
                <p>{professionalDetails.specialties}</p>
                <h2>Currícurlo:</h2>
                <p>{professionalDetails.mini_curriculum}</p>
                <p></p>
              </section>
            )}
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            {professionalServices ? (
              <h1 className={"services-title"}>
                Foram encontradas {professionalServices.length} terapias para
                consulta com esse profissional.
              </h1>
            ) : (
              <h1 className={"services-title"}>
                Esse profissional por enquanto não possui terapias disponíveis
                para consulta.
              </h1>
            )}
            {professionalServices && (
              <section className={"services"}>
                <div className={"attendance"}>
                  {professionalServices.map((service) => {
                    return (
                      <Link
                        key={service.id}
                        to={{
                          pathname: "/atendimento-geral",
                          state: { ...service },
                        }}
                      >
                        <Service
                          title={service.name}
                          img={service.image_url}
                          id={service.id}
                        />
                      </Link>
                    );
                  })}
                </div>
              </section>
            )}
          </TabPane>
          <TabPane tab="Tab 3" key="3">
            <section className="courses">
              {relatedEvents?.length >= 1 ? (
                <div className="flex-horizontal flex-wrap justify-around">
                  {relatedEvents.map((event) => {
                    return (
                      <EventShowcaseCard
                        key={event.id}
                        date={event.date}
                        id={event.id}
                        name={event.name}
                        thumbnail_url={event.thumbnail_url}
                        type={event.type}
                        loading={loading}
                      />
                    );
                  })}
                </div>
              ) : (
                <h1>Não foi encontrado nenhum curso relacionado.</h1>
              )}
            </section>
          </TabPane>
          <TabPane tab="Tab 4" key="4">
            <section className="related-posts">
              {professionalPosts?.length >= 1 ? (
                <div className="flex-horizontal flex-wrap justify-around">
                  {professionalPosts.map((post) => {
                    return (
                      <PostCard
                        id={post.id}
                        title={post.title}
                        abstract={post.abstract}
                        thumbnail_url={post.thumbnail_url}
                        date={post.createdAt}
                        key={post.id}
                      />
                    );
                  })}
                </div>
              ) : (
                <h1>Este profissional ainda não publicou nenhum conteúdo.</h1>
              )}
            </section>
          </TabPane>
        </Tabs>
      </div>
      <Footer
        backgroundImage={require("../../assets/images/orange-footer.png")}
      />
    </div>
  );
};

export default ProfessionalDetails;
