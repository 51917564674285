import history from '../../services/history'
import { EVENT_CART_ADD_SUCCESS, EVENT_CART_REMOVE_SUCCESS } from "../constants/eventCartConstants"

export const eventCartAddRequest = (event, ticketType) => async (dispatch) => {
        dispatch({
            type: EVENT_CART_ADD_SUCCESS,
            payload: { event, ticketType }
        })
        history.push('/pagamento-eventos')

}

export const eventCartRemoveEvent = (event) => async (dispatch) => {
    dispatch({
        type: EVENT_CART_REMOVE_SUCCESS,
        payload: { event }
    })
}