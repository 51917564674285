import { GENERAL_CART_ADD_SUCCESS, GENERAL_CART_REMOVE_SUCCESS  } from "../constants/generalCartConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
    schedules: [],
}

export const generalCartReducer = (state = initialState, action) => {

    switch (action.type){
        case GENERAL_CART_ADD_SUCCESS:
            return { ...state, schedules: [ ...state.schedules, action.payload.schedule] }
        case GENERAL_CART_REMOVE_SUCCESS:
            return { schedules: action.payload.schedules }
        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}