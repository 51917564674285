import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Constants } from "../../constants";
import Button from '../../components/button'
import "./style.scss";
import axios from "axios";
import AccessControll from '../../services/access';

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pass: '',
            confirmPass: '',
            error: false,
            loading: false,
            token: this.props.match.params.token
        }
        
    }

    componentDidMount = () => {
        AccessControll('Trocar Senha');
    }

    handleResetPassword = async () => {
        const schemaValidate = Yup.object().shape({
            pass: Yup.string().required().min(6),
            confirmPass: Yup.string().when('pass', (pass, field) =>
            pass ? field.required().oneOf([Yup.ref('pass')]) : field
          ),
        })
        const body = {
            pass: this.state.pass,
            confirmPass: this.state.confirmPass,
        }
        if(await schemaValidate.isValid(body)){
            this.setState({
                error: false,
                loading: true,
            })
            try {
                await axios.post(Constants.ApiUrl + '/auth/resetpass/' + this.state.token, {
                    newPassword: this.state.pass
                })
                Modal.success({title: 'Senha alterada com sucesso',
                 onOk: () => {
                     this.props.history.push('/login')
                 }
                 })
            } catch (error) {
                Modal.error({title: 'Erro ao alterar senha, tente novamente mais tarde'})
            }
            this.setState({
                loading: false
            })
        }else {
            this.setState({
                error: true
            })
        }
    }

    render() {
        
        return (
            <div id="change-password">
                <div className={"flex-horizontal head align-center"}>
                    <Link to={'/'}>
                    <img alt="logo" src={require("../../assets/images/logo-with-name.png")} />
                    </Link>
                    <h1>Mudar senha</h1>
                </div>
                <div className={"change-password-box flex-horizontal align-center"}>
                    <div span={12}>
                        <img alt="change-password" src={require("../../assets/images/login.png")} />
                    </div>
                    <div span={12}>
                    
                       <div className="change-password-container">
                            <div className="change-input">
                                <label>Nova senha</label>
                                <Input.Password 
                                    onChange={(e) => {
                                        this.setState({ pass: e.target.value });
                                    }}
                                    placeholder="Nova senha"
                                />
                            </div>
                            <div className="change-input">
                                <label>Confirmar senha</label>
                                
                                <Input.Password
                                    onChange={(e) => {
                                        this.setState({ confirmPass: e.target.value });
                                    }}
                                    placeholder="Confirmar nova senha"
                                />
                            </div>
                            {this.state.error && <span className="error-forms">As senha devem ter no mínimo 6 caracteres e coincidirem.</span>}
                            <Button 
                                loading={this.state.loading}
                                loadingSize={15}
                                onClick={() => {
                                  this.handleResetPassword();
                                }}
                                className={"botao"}
                            >Mudar senha</Button>
                       </div>
                                              
                    </div>
                </div>
            </div>
        )
    }
}

export default (ChangePassword);
