import React from 'react';
import { Link } from 'react-router-dom';
import  './styles.scss';

function HeaderI({children}) {
  return (
    <div className="header">
        <Link to={'/'}>
            <img alt="logo" src={require("../../assets/images/logo-with-name.png")} />
        </Link>
        {children}
    </div>
  );
}

export default HeaderI;