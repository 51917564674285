import React, { useEffect } from 'react';
import moment from 'moment';
import "./style.scss";
import { Input, Select, message, DatePicker, ConfigProvider, Upload, Modal, Tooltip} from 'antd';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import { login } from '../../redux/actions/userActions';
import Button from '../../components/button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isBefore } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Constants } from "../../constants";
import axios from "axios";
import locale from "antd/es/locale/pt_BR";
import { useState } from 'react';
import cpfMask from '../../utils/cpfMask';
import phoneMask from '../../utils/phoneMask';
import cepMask from '../../utils/cepMask';
import cnpjMask from '../../utils/cnpjMask';
import Header from '../../components/header';

const { Option } = Select;

function Register (){
    const dispatch = useDispatch();
    const history = useHistory();
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1);
    const [role, setRole] = useState('client');
    const [curriculum, setCurriculum] = useState();
    const [diplomas, setDiplomas] = useState();
    const nomeRegex = RegExp(/^[a-zA-Z\u00C0-\u00FF ]+$/)
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isPoliciesModalOpen, setIsPoliciesModalOpen] = useState(false);

    const handleTermsModalState = () => {
        setIsTermsModalOpen(!isTermsModalOpen);
    }

    const handlePoliciesModalState = () => {
        setIsPoliciesModalOpen(!isPoliciesModalOpen);
    }
    
    const formik = useFormik({
        initialValues: {
            name: '',
            sobrenome: '',
            email: '',
            password: '',
            confirmPassword: '',
            birth: '',
            gender: '',
            role: '',
            specialties: '',
            cpf_cnpj: '',
            phone: '',
            zip_code: '',
            street :'',
            street_number :'',
            complement :'',
            neighborhood :'',
            city :'',
            state: '',
            mini_curriculum :'',
            company_name: '',
            company_field: '',
            employee_quantity: '',
            business_contact: '',
            financial_contact: '',
        },
        validationSchema:  Yup.object().shape({
        name: Yup.string().matches(nomeRegex, 'Números ou caracteres especias não são permitidos').required('Campo obrigatório'),
        sobrenome: Yup.string().matches(nomeRegex, 'Números ou caracteres especias não são permitidos').required('Campo obrigatório'),
        email: Yup.string().email('Digite um email válido').required('Campo obrigatório'),
        password: Yup.string().required('Campo obrigatório'),
        confirmPassword: Yup.string().when('password', (password, field) =>
        password ? field.required('Campo obrigatório').oneOf([Yup.ref('password')], 'Senhas não coincidem') : field),
        birth: Yup.date(),
        gender: Yup.string().required('Campo obrigatório'),
        role: Yup.string().required('Campo obrigatório'),
        specialties: role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        cpf_cnpj: role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        phone: Yup.string().required('Campo obrigatório'),
        zip_code: role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        street : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        street_number : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        complement : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        neighborhood : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        city : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        state: role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        mini_curriculum : role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        company_name: role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        company_field:role === 'professional' ? Yup.string().required('Campo obrigatório') : Yup.string(),
        employee_quantity: Yup.string(),
        business_contact: Yup.string(),
        financial_contact: Yup.string(),
    }),
        onSubmit: async (values) => {
        setLoading(true);

        const formValues = {
            name: values.name,
            last_name: values.sobrenome,
            email: values.email,
            password: values.password,
            birth: new Date(values.birth).toISOString(),
            gender: values.gender,
            role: values.role,
            specialties: values.specialties,
            cpf_cnpj: values.cpf_cnpj,
            phone: values.phone,
            zip_code: values.zip_code,
            street :values.street,
            street_number :values.street_number,
            complement :values.complement,
            neighborhood :values.neighborhood,
            city :values.city,
            state: values.state,
            mini_curriculum :values.mini_curriculum,
            company_name: values.company_name,
            company_field: values.company_field,
            employee_quantity: values.employee_quantity,
            business_contact: values.business_contact,
            financial_contact: values.financial_contact,
        }

        let formData = new FormData();

        Object.keys(formValues).forEach((key) => {
            formData.append(key, formValues[key])
        })

        formData.append('curriculum', curriculum);
        formData.append('diplomas', diplomas)

        await axios
            .post(Constants.ApiUrl + '/users/', formData,
            {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                }})
            .then((response) => {
                setLoading(false);
                let user = response.data;
                if(user.role === 'professional'){
                    Modal.success({
                        title: "Perfil criado com sucesso!",
                        content: "Nossa equipe fará uma análise do seu perfil e entrará em contato para ativação da sua conta. Obrigado!"
                    });
                }else{
                    dispatch(login(values.email, values.password))
                    localStorage.setItem("userInfo", JSON.stringify(user));
                    history.push("/");
                }
                
            })
            .catch((error) => {
                setLoading(false);
                Modal.error({
                    title: 'Ops!!',
                    content: error.response.data.message
                })
                console.log(error);
            })
        },
    })

    useEffect(()=> {
    async function getStates(){
        const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        const ufInitials = response.data.map(uf => uf.sigla);
        setStates(ufInitials.sort())
    }
    getStates()
    },[])

    useEffect(()=> {
        async function getCities(){
          const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${formik.values.state}/municipios`)
          const cityName = response.data.map(city => city.nome);
          setCities(cityName.sort())
        }
        getCities()
    }, [formik.values.state])

    useEffect(() => {
        if(
            formik.errors.email ||
            formik.errors.name ||
            formik.errors.sobrenome ||
            formik.errors.phone ||
            formik.errors.password ||
            formik.errors.birth ||
            formik.errors.gender ||
            formik.errors.role){
            setCurrentStep(1);
        }
    }, [formik.errors])

    const handleAutoComplete = async (cep) => {
        
        await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {
                const address = response.data;

                formik.setFieldValue('street', address.logradouro);
                formik.setFieldValue('neighborhood', address.bairro);
                formik.setFieldValue('city', address.localidade);
                formik.setFieldValue('state', address.uf);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function beforeUpload(file) {
        const isPdf = file.type === 'application/pdf';
        if (!isPdf) {
          message.error('Somente arquivos PDF!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Aquivo deve ser menor que 2MB!');
        }
        return isPdf && isLt2M;
    }

    function beforeUploadDiploma(file) {

        const isZip = file.type === 'application/x-zip-compressed';
        if (!isZip) {
          message.error('Somente arquivos .zip');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Aquivo deve ser menor que 5MB!');
        }
        return isZip && isLt2M;
    }

    const curriculumRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
            setCurriculum(file);
        }, 0);
    }

    const diplomasRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
            setDiplomas(file);
        }, 0);
    }

    function handleDisableDate(currentDate) {
        const year = new Date().getFullYear() -18;
        const limitDate = new Date(year, 12, 31, 12, 59, 59)
        if (isBefore(limitDate, new Date(currentDate))){
            return true
        }else {
            return false
        }
    }
    function handleDatePickerDefaultValue(currentDate) {
        const year = new Date().getFullYear() -18;
        
        return moment(`${year}-12-31`, 'YYYY-MM-DD')
    }
      
        return (
            <div id="register">
                <Header />
                <Modal visible={isTermsModalOpen} title="Termos de uso" width={1000} centered onOk={() => setIsTermsModalOpen(false)} onCancel={() => setIsTermsModalOpen(false)} className="terms-modal">
                    <p><strong>Termos e condi&ccedil;&otilde;es gerais de uso site mundoakar.com.br:</strong></p>
                    <p>Os servi&ccedil;os do mundoakar.com.br s&atilde;o foil v&aacute;lido, atrav&eacute;s do qual o site realizar&aacute; todas comunica&ccedil;&otilde;es necess&aacute;rias.</p>
                    <p>Ap&oacute;s a confirma&ccedil;&atilde;o do cadastro, o usu&aacute;rio possuir&aacute; um login e uma senha pessoal, a qual assegura ao usu&aacute;rio o acesso individual &agrave; mesma. Desta forma, compete ao usu&aacute;rio exclusivamente a manuten&ccedil;&atilde;o de referida senha de maneira confidencial e segura, evitando o acesso indevido &agrave;s informa&ccedil;&otilde;es pessoais.</p>
                    <p>Toda e qualquer atividade realizada com o uso da senha ser&aacute; de responsabilidade do usu&aacute;rio, que dever&aacute; informar prontamente a plataforma em caso de uso indevido da respectiva senha.</p>
                    <p>N&atilde;o ser&aacute; permitido ceder, vender, alugar ou transferirrnecidos pela pessoa jur&iacute;dica com a seguinte Raz&atilde;o Social: MUNDO AKAR EDUCA&Ccedil;&Atilde;O E EVENTOS EIRELE, com nome fantasia MUNDO AKAR, inscrito no CNPJ sob o n&ordm; 17.754.132/0001-01, titular da propriedade intelectual sobre software e website, conte&uacute;dos e demais ativos relacionados &agrave; plataforma mundoakar.com.br.</p>
                    <ol>
                    <li><strong>1- Do objeto</strong></li>
                    </ol>
                    <p>A plataforma visa licenciar o uso de seu software, website e demais ativos de propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usu&aacute;rios.</p>
                    <p>A plataforma caracteriza-se pela presta&ccedil;&atilde;o do seguinte servi&ccedil;o: agendamento de atendimento para terapias integrativas e inscri&ccedil;&atilde;o em cursos palestras e workshops organizados pela titular da plataform.</p>
                    <ol start="2">
                    <li><strong>2- Da aceita&ccedil;&atilde;o</strong></li>
                    </ol>
                    <p>O presente Termo estabelece obriga&ccedil;&otilde;es contratadas de livre e espont&acirc;nea vontade, por tempo indeterminado, entre a plataforma e as pessoas f&iacute;sicas ou jur&iacute;dicas, usu&aacute;rias do site.</p>
                    <p>Ao utilizar a plataforma o usu&aacute;rio aceita integralmente as presentes normas e compromete-se a observ&aacute;-las, sob o risco de aplica&ccedil;&atilde;o das penalidades cab&iacute;veis.</p>
                    <p>A aceita&ccedil;&atilde;o do presente instrumento &eacute; imprescind&iacute;vel para o acesso e para a utiliza&ccedil;&atilde;o de quaisquer servi&ccedil;os fornecidos pela empresa. Caso n&atilde;o concorde com as disposi&ccedil;&otilde;es deste instrumento, o usu&aacute;rio n&atilde;o deve utiliz&aacute;-los.</p>
                    <ol start="3">
                    <li><strong>3- Do acesso dos usu&aacute;rios</strong></li>
                    </ol>
                    <p>Ser&atilde;o utilizadas todas as solu&ccedil;&otilde;es t&eacute;cnicas &agrave; disposi&ccedil;&atilde;o do respons&aacute;vel pela plataforma para permitir o acesso ao servi&ccedil;o 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navega&ccedil;&atilde;o na plataforma ou em alguma de suas p&aacute;ginas poder&aacute; ser interrompida, limitada ou suspensa para atualiza&ccedil;&otilde;es, modifica&ccedil;&otilde;es ou qualquer a&ccedil;&atilde;o necess&aacute;ria ao seu bom funcionamento.</p>
                    <ol start="4">
                    <li><strong>4- Do cadastro</strong></li>
                    </ol>
                    <p>O acesso &agrave;s funcionalidades da plataforma exigir&aacute; a realiza&ccedil;&atilde;o de um cadastro pr&eacute;vio e, a depender dos servi&ccedil;os ou produtos escolhidos, o pagamento de determinado valor.</p>
                    <p>Ao se cadastrar o usu&aacute;rio dever&aacute; informar dados completos, recentes e v&aacute;lidos, sendo de sua exclusiva responsabilidade manter os referidos dados atualizados, bem como o usu&aacute;rio se compromete com a veracidade dos dados fornecidos.</p>
                    <p>O usu&aacute;rio se compromete a n&atilde;o informar seus dados cadastrais e/ou de acesso &agrave; plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.</p>
                    <p>Menores de 18 anos e aqueles que n&atilde;o possu&iacute;rem plena capacidade civil dever&atilde;o obter previamente o consentimento expresso de seus respons&aacute;veis legais para utiliza&ccedil;&atilde;o da plataforma e dos servi&ccedil;os ou produtos, sendo de responsabilidade exclusiva dos mesmos o eventual acesso por menores de idade e por aqueles que n&atilde;o possuem plena capacidade civil sem a pr&eacute;via autoriza&ccedil;&atilde;o.</p>
                    <p>Mediante a realiza&ccedil;&atilde;o do cadastro o usu&aacute;rio declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos servi&ccedil;os e produtos.</p>
                    <p>O usu&aacute;rio dever&aacute; fornecer um endere&ccedil;o de e-ma, de qualquer forma, a conta, que &eacute; pessoal e intransfer&iacute;vel.</p>
                    <p>Caber&aacute; ao usu&aacute;rio assegurar que o seu equipamento seja compat&iacute;vel com as caracter&iacute;sticas t&eacute;cnicas que viabilize a utiliza&ccedil;&atilde;o da plataforma e dos servi&ccedil;os ou produtos.</p>
                    <p>O usu&aacute;rio poder&aacute;, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site. O seu descadastramento ser&aacute; realizado o mais rapidamente poss&iacute;vel, desde que n&atilde;o sejam verificados d&eacute;bitos em aberto.</p>
                    <p>O usu&aacute;rio, ao aceitar os Termos e Pol&iacute;tica de Privacidade, autoriza expressamente a plataforma a coletar, usar, armazenar, tratar, ou utilizar as informa&ccedil;&otilde;es derivadas do uso dos servi&ccedil;os, do site e quaisquer plataformas, incluindo todas as informa&ccedil;&otilde;es preenchidas pelo usu&aacute;rio no momento em que realizar ou atualizar seu cadastro, al&eacute;m de outras expressamente descritas na Pol&iacute;tica de Privacidade que dever&aacute; ser autorizada pelo usu&aacute;rio.</p>
                    <ol start="5">
                    <li><strong>5- Dos servi&ccedil;os ou produtos</strong></li>
                    </ol>
                    <p>A plataforma poder&aacute; disponibilizar para o usu&aacute;rio um conjunto espec&iacute;fico de funcionalidades e ferramentas para otimizar o uso dos servi&ccedil;os e produtos.</p>
                    <p>Na plataforma os servi&ccedil;os ou produtos oferecidos est&atilde;o descritos e apresentados com o maior grau de exatid&atilde;o, contendo informa&ccedil;&otilde;es sobre suas caracter&iacute;sticas, qualidades, quantidades, composi&ccedil;&atilde;o, pre&ccedil;o, prazos de validade e origem, entre outros dados.</p>
                    <ol start="6">
                    <li><strong>6- Dos pre&ccedil;os</strong></li>
                    </ol>
                    <p>A plataforma se reserva no direito de reajustar unilateralmente, a qualquer tempo, os valores dos servi&ccedil;os ou produtos sem consulta ou anu&ecirc;ncia pr&eacute;via do usu&aacute;rio.</p>
                    <p>Os valores aplicados s&atilde;o aqueles que est&atilde;o em vigor no momento do pedido.</p>
                    <p>Os pre&ccedil;os s&atilde;o indicados em reais e n&atilde;o incluem as taxas de entrega, as quais s&atilde;o especificadas &agrave; parte e s&atilde;o informadas ao usu&aacute;rio antes da finaliza&ccedil;&atilde;o do pedido.</p>
                    <p>Na contrata&ccedil;&atilde;o de determinado servi&ccedil;o ou produto, a plataforma poder&aacute; solicitar as informa&ccedil;&otilde;es financeiras do usu&aacute;rio, como CPF, endere&ccedil;o de cobran&ccedil;a e dados de cart&otilde;es. Ao inserir referidos dados o usu&aacute;rio concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os pre&ccedil;os ent&atilde;o vigentes e informados quando da contrata&ccedil;&atilde;o. Referidos dados financeiros poder&atilde;o ser armazenados para facilitar acessos e contrata&ccedil;&otilde;es futuras.</p>
                    <ol start="7">
                    <li><strong>7- Do cancelamento</strong></li>
                    </ol>
                    <p>O usu&aacute;rio poder&aacute; cancelar a contrata&ccedil;&atilde;o dos servi&ccedil;os de acordo com os termos que forem definidos no momento de sua contrata&ccedil;&atilde;o. Ainda, o usu&aacute;rio tamb&eacute;m poder&aacute; cancelar os servi&ccedil;os em at&eacute; 7 (sete) dias ap&oacute;s a contrata&ccedil;&atilde;o, mediante contato com o e-mail: financeiro@mundoakar.com.br, de acordo com o&nbsp;C&oacute;digo de Defesa do Consumidor&nbsp;(Lei no.&nbsp;8.078/90).</p>
                    <p>O servi&ccedil;o poder&aacute; ser cancelado por:</p>
                    <ol>
                    <li>a) parte do usu&aacute;rio: nessas condi&ccedil;&otilde;es os servi&ccedil;os somente cessar&atilde;o quando conclu&iacute;do o ciclo vigente ao tempo do cancelamento;</li>
                    <li>b) viola&ccedil;&atilde;o dos Termos de Uso: os servi&ccedil;os ser&atilde;o cessados imediatamente.</li>
                    <li><strong> Do Cancelamento dos Servi&ccedil;os</strong></li>
                    </ol>
                    <p>A pol&iacute;tica de cancelamento de servi&ccedil;os da plataforma &eacute; regida conforme o&nbsp;C&oacute;digo de Defesa do Consumidor&nbsp;(Lei n&ordm;&nbsp;8.078/90).</p>
                    <p>Em caso de cancelamento dos servi&ccedil;os contratados, o(a)(s)contratante far&atilde;o jus a reembolso proporcional dos servi&ccedil;os n&atilde;o prestados, sendo ainda abatida, do saldo remanescente, multa estabelecida na oportunidade da contrata&ccedil;&atilde;o.</p>
                    <ol start="9">
                    <li><strong>8- Do suporte</strong></li>
                    </ol>
                    <p>Em caso de qualquer d&uacute;vida, sugest&atilde;o ou problema com a utiliza&ccedil;&atilde;o da plataforma, o usu&aacute;rio poder&aacute; entrar em contato com o suporte, atrav&eacute;s do e-mail: <a href="mailto:financeiro@mundoakar.com.br">financeiro@mundoakar.com.br</a> ou telefone + 55 (85) 3121-0271.</p>
                    <p>Estes servi&ccedil;os de atendimento ao usu&aacute;rio estar&atilde;o dispon&iacute;veis nos seguintes dias e hor&aacute;rios: segunda &agrave; sexta das 09h &agrave;s 12h e das 14h &agrave;s 19h .</p>
                    <ol start="10">
                    <li><strong>9- Das responsabilidades</strong></li>
                    </ol>
                    <p>&Eacute; de responsabilidade do usu&aacute;rio:</p>
                    <ol>
                    <li>a) defeitos ou v&iacute;cios t&eacute;cnicos originados no pr&oacute;prio sistema do usu&aacute;rio;</li>
                    <li>b) a correta utiliza&ccedil;&atilde;o da plataforma, dos servi&ccedil;os ou produtos oferecidos, prezando pela boa conviv&ecirc;ncia, pelo respeito e cordialidade entre os usu&aacute;rios;</li>
                    <li>c) pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condi&ccedil;&otilde;es Geral de Uso, na respectiva Pol&iacute;tica de Privacidade e na legisla&ccedil;&atilde;o nacional e internacional;</li>
                    <li>d) pela prote&ccedil;&atilde;o aos dados de acesso &agrave; sua conta/perfil (login e senha).</li>
                    </ol>
                    <p>&Eacute; de responsabilidade da plataforma:</p>
                    <ol>
                    <li>a) indicar as caracter&iacute;sticas do servi&ccedil;o;</li>
                    <li>b) as informa&ccedil;&otilde;es que foram por ele divulgadas, sendo que os coment&aacute;rios ou informa&ccedil;&otilde;es divulgadas por usu&aacute;rios s&atilde;o de inteira responsabilidade dos pr&oacute;prios usu&aacute;rios;</li>
                    <li>c) os conte&uacute;dos ou atividades il&iacute;citas praticadas atrav&eacute;s da sua plataforma.</li>
                    </ol>
                    <p>A plataforma n&atilde;o se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usu&aacute;rio &agrave; ambiente externo a sua rede.</p>
                    <p>N&atilde;o poder&atilde;o ser inclu&iacute;dos links externos ou p&aacute;ginas que sirvam para fins comerciais ou publicit&aacute;rios ou quaisquer informa&ccedil;&otilde;es il&iacute;citas, violentas, pol&ecirc;micas, pornogr&aacute;ficas, xenof&oacute;bicas, discriminat&oacute;rias ou ofensivas.</p>
                    <ol start="11">
                    <li><strong>10- Dos&nbsp;direitos autorais</strong></li>
                    </ol>
                    <p>O presente Termo de Uso concede aos usu&aacute;rios uma licen&ccedil;a n&atilde;o exclusiva, n&atilde;o transfer&iacute;vel e n&atilde;o sublicenci&aacute;vel, para acessar e fazer uso da plataforma e dos servi&ccedil;os e produtos por ela disponibilizados.</p>
                    <p>A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gr&aacute;ficos e design de interface, imagens, ilustra&ccedil;&otilde;es, fotografias, apresenta&ccedil;&otilde;es, v&iacute;deos, conte&uacute;dos escritos e de som e &aacute;udio, programas de computador, banco de dados, arquivos de transmiss&atilde;o e quaisquer outras informa&ccedil;&otilde;es e direitos de propriedade intelectual da raz&atilde;o social MUNDO AKAR EDUCA&Ccedil;&Atilde;O E EVENTOS EIRELE, observados os termos da&nbsp;Lei da Propriedade Industrial&nbsp;(Lei n&ordm;&nbsp;9.279/96),&nbsp;Lei de Direitos Autorais&nbsp;(Lei n&ordm;&nbsp;9.610/98) e&nbsp;Lei do Software&nbsp;(Lei n&ordm;&nbsp;9.609/98), est&atilde;o devidamente reservados.</p>
                    <p>Este Termos de Uso n&atilde;o cede ou transfere ao usu&aacute;rio qualquer direito, de modo que o acesso n&atilde;o gera qualquer direito de propriedade intelectual ao usu&aacute;rio, exceto pela licen&ccedil;a limitada ora concedida.</p>
                    <p>O uso da plataforma pelo usu&aacute;rio &eacute; pessoal, individual e intransfer&iacute;vel, sendo vedado qualquer uso n&atilde;o autorizado, comercial ou n&atilde;o-comercial. Tais usos consistir&atilde;o em viola&ccedil;&atilde;o dos direitos de propriedade intelectual, pun&iacute;veis nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel.</p>
                    <ol start="12">
                    <li><strong>11- Das san&ccedil;&otilde;es</strong></li>
                    </ol>
                    <p>Sem preju&iacute;zo das demais medidas legais cab&iacute;veis, a MUNDO AKAR EDUCA&Ccedil;&Atilde;O E EVENTOS EIRELE poder&aacute;, a qualquer momento, advertir, suspender ou cancelar a conta do usu&aacute;rio:</p>
                    <ol>
                    <li>a) que violar qualquer dispositivo do presente Termo;</li>
                    <li>b) que descumprir os seus deveres de usu&aacute;rio;</li>
                    <li>c) que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.</li>
                    <li><strong> Da rescis&atilde;o</strong></li>
                    </ol>
                    <p>A n&atilde;o observ&acirc;ncia das obriga&ccedil;&otilde;es pactuadas neste Termo de Uso ou da legisla&ccedil;&atilde;o aplic&aacute;vel poder&aacute;, sem pr&eacute;vio aviso, ensejar a imediata rescis&atilde;o unilateral por parte da plataforma e o bloqueio de todos os servi&ccedil;os prestados ao usu&aacute;rio.</p>
                    <ol start="14">
                    <li><strong>12- Das altera&ccedil;&otilde;es</strong></li>
                    </ol>
                    <p>Os itens descritos no presente instrumento poder&atilde;o sofrer altera&ccedil;&otilde;es, unilateralmente e a qualquer tempo, por parte de plataforma, para adequar ou modificar os servi&ccedil;os, bem como para atender novas exig&ecirc;ncias legais. As altera&ccedil;&otilde;es ser&atilde;o veiculadas pelo site&nbsp; e o usu&aacute;rio poder&aacute; optar por aceitar o novo conte&uacute;do ou por cancelar o uso dos servi&ccedil;os, caso seja assinante de algum servi&ccedil;o.</p>
                    <p>Os servi&ccedil;os oferecidos podem, a qualquer tempo e unilateralmente, e sem qualquer aviso pr&eacute;vio, ser deixados de fornecer, alterados em suas caracter&iacute;sticas, bem como restringido para o uso ou acesso.</p>
                    <p>&nbsp;</p>
                    <ol start="15">
                    <li><strong>13- Da pol&iacute;tica de privacidade</strong></li>
                    </ol>
                    <p>Al&eacute;m do presente Termo, o usu&aacute;rio dever&aacute; consentir com as disposi&ccedil;&otilde;es contidas na respectiva Pol&iacute;tica de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>&nbsp;</strong></p>
                    <ol start="16">
                    <li><strong>14- Do foro</strong></li>
                    </ol>
                    <p>Para a solu&ccedil;&atilde;o de controv&eacute;rsias decorrentes do presente instrumento ser&aacute; aplicado integralmente o Direito brasileiro.</p>
                    <p>&nbsp;</p>
                    <p>Os eventuais lit&iacute;gios dever&atilde;o ser apresentados no foro da comarca de Fortaleza, capital do estado do Cear&aacute;.</p>
                    <p>&nbsp;</p>
                </Modal>
                <Modal visible={isPoliciesModalOpen} title="Política de dados" width={1000} centered onOk={() => setIsPoliciesModalOpen(false)} onCancel={() => setIsPoliciesModalOpen(false)} className="terms-modal">
                    <p><strong>POL&Iacute;TICA DE PRIVACIDADE</strong></p>
                    <p><strong>SE&Ccedil;&Atilde;O 1 - INFORMA&Ccedil;&Otilde;ES GERAIS</strong></p>
                    <p>A presente Pol&iacute;tica de Privacidade cont&eacute;m informa&ccedil;&otilde;es sobre coleta, uso, armazenamento, tratamento e prote&ccedil;&atilde;o dos dados pessoais dos usu&aacute;rios e visitantes do site mundoakar.com.br, com a finalidade de demonstrar absoluta transpar&ecirc;ncia quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que s&atilde;o coletados, os motivos da coleta e a forma como os usu&aacute;rios podem gerenciar ou excluir as suas informa&ccedil;&otilde;es pessoais.</p>
                    <p>Esta Pol&iacute;tica de Privacidade aplica-se a todos os usu&aacute;rios e visitantes do do site mundoakar.com.br e integra os Termos e Condi&ccedil;&otilde;es Gerais de Uso do s&iacute;tio virtual do Mundo Akar, devidamente inscrita no CNPJ sob o n&ordm; 17.754.132/0001-01, situado em Rua Felipe Neri, no. 1000, Patriolino Ribeiro, Fortaleza/CE, CEP 60.810-310, doravante nominado SITE.</p>
                    <p>O presente documento foi elaborado em conformidade com a Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais (Lei&nbsp;13.709/18), o&nbsp;Marco Civil da Internet&nbsp;(Lei&nbsp;12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poder&aacute; ser atualizado em decorr&ecirc;ncia de eventual atualiza&ccedil;&atilde;o normativa, raz&atilde;o pela qual se convida o usu&aacute;rio a consultar periodicamente esta se&ccedil;&atilde;o.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USU&Aacute;RIO E DO VISITANTE?</strong></p>
                    <p>Os dados pessoais do usu&aacute;rio e visitante s&atilde;o recolhidos pela plataforma da seguinte forma:</p>
                    <ul>
                    <li>Quando o usu&aacute;rio cria uma conta/perfil na plataforma mundoakar.com.br, preenche os dados b&aacute;sicos de identifica&ccedil;&atilde;o, quais sejam, e-mail, nome completo, telefone, CPF e endere&ccedil;o completo. A partir deles, podemos identificar o usu&aacute;rio e o visitante, al&eacute;m de garantir uma maior seguran&ccedil;a e bem-estar &agrave;s suas necessidades.</li>
                    <li>Quando um usu&aacute;rio e/ou visitante acessa o site, as informa&ccedil;&otilde;es sobre intera&ccedil;&atilde;o e acesso s&atilde;o coletadas pela empresa para garantir uma melhor experi&ecirc;ncia ao usu&aacute;rio e/ou Estes dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento espec&iacute;fico, coment&aacute;rios, visualiza&ccedil;&otilde;es de p&aacute;ginas, perfis, a URL de onde o usu&aacute;rio e visitante prov&ecirc;m, o navegador que utilizam e seus IPs de acesso, dentre outras que poder&atilde;o ser armazenadas e retidas.</li>
                    </ul>
                    <p>&nbsp;</p>
                    <p><strong>SE&Ccedil;&Atilde;O 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USU&Aacute;RIO E VISITANTE?</strong></p>
                    <p>Os dados pessoais do usu&aacute;rio e visitante recolhidos s&atilde;o os seguintes:</p>
                    <ul>
                    <li>Dados para a cria&ccedil;&atilde;o da conta/perfil na plataforma: e-mail, nome completo, telefone, CPF e endere&ccedil;o completo.</li>
                    <li>Dados para otimiza&ccedil;&atilde;o da navega&ccedil;&atilde;o: acesso a p&aacute;ginas, palavras-chave utilizadas na busca e coment&aacute;rios.</li>
                    <li>Dados para concretizar transa&ccedil;&otilde;es: dados referentes ao pagamento e transa&ccedil;&otilde;es, tais como, n&uacute;mero do cart&atilde;o de cr&eacute;dito e outras informa&ccedil;&otilde;es sobre o cart&atilde;o, al&eacute;m dos pagamentos efetuados.</li>
                    <li>Newsletter: o e-mail cadastrado pelo visitante que optar por se inscrever na Newsletter ser&aacute; coletado e armazenado at&eacute; que o usu&aacute;rio solicite o descadastro.</li>
                    </ul>
                    <p>&nbsp;</p>
                    <p><strong>SE&Ccedil;&Atilde;O 3 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USU&Aacute;RIO E VISITANTE?</strong></p>
                    <p>Os dados pessoais do usu&aacute;rio e do visitante coletados e armazenados pela plataforma mundoakar.com.br tem por finalidade:</p>
                    <ul>
                    <li>Bem-estar do usu&aacute;rio e visitante: aprimorar o servi&ccedil;o oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usu&aacute;rio e a empresa, melhorar a experi&ecirc;ncia dos usu&aacute;rios e fornecer funcionalidades espec&iacute;ficas a depender das caracter&iacute;sticas b&aacute;sicas do usu&aacute;rio.</li>
                    <li>Melhorias da plataforma: compreender como o usu&aacute;rio utiliza os servi&ccedil;os da plataforma, para ajudar no desenvolvimento de neg&oacute;cios e t&eacute;cnicas.</li>
                    <li>An&uacute;ncios: apresentar an&uacute;ncios personalizados para o usu&aacute;rio com base nos dados fornecidos.</li>
                    <li>Comercial: os dados s&atilde;o usados para personalizar o conte&uacute;do oferecido e gerar subs&iacute;dio &agrave; plataforma para a melhora da qualidade no funcionamento dos servi&ccedil;os.</li>
                    <li>Previs&atilde;o do perfil do usu&aacute;rio: tratamentos automatizados de dados pessoais para avaliar o uso na plataforma.</li>
                    <li>Dados de cadastro: para permitir o acesso do usu&aacute;rio a determinados conte&uacute;dos da plataforma, exclusivo para usu&aacute;rios cadastrados</li>
                    <li>&agrave;s partes seguran&ccedil;a jur&iacute;dica e facilitar a conclus&atilde;o do neg&oacute;cio.</li>
                    <li>Outras</li>
                    </ul>
                    <p>O tratamento de dados pessoais para finalidades n&atilde;o previstas nesta Pol&iacute;tica de Privacidade somente ocorrer&aacute; mediante comunica&ccedil;&atilde;o pr&eacute;via ao usu&aacute;rio e/ou visitante, de modo que os direitos e obriga&ccedil;&otilde;es aqui previstos permanecem aplic&aacute;veis.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?</strong></p>
                    <p>Os dados pessoais do usu&aacute;rio e/ou visitante s&atilde;o armazenados pela plataforma durante o per&iacute;odo necess&aacute;rio para a presta&ccedil;&atilde;o do servi&ccedil;o ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso&nbsp;I&nbsp;do artigo&nbsp;15&nbsp;da Lei&nbsp;13.709/18.</p>
                    <p>Os dados podem ser removidos ou anonimizados a pedido do usu&aacute;rio, excetuando os casos em que a lei oferecer outro tratamento.</p>
                    <p>Ainda, os dados pessoais dos usu&aacute;rios, &nbsp;apenas podem ser conservados ap&oacute;s o t&eacute;rmino de seu tratamento nas seguintes hip&oacute;teses previstas no artigo 16 da referida lei:</p>
                    <p>I - cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador;</p>
                    <p>II - estudo por &oacute;rg&atilde;o de pesquisa, garantida, sempre que poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos dados pessoais;</p>
                    <p>III - transfer&ecirc;ncia a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;</p>
                    <p>IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 5 - SEGURAN&Ccedil;A DOS DADOS PESSOAIS ARMAZENADOS</strong></p>
                    <p>A plataforma se compromete a aplicar as medidas t&eacute;cnicas e organizativas aptas a proteger os dados pessoais de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es de destrui&ccedil;&atilde;o, perda, altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou difus&atilde;o de tais dados.</p>
                    <p>Os dados relativos a cart&otilde;es de cr&eacute;dito s&atilde;o criptografados usando a tecnologia "secure socket layer" (SSL) que garante a transmiss&atilde;o de dados de forma segura e confidencial.</p>
                    <p>A plataforma se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usu&aacute;rio, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usu&aacute;rio em caso de alguma viola&ccedil;&atilde;o de seguran&ccedil;a dos seus dados pessoais.</p>
                    <p>Os dados pessoais armazenados s&atilde;o tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informa&ccedil;&otilde;es pessoais caso sejamos obrigados pela lei para faz&ecirc;-lo ou se voc&ecirc; violar nossos Termos de Servi&ccedil;o.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 6 - COMPARTILHAMENTO DOS DADOS</strong></p>
                    <p>O compartilhamento de dados do usu&aacute;rio ocorre apenas com os dados referentes a publica&ccedil;&otilde;es realizadas pelo pr&oacute;prio usu&aacute;rio, tais a&ccedil;&otilde;es s&atilde;o compartilhadas publicamente com os outros usu&aacute;rios.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 7 - CONSENTIMENTO</strong></p>
                    <p>Ao utilizar os servi&ccedil;os e fornecer as informa&ccedil;&otilde;es pessoais na plataforma, o usu&aacute;rio est&aacute; consentindo com a presente Pol&iacute;tica de Privacidade.</p>
                    <p>O usu&aacute;rio, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informa&ccedil;&otilde;es por ele disponibilizadas.</p>
                    <p>O usu&aacute;rio tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato atrav&eacute;s do e-mail financeiro@mundoakar.com.br&nbsp;ou por correio enviado ao seguinte endere&ccedil;o: Rua Felipe Neri, no. 1000, Patriolino Ribeito, Fortaleza/CE, CEP 60.810-310.</p>
                    <p>&nbsp;</p>
                    <p><strong>SE&Ccedil;&Atilde;O 8 - ALTERA&Ccedil;&Otilde;ES PARA ESSA POL&Iacute;TICA DE PRIVACIDADE</strong></p>
                    <p>Reservamos o direito de modificar essa Pol&iacute;tica de Privacidade a qualquer momento, ent&atilde;o, &eacute; recomend&aacute;vel que o usu&aacute;rio e visitante revise-a com frequ&ecirc;ncia.</p>
                    <p>As altera&ccedil;&otilde;es e esclarecimentos v&atilde;o surtir efeito imediatamente ap&oacute;s sua publica&ccedil;&atilde;o na plataforma. Quando realizadas altera&ccedil;&otilde;es os usu&aacute;rios ser&atilde;o notificados. Ao utilizar o servi&ccedil;o ou fornecer informa&ccedil;&otilde;es pessoais ap&oacute;s eventuais modifica&ccedil;&otilde;es, o usu&aacute;rio e visitante demonstra sua concord&acirc;ncia com as novas normas.</p>
                    <p><strong>SE&Ccedil;&Atilde;O 10 &ndash; JURISDI&Ccedil;&Atilde;O PARA RESOLU&Ccedil;&Atilde;O DE CONFLITOS</strong></p>
                    <p>Para a solu&ccedil;&atilde;o de controv&eacute;rsias decorrentes do presente instrumento ser&aacute; aplicado integralmente o Direito brasileiro.</p>
                    <p>Os eventuais lit&iacute;gios dever&atilde;o ser apresentados no foro da comarca em que se encontra a sede da empresa.</p>
                    <p>&nbsp;</p>
                </Modal>
                
                <div className={"register-box flex-horizontal align-center"}>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            currentStep === 1 &&
                            <>
                                <div className="input-colunm">
                                    <label>Primeiro nome <Tooltip color="#ec923c" title="Seu nome não pode conter números ou caracteres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input
                                        id="name" 
                                        name="name" 
                                        type="text"
                                        placeholder="Primeiro nome" 
                                        onChange={formik.handleChange}
                                        value={formik.values.name}/>
                                        {formik.touched.name && formik.errors.name ? (<div className="form-field-edit-validate">{formik.errors.name}</div>) : null} 
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>Sobrenome<Tooltip color="#ec923c" title="Seu sobrenome não pode conter números ou caracteres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input
                                        id="sobrenome" 
                                        name="sobrenome" 
                                        type="text"
                                        placeholder="Sobrenome" 
                                        onChange={formik.handleChange}
                                        value={formik.values.sobrenome}/>
                                        {formik.touched.sobrenome && formik.errors.sobrenome ? (<div className="form-field-edit-validate">{formik.errors.sobrenome}</div>) : null} 
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>Email<Tooltip color="#ec923c" title="Seu email tem que seguir o modelo exemplo@exemplo.com ou exemplo@exemplo.com.br"><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input
                                        id="email" 
                                        name="email" 
                                        type="email"
                                        placeholder="exemplo@exemplo.com" 
                                        onChange={formik.handleChange}
                                        value={formik.values.email}/>
                                        {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                                </div>
                                <div className="input-colunm">
                                    <label>Telefone<Tooltip color="#ec923c" title="Digite apenas números."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        onChange={formik.handleChange}
                                        maxLength="15"
                                        value={phoneMask(formik.values.phone)}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null} 
                                </div>
                                <div className="input-colunm">
                                    <label>Senha<Tooltip color="#ec923c" title="Senha obrigatória. recomendamos uma senha de no mínimo 6 dígitos contendo números, letras e carateres especiais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input.Password
                                        id="password" 
                                        name="password" 
                                        type="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}/>
                                        {formik.touched.password && formik.errors.password ? (<div className="form-field-edit-validate">{formik.errors.password}</div>) : null}
                                </div>
                                <div className="input-colunm">
                                    <label>Confirmar Senha<Tooltip color="#ec923c" title="Os dois campos da sua senha devem ser iguais."><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Input.Password
                                        id="confirmPassword" 
                                        name="confirmPassword" 
                                        type="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}/>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (<div className="form-field-edit-validate">{formik.errors.confirmPassword}</div>) : null}
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Data de Nascimento</label>
                                        <ConfigProvider locale={locale}>
                                            <DatePicker
                                            id="birth" 
                                            name="birth" 
                                            format="DD/MM/YYYY"
                                            defaultPickerValue={handleDatePickerDefaultValue()}
                                            disabledDate={(currentDate)=> handleDisableDate(currentDate)}
                                            placeholder="Sex, 27 dez 2002"
                                            onChange={(date) => formik.setFieldValue('birth', date)}
                                            value={formik.values.birth}
                                            />
                                        </ConfigProvider>
                                        {formik.touched.birth && formik.errors.birth ? (<div className="form-field-edit-validate">{formik.errors.birth}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Gênero</label>
                                        <Select
                                            id="gender" 
                                            name="gender" 
                                            onChange={(value) => formik.setFieldValue('gender', value)}
                                            value={formik.values.gender}
                                        >
                                            <Option value="Masculino">Masculino</Option>
                                            <Option value="Feminino">Feminino</Option>
                                            <Option value="Outros">Outros</Option>
                                        </Select>
                                        {formik.touched.gender && formik.errors.gender ? (<div className="form-field-edit-validate">{formik.errors.gender}</div>) : null}
                                        
                                    </div>
                                </div>
                                <div className="input-colunm">
                                    <label>Eu sou<Tooltip color="#ec923c" title="Qual sua intenção ao fazer parte do Mundo Akar"><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                    <Select
                                        id="role" 
                                        name="role" 
                                        placeholder="Selecione"
                                        onChange={(value) => { formik.setFieldValue('role', value); setRole(value);}}
                                        value={formik.values.role}
                                    >
                                        <Option value="client">Cliente</Option>
                                        <Option value="professional">Profissional</Option>
                                        <Option value="company">Empresa</Option>
                                    </Select>
                                    {formik.touched.role && formik.errors.role ? (<div className="form-field-edit-validate">{formik.errors.role}</div>) : null}
                                </div>
                            </>
                        }
                        {
                            currentStep === 2 && role === 'professional' &&
                            <>
                                <div className="input-colunm">
                                    <label>Especialidades</label>
                                    <Input
                                        id="specialties" 
                                        name="specialties" 
                                        type="text"
                                        placeholder="Terapias diversas" 
                                        onChange={formik.handleChange}
                                        value={formik.values.specialties}/>
                                        {formik.touched.specialties && formik.errors.specialties ? (<div className="form-field-edit-validate">{formik.errors.specialties}</div>) : null} 
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>CPF</label>
                                    <Input
                                        id="cpf_cnpj"
                                        type="text"
                                        name="cpf_cnpj"
                                        onChange={formik.handleChange}
                                        maxLength="14"
                                        value={cpfMask(formik.values.cpf_cnpj)}
                                        className="form-input"/>
                                        {formik.touched.cpf_cnpj && formik.errors.cpf_cnpj ? (<div className="form-field-edit-validate">{formik.errors.cpf_cnpj}</div>) : null}
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>Telefone</label>
                                    <Input
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        onChange={formik.handleChange}
                                        maxLength="15"
                                        value={phoneMask(formik.values.phone)}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null} 
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>Experiência</label>
                                    <Input
                                        id="mini_curriculum"
                                        type="text"
                                        name="mini_curriculum"
                                        onChange={formik.handleChange}
                                        value={formik.values.mini_curriculum}
                                        className="form-input"/>
                                    {formik.touched.mini_curriculum && formik.errors.mini_curriculum ? (<div className="form-field-edit-validate">{formik.errors.mini_curriculum}</div>) : null}
                                
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Currículo</label>
                                        <Upload
                                            name="curriculum"
                                            listType="text"
                                            className="registerFileUploadButton"
                                            showUploadList={true}
                                            multiple={false}
                                            customRequest={curriculumRequest}
                                            beforeUpload={beforeUpload}
                                        >
                                            <Button>Fazer upload</Button>
                                        </Upload>
                                    </div>
                                    <div className="input-colunm">
                                        <label>Diplomas</label>
                                        <Upload
                                            name="diplomas"
                                            listType="text"
                                            className="registerFileUploadButton"
                                            showUploadList={true}
                                            multiple={false}
                                            customRequest={diplomasRequest}
                                            beforeUpload={beforeUploadDiploma}
                                        >
                                            <Button>Fazer upload</Button>
                                        </Upload>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>CEP</label>
                                        <Input
                                            id="cep"
                                            type="text"
                                            name="cep"
                                            onBlur={e => {handleAutoComplete(e.target.value)}}
                                            onChange={formik.handleChange}
                                            maxLength="9"
                                            value={cepMask(formik.values.cep)}
                                            className="form-input"/>
                                    {formik.touched.cep && formik.errors.cep ? (<div className="form-field-edit-validate">{formik.errors.cep}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Bairro</label>
                                        <Input
                                            id="neiborhood"
                                            type="text"
                                            name="neiborhood"
                                            onChange={formik.handleChange}
                                            value={formik.values.neiborhood}
                                            className="form-input"/>
                                        {formik.touched.neiborhood && formik.errors.neiborhood ? (<div className="form-field-edit-validate">{formik.errors.neiborhood}</div>) : null}
                                    </div>
                                </div>
                                <div className="input-colunm">
                                        <label>Rua</label>
                                        <Input
                                            id="street"
                                            type="text"
                                            name="street"
                                            onChange={formik.handleChange}
                                            value={formik.values.street}
                                            className="form-input"/>
                                            {formik.touched.street && formik.errors.street ? (<div className="form-field-edit-validate">{formik.errors.street}</div>) : null}
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Número</label>
                                        <Input
                                            id="street_number"
                                            type="text"
                                            name="street_number"
                                            onChange={formik.handleChange}
                                            value={formik.values.street_number}
                                            className="form-input"/>
                                            {formik.touched.street_number && formik.errors.street_number ? (<div className="form-field-edit-validate">{formik.errors.street_number}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Complemento</label>
                                        <Input
                                            id="complement"
                                            type="text"
                                            name="complement"
                                            onChange={formik.handleChange}
                                            value={formik.values.complement}
                                            className="form-input"/>
                                        {formik.touched.complement && formik.errors.complement ? (<div className="form-field-edit-validate">{formik.errors.complement}</div>) : null}
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Estado</label>
                                        <Select
                                            id="state"
                                            name="state"
                                            size='middle'
                                            showSearch
                                            className="form-field-select-signup"
                                            onChange={(value)=> formik.setFieldValue('state', value)}
                                            value={formik.values.state}
                                            >
                                            {states.map((uf) => (
                                            <Option key={uf} value={uf}>{uf}</Option>
                                            ))}
                                            </Select>
                                        {formik.touched.state && formik.errors.state ? (<div className="form-field-edit-validate">{formik.errors.state}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Cidade</label>
                                        <Select
                                            id="city"
                                            name="city"
                                            size='middle'
                                            showSearch
                                            className="form-field-select-signup"
                                            onChange={(value) => formik.setFieldValue('city', value)}
                                            value={formik.values.city}
                                            >
                                            {cities.map((city) => (
                                            <Option key={city} value={city}>{city}</Option>
                                            ))}
                                            </Select>
                                        {formik.touched.city && formik.errors.city ? (<div className="form-field-edit-validate">{formik.errors.city}</div>) : null}
                                    </div>
                                </div>
                            </>
                        }

                        {
                            currentStep === 2 && role === 'company' &&
                            <>
                                <div className="input-colunm">
                                    <label>Razão social</label>
                                    <Input
                                        id="company_name" 
                                        name="company_name" 
                                        type="text"
                                        placeholder="Nome da empresa" 
                                        onChange={formik.handleChange}
                                        value={formik.values.company_name}/>
                                        {formik.touched.company_name && formik.errors.company_name ? (<div className="form-field-edit-validate">{formik.errors.company_name}</div>) : null} 
                                        
                                </div>
                                <div className="input-colunm">
                                    <label>CNPJ</label>
                                    <Input
                                        id="cpf_cnpj"
                                        type="text"
                                        name="cpf_cnpj"
                                        onChange={formik.handleChange}
                                        maxLength="18"
                                        value={cnpjMask(formik.values.cpf_cnpj)}
                                        className="form-input"/>
                                        {formik.touched.cpf_cnpj && formik.errors.cpf_cnpj ? (<div className="form-field-edit-validate">{formik.errors.cpf_cnpj}</div>) : null}
                                        
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Área de atuação</label>
                                        <Input
                                            id="company_field"
                                            type="text"
                                            name="company_field"
                                            onChange={formik.handleChange}
                                            value={formik.values.company_field}
                                            className="form-input"/>
                                        {formik.touched.company_field && formik.errors.company_field ? (<div className="form-field-edit-validate">{formik.errors.company_field}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Número de funcionários</label>
                                        <Input
                                            id="employee_quantity"
                                            type="number"
                                            name="employee_quantity"
                                            onChange={formik.handleChange}
                                            value={formik.values.employee_quantity}
                                            className="form-input"/>
                                        {formik.touched.employee_quantity && formik.errors.employee_quantity ? (<div className="form-field-edit-validate">{formik.errors.employee_quantity}</div>) : null}
                                    </div>
                                </div>
                                <div className="input-colunm">
                                    <label>Contato comercial</label>
                                    <Input
                                        id="business_contact" 
                                        name="business_contact" 
                                        type="text"
                                        placeholder="Nome e telefone ou email" 
                                        onChange={formik.handleChange}
                                        value={formik.values.business_contact}/>
                                        {formik.touched.business_contact && formik.errors.business_contact ? (<div className="form-field-edit-validate">{formik.errors.business_contact}</div>) : null} 
                                </div>
                                <div className="input-colunm">
                                    <label>Contato Financeiro</label>
                                    <Input
                                        id="financial_contact" 
                                        name="financial_contact" 
                                        type="text"
                                        placeholder="Nome e telefone ou email" 
                                        onChange={formik.handleChange}
                                        value={formik.values.financial_contact}/>
                                        {formik.touched.financial_contact && formik.errors.financial_contact ? (<div className="form-field-edit-validate">{formik.errors.financial_contact}</div>) : null}
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>CEP</label>
                                        <Input
                                            id="cep"
                                            type="text"
                                            name="cep"
                                            onChange={formik.handleChange}
                                            maxLength="9"
                                            value={cepMask(formik.values.cep)}
                                            className="form-input"/>
                                    {formik.touched.cep && formik.errors.cep ? (<div className="form-field-edit-validate">{formik.errors.cep}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Bairro</label>
                                        <Input
                                            id="neiborhood"
                                            type="text"
                                            name="neiborhood"
                                            onChange={formik.handleChange}
                                            value={formik.values.neiborhood}
                                            className="form-input"/>
                                        {formik.touched.neiborhood && formik.errors.neiborhood ? (<div className="form-field-edit-validate">{formik.errors.neiborhood}</div>) : null}
                                    </div>
                                </div>
                                <div className="input-colunm">
                                        <label>Rua</label>
                                        <Input
                                            id="street"
                                            type="text"
                                            name="street"
                                            onChange={formik.handleChange}
                                            value={formik.values.street}
                                            className="form-input"/>
                                            {formik.touched.street && formik.errors.street ? (<div className="form-field-edit-validate">{formik.errors.street}</div>) : null}
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Número</label>
                                        <Input
                                            id="street_number"
                                            type="text"
                                            name="street_number"
                                            onChange={formik.handleChange}
                                            value={formik.values.street_number}
                                            className="form-input"/>
                                            {formik.touched.street_number && formik.errors.street_number ? (<div className="form-field-edit-validate">{formik.errors.street_number}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Complemento</label>
                                        <Input
                                            id="complement"
                                            type="text"
                                            name="complement"
                                            onChange={formik.handleChange}
                                            value={formik.values.complement}
                                            className="form-input"/>
                                        {formik.touched.complement && formik.errors.complement ? (<div className="form-field-edit-validate">{formik.errors.complement}</div>) : null}
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-colunm">
                                        <label>Estado</label>
                                        <Select
                                            id="state"
                                            name="state"
                                            size='middle'
                                            showSearch
                                            className="form-field-select-signup"
                                            onChange={(value)=> formik.setFieldValue('state', value)}
                                            value={formik.values.state}
                                            >
                                            {states.map((uf) => (
                                            <Option key={uf} value={uf}>{uf}</Option>
                                            ))}
                                            </Select>
                                        {formik.touched.state && formik.errors.state ? (<div className="form-field-edit-validate">{formik.errors.state}</div>) : null}
                                    </div>
                                    <div className="input-colunm">
                                        <label>Cidade</label>
                                        <Select
                                            id="city"
                                            name="city"
                                            size='middle'
                                            showSearch
                                            className="form-field-select-signup"
                                            onChange={(value) => formik.setFieldValue('city', value)}
                                            value={formik.values.city}
                                            >
                                            {cities.map((city) => (
                                            <Option key={city} value={city}>{city}</Option>
                                            ))}
                                            </Select>
                                        {formik.touched.city && formik.errors.city ? (<div className="form-field-edit-validate">{formik.errors.city}</div>) : null}
                                    </div>
                                </div>
                            </>
                        }
                        
                    </form>
                    <div className={"image-button-container"}>
                        <div className="head-register">
                            <h1>Abra sua conta</h1>
                            <Link to={'/login'}>
                                <p>Já tenho conta</p>
                            </Link>
                        </div>
                        <img alt="register" src={require("../../assets/images/register.png")} />
                        
                        {role === 'client' && 
                            <>
                                <Button
                                    loading={loading}
                                    onClick={() => {
                                        formik.submitForm()
                                    }}
                                    className="register-button"
                                >Cadastre-se</Button>
                                <p>
                                Ao clicar em Cadastre-se, você concorda com
                                nossos <span style={{cursor: 'pointer'}} onClick={() => handleTermsModalState()}>Temos de uso</span> e <span style={{cursor: 'pointer'}} onClick={() => handlePoliciesModalState()}>Política de Dados e Privacidade</span>
                                </p>
                            </>
                        }
                        
                        {role !== 'client' && currentStep === 1 &&
                            <Button
                                loading={loading}
                                onClick={() => {setCurrentStep(2)}}
                                className="register-button"
                            >Próximo</Button>
                        }                    
                        {currentStep === 2 &&
                            <>
                                <Button
                                loading={loading}
                                onClick={() => {setCurrentStep(1)}}
                                className="register-button"
                                >Voltar</Button>
                                <Button
                                    loading={loading}
                                    onClick={() => {
                                        formik.submitForm()
                                    }}
                                    className="register-button"
                                >Cadastre-se</Button>
                                <p>
                                Ao clicar em Cadastre-se, você concorda com
                                nossos <span style={{cursor: 'pointer'}} onClick={() => handleTermsModalState()}>Temos de uso</span> e <span style={{cursor: 'pointer'}} onClick={() => handlePoliciesModalState()}>Política de Dados e Privacidade</span>
                                </p>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
}

export default Register;