import React from 'react';
import Socials from '../socials';
import "./style.scss";

const Footer = ({backgroundImage}) => {
    return (
        <div id="footer" style={{
            backgroundImage: `url(${backgroundImage})`}} >
            <div className={"footer-group"}>
                <img alt="logo" className={"logo"} src={require("../../assets/images/logo-white.png")} />
                <div>
                    <p>Fixo: (85) 3879.2444 | (85) 3121-0271 ou (85) 98165-1765</p>
                    <p>mundoakar@mundoakar.com.br</p>
                    <p>Rua Felipe Nery, 1000 - Guararapes, Fortaleza - CE</p>
                </div>
            </div>
               <Socials color={'#fff'}/>
        </div>
    )
}

export default Footer;