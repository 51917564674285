import React, { useState, useEffect, useCallback } from "react";
import "./style.scss";
import Header from "../../components/header";
import Hero from "../../components/hero";
import Footer from "../../components/footer";
import SpaceCard from "../../components/space-card";
import axios from "axios";
import { Constants } from "../../constants";
import AccessControll from "../../services/access";
import Loading from "../../components/loading";

const PagesContent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState();

  const getContent = useCallback(async () => {
    setIsLoading(true);
    try {
      const slug = props.match.params.slug;
      const { data } = await axios.get(
        `${Constants.ApiUrl}/pages-content/slug/${slug}`
      );
      setDetails(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [props]);

  useEffect(() => {
    getContent();
  }, [props]);
  
  useEffect(() => {
    if (details?.name) AccessControll(`Home ${details?.name}`);
  }, [details])

  return (
    <div id="spaces">
      <Loading loading={isLoading} />
      <Header />
      <Hero center={true} image={details?.main_image_url} h1={details?.name} />

      <div className={"blog-box"}>
        <div className={"space-description"} dangerouslySetInnerHTML={{ __html: details?.description }}/>
        <div className="flex-horizontal justify-around flex-wrap">
          {details?.rooms?.map((room) => {
            return (
              <SpaceCard
                key={room.id}
                id={room.id}
                name={room.name}
                thumbnail_url={room.thumbnail_url}
                category={room.category}
              />
            );
          })}
        </div>
      </div>

      <Footer
        backgroundImage={require("../../assets/images/orange-footer.png")}
      />
    </div>
  );
};

export default PagesContent;
