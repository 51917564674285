import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, Button, Radio, Modal } from 'antd';
import Header from '../../components/header';
import { getDate, getYear, getMonth, format, getHours, getMinutes, parseISO, differenceInMinutes, isEqual } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ptBRA from 'antd/lib/locale/pt_BR';
import { Constants } from "../../constants";
import Loading from '../../components/loading';
import ProfileProf from '../profileProfessional'
import axios from "axios";
import { parse } from 'query-string';
import { socialCartAddRequest, socialCartRemoveService } from '../../redux/actions/socialCartActions';
import "./style.scss";
import AccessControll from '../../services/access';

class SocialAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            professionals: [],
            daysViability: [],
            currentService: {},
            currentProfessional: {},
            monthAvailability: [],
            availableServices: [],
            scheduleHour: '',
            scheduleMinute: '',
            selectedSchedule: {},
            advertisedSocial: {},
            dayName: '',
            year: '',
            month: '',
            day: '',
            loading: true,
            modal: {
                visibility: false,
                professional: {}
            },
        }
    }
    
    componentDidMount = () => {

        this.verifyCart()

        if (this.props.location.state){
            this.handleServiceChoice(this.props.location.state, this.props.userInfo)
            this.getServiceInfo(this.props.userInfo)
            const weekNames = ['D', 'S', 'T','Q','Q','S','S',]
            let tableHeaders = document.querySelectorAll('th')
            for (let i = 0; i < weekNames.length; i++) {
                tableHeaders[i].innerHTML = weekNames[i]
            }
        }else if(this.props.location.search){
            const query = parse(this.props.location.search);
            console.log('caiu aqui')
            this.getSocialSchedule(query.social);

        } else {
            this.setState({
                loading: false
            })
            Modal.error({
                title: 'Ops!',
                content: 'Serviço indisponível ou inexistente.',
                onOk: () => {
                    this.props.history.push('/agendar')
                }
            })
        }
        
        AccessControll('Selecionar Agendamento Social');
        
    }

    getSocialSchedule = async (id) => {
        this.setState({
            loading: true
        })
        await axios
            .get(`${Constants.ApiUrl}/socialSchedules/${id}`)
            .then((response) => {
                const socialSchedule = response.data;
                const professional = response.data.socialScheduleProfessional;
                const service = response.data.socialScheduleService;

                const { socialCartAddRequest } = this.props;

                const socialToAdd = {
                    ...service,
                    max_clients: socialSchedule.max_clients,
                    price: socialSchedule.price, 
                    status: socialSchedule.status, 
                    scheduleId: socialSchedule.id, 
                    profissional: professional,
                    type: socialSchedule.type,
                    date: {
                        dateIso: socialSchedule.date,
                        name: `${new Date(socialSchedule.date).toLocaleString('pt-BR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`,
                        hour: `${new Date(socialSchedule.date).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"})}`,
                    },
                    created_at: new Date()
                }

                this.setState({
                    loading: false
                })

                socialCartAddRequest(socialToAdd);

            }).catch((error) => {
                console.log(error.description);
            });
    }

    verifyCart = async () => {
        const {socialCart, socialCartRemoveService} = this.props;
                
        if(socialCart.length > 0 ){
            socialCart.forEach((service, index) => {
                const fifteenMinutes = 900000
                if(Math.abs(new Date() - new Date(service.created_at)) > fifteenMinutes){
                    const aux = []
                    socialCart.forEach((s, i) => {
                        if(i !== index ){
                            aux.push(s)
                        }
                    })
                    socialCartRemoveService(aux)
                }            
            });
        }
        
    }
    getServiceInfo = async () => {
        const date = new Date();
        const year = getYear(date);
        const month = getMonth(date);
        const day = getDate(date);
        const service = this.props.location.state;
        
        const config = {
            params: {
                year,
                month: month +1,
                day,
            }
        }
        //retorna os dias que tem ou nao agendamentos
        await axios
            .get(Constants.ApiUrl + '/socialSchedules/service/' + service.id, config)
            .then((response) => {
                this.setState({
                    daysViability: response.data
                })
            }).catch((error) => {
                console.log(error.description);
            });
    }

    dateCellRender = (value) => {
        if (this.state.daysViability.length > 0 ){
            const found = this.state.daysViability.find((service) =>  { 
                return isEqual(new Date(service.day).setHours(0,0,0,0), value._d.setHours(0,0,0,0)) && service.available === true}
                )
                if(found){
                return (
                    <div className="circle"></div>
                )
            }else{
                return (
                    <div></div>
                )
            }
        }
        return (
            <div></div>
        )
    }

    handleServiceChoice = async (service) => {
        const date = new Date();
        const year = getYear(date);
        const month = getMonth(date);
        const day = getDate(date);
        
        const config = {
            params: {
                year,
                month: month +1,
                day,
            }
        }

        await axios
            .get(Constants.ApiUrl + '/socialSchedules/professionals/' + service.id, config)
            .then((response) => {
                this.setState({
                    professionals: response.data,
                    currentProfessional: response.data[0],
                    currentService: service,
                    loading: false,
                });

            }).catch((error) => {
                console.log(error.description);
            });
            this.handleInitialDate(new Date());
    }

    handleInitialDate = async (date) => {
        const year = getYear(date);
        const month = getMonth(date);
        const day = getDate(date);
        const dayName = format(date, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
        const firstProfessional = this.state.professionals[0];
        const service = this.state.currentService;

        this.setState({
            year,
            month: month +1,
            day,
            dayName,
        });

        this.handleProfessionalChange(firstProfessional);

        const user = this.props.userInfo;
        const config = {
            params: {
                year,
                month: month + 1,
                day,
                service: service.id,
            }
        }

        if(!firstProfessional){
            return;
        }

        await axios
            .get(Constants.ApiUrl + '/socialSchedules/professional/' + firstProfessional.id, config)
            .then(response => {
                
                const aux = response.data.map((service) => {
                    let min = getMinutes(parseISO(service.date))
                    
                    return {
                        ...service,
                        hour: getHours(parseISO(service.date)),
                        minutes: this.parseMinuteTo00(min),
                    }
                })
                this.setState({
                    availableServices: aux
                })
            }).catch((error) => {
                console.log(error);
            });
    }
    
    handleProfessionalChange = async (professional) => {
        const day = this.state.day;
        const month = this.state.month;
        const year = this.state.year;
        const service = this.state.currentService;

        this.setState({
            currentProfessional: professional,
        });

        const user = this.props.userInfo;
        const config = {
            params: {
                year,
                month: month,
                day,
                service: service.id
            }
        }
        await axios
            .get(Constants.ApiUrl + '/socialSchedules/professional/' + professional.id, config)
            .then(response => {
                const aux = response.data.map((service) => {
                    let min = getMinutes(parseISO(service.date))
                    
                    return {
                        ...service,
                        hour: getHours(parseISO(service.date)),
                        minutes: this.parseMinuteTo00(min),
                    }
                })
                this.setState({
                    availableServices: aux
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    handleDateChange = async (date) => {
        const year = getYear(date._d);
        const month = getMonth(date._d);
        const day = getDate(date._d);
        const dayName = format(date._d, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
        const professional = this.state.currentProfessional;
        const service = this.state.currentService;
        
        this.setState({
            year,
            month: month +1,
            day,
            dayName
        });
        const user = this.props.userInfo;
        const config = {
            params: {
                year,
                month: month +1,
                day,
                service: service.id
            }
        }
        await axios
            .get(Constants.ApiUrl + '/socialSchedules/professional/' + professional.id, config)
            .then(response => {
                this.setState({
                    availableServices: response.data
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    handleMonthChange = async (date) => {
        const month = getMonth(date._d);
        this.setState({
            month: month +1
        });
            const user = this.props.userInfo;
            const service = this.props.location.state;
            const config = {
                params: {
                    year: getYear(date._d),
                    month: getMonth(date._d) +1,
                    day: getMonth(date._d) === getMonth(new Date()) && 
                    getYear(date._d) === getYear(new Date()) ? getDate(new Date()) : 1,
                }
            }
            
            await axios
            .get(Constants.ApiUrl + '/socialSchedules/service/' + service.id, config)
            .then((response) => {
                this.setState({
                    daysViability: response.data
                })
            }).catch((error) => {
                console.log(error.description);
            });
    }

    handleScheduleChange = (schedule) => {
        if(schedule.target.value){
            const hour = getHours(parseISO(schedule.target.value.date));
            const minutes = getMinutes(parseISO(schedule.target.value.date));
            const parsedMinute = this.parseMinuteTo00(minutes);
            this.setState({
                scheduleHour: hour,
                scheduleMinute: parsedMinute,
                selectedSchedule: schedule.target.value
            })
        }else {
            this.setState({
                selectedSchedule: {}
            })
        }
    }

    parseMinuteTo00(minute) {
        return (minute < 10 ? '0' : '') + minute;
    }

    handleNextButton = async () => {
        if (this.props.location.state){
            const { socialCartAddRequest, socialCart } = this.props;
            const aux = this.state;
            const service = {...this.props.location.state,
                max_clients: aux.selectedSchedule.max_clients, 
                price: aux.selectedSchedule.price, 
                status: aux.selectedSchedule.status, 
                scheduleId: aux.selectedSchedule.id, 
                profissional: aux.currentProfessional,
                type: aux.selectedSchedule.type,
                 date: {
                   dateIso: aux.selectedSchedule.date, 
                   name: aux.dayName,
                   hour: `${aux.scheduleHour}:${aux.scheduleMinute}`,
                },
                created_at: new Date()
               }
            if(!!socialCart.find((service)=>{
                return service['profissional']['id'] === aux.currentProfessional.id
            })){
                
                Modal.error({
                    content: 'Atenção! Você não pode agendar 02 atendimentos com o mesmo profissional.',
                });
            }
            else if (socialCart.length >= 2) {
                Modal.error({
                    content: 'Atenção! Você não pode ter mais que 2 agendamentos.',
                });
            }else if(socialCart.length === 1 && socialCart[0].id === service.id){
                Modal.error({
                    content: 'Atenção! Você não pode comprar mais de uma consulta na mesma especialidade',
                });
            }else if(!!socialCart.find((service)=>{
                const diff = Math.abs(differenceInMinutes(new Date(service['date']['dateIso']), new Date(aux.selectedSchedule.date)))
                return (diff < 30) 
            })){
                Modal.error({
                    content: 'Atenção! Você não pode ter 02 agendamentos no mesmo horário.',
                });
            }else {
                socialCartAddRequest(service)
            }
        }
    }

    render() {
        //console.log(this.state)

        return (
            <div id="social-attendance">
                <Loading loading={this.state.loading} />
                <Header />
                <div className={"flex-vertical head"}>
                    <div className={"flex-vertical"} >
                        <div className={"service-banner flex-horizontal"}>
                            <img src={this.state.currentService.image_url} alt={this.state.currentService.name}/>
                            <div>
                                <h1>{this.state.currentService.name}</h1>
                                <p>{this.state.currentService.description}</p>
                            </div>
                        </div>
                        <div className={"flex-vertical"}>
                            <h1>Atendimento Social</h1>
                            <h3>Escolha um profissional, data e horário abaixo</h3>
                        </div>
                    </div>
                </div>
                    <div className="professional-box">
                        
                            <Modal
								title={<span className="modal-title">Perfil</span>}
								visible={this.state.modal.visibility}
								onCancel={() => this.setState({modal: {visibility: false}})}
								closable={true}
								footer={null}
                                destroyOnClose={true}
							>
                                <ProfileProf professional={this.state.modal.professional}/>
                               
							</Modal>
                        <div className="professional-container">
                            {
                                this.state.professionals?.map((professional) => {
                                    return (
                                        <div 
                                            key={professional.id} 
                                            onClick={() => {this.handleProfessionalChange(professional)}} 
                                            className=
                                            {professional.id === this.state.currentProfessional?.id ?
                                                "professional-SA flex-horizontal align-center professional-selected"
                                                : "professional-SA flex-horizontal align-center"}
                                        >
                                            <div className="professional-img">
                                                <img alt="profile" className="back" src={require("../../assets/images/solar-blue-circle.png")} />
                                                <img alt="profile" className="user" src={professional.image_url} />
                                            </div>
                                            <div className="professional-description">
                                                <p className="title">{professional.name}</p>
                                                <span onClick={()=> {this.setState({modal: {
                                                    visibility: true,
                                                    professional: professional
                                                }})}}>
                                                    Ver perfil
                                                </span>    
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={"schedule-container"}>
                            <div className={"schedule"} >
                                <h1>Agenda</h1>
                                <Calendar
                                    fullscreen={false}
                                    onChange={(date) => {this.handleDateChange(date)}}
                                    onPanelChange={(date) => {this.handleMonthChange(date)}}
                                    dateCellRender={this.dateCellRender}
                                    locale={ptBRA}
                                    disabledDate={(date) => {
                                        const month = getMonth(new Date());
                                        const day = getDate(new Date());
                                        const year = getYear(new Date());
                                        // const weekDay = getDay(date._d);
                                        // if(weekDay === 0 || weekDay === 6){
                                        //     return true;
                                        // }
                                        return date && date.isBefore(new Date(year, month, day));
                                    }}
                                    className="calendar"
                                />
                            </div>
                            <div className={"checks"} >
                                <h2>{this.state.dayName}</h2>
                                <div className={"schedules-options flex-vertical"}>
                                    {
                                        this.state.availableServices.length > 0 ? 
                                        <Radio.Group onChange={(e) => this.handleScheduleChange(e)}>
                                        {
                                            this.state.availableServices?.map((schedule) => {       
                                                const date = schedule.date;
                                                const schedulehour = getHours(parseISO(date));
                                                const auxMinutes = getMinutes(parseISO(date));
                                                const scheduleMinutes = this.parseMinuteTo00(auxMinutes);                                       
                                                return (
                                                    <div key={schedule.id} className={"check flex-vertical"}>
                                                        <Radio className="checkbox" value={schedule} disabled={schedule.status !== "Disponível"? true : false}>                                                           
                                                            <div className="card">
                                                                <div className="group">
                                                                    <span className="hour">{schedulehour}:{scheduleMinutes}</span>
                                                                    <span className="name"> - {this.state.currentService.name}</span>
                                                                </div>
                                                                <div className="group colunm">
                                                                    <span className={`attendance-type-${schedule.type}`}>Atendimento {schedule.type}</span>
                                                                    <span className={`attendance-status-${schedule.status}`}>{schedule.status === 'Cancelado' ? 'Indisponível' : schedule.status}</span>
                                                                    <span>{schedule.max_clients - schedule.scheduled_clients}{" "}/{" "}{schedule.max_clients}</span>
                                                                </div>
                                                            </div>
                                                        </Radio>
                                                    </div>
                                                )
                                            })
                                        }
                                        </Radio.Group>
                                        :
                                        <div className="schedule-placeholder">
                                            <p>Não há consultas disponíveis para este dia...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-vertical justify-end attendance-buttons">
                                <Button onClick={this.handleNextButton} className={"btn btn-primary"} type="primary" disabled={!this.state.selectedSchedule.id} >Próximo</Button>
                                <Button onClick={() => this.props.history.push('/pagamento-social')} className={"btn btn-primary"} type="primary" disabled={!(this.props.socialCart.length > 0)} >Carrinho</Button>
                                <Button onClick={() => this.props.history.push('/agendar')} className={"btn btn-primary"} type="primary" >Voltar</Button>
                            </div>
                            
                        </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo,
        socialCart: state.socialCart.services
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        socialCartAddRequest: (service) => {
            dispatch(socialCartAddRequest(service));
        },
        socialCartRemoveService: (services) => {
            dispatch(socialCartRemoveService(services));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialAttendance);