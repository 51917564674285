import { LIKE_POST_INITIAL_STATE_SUCCESS, LIKE_POST_ADD_SUCCESS, LIKE_POST_REMOVE_SUCCESS, LIKE_POST_INITIAL_STATE_FAIL } from '../constants/likesConstants';
import { USER_LOGOUT } from '../constants/userConstants';

const initialState = {
    likedPosts: [],
    loading: false,
    error: '',
}

export const likedPostsReducer = (state = initialState, action) => {

    switch (action.type){
        case LIKE_POST_INITIAL_STATE_FAIL:
            return initialState;
        case LIKE_POST_INITIAL_STATE_SUCCESS:
            return action.payload
        case LIKE_POST_ADD_SUCCESS:

            const updatedState = [...state, ...[action.payload]];

            return updatedState

        case LIKE_POST_REMOVE_SUCCESS:

            return state.filter(item => {
                return item.id !== action.payload.item.id
            });

        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}