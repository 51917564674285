import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from 'redux-devtools-extension';

import { userLoginReducer } from '../reducers/userReducers';
import { socialCartReducer } from '../reducers/socialCartReducers';
import { generalCartReducer } from '../reducers/generalCartReducers';
import { favoritesReducer } from '../reducers/favoritesReducers';
import { likedPostsReducer } from '../reducers/likesReducers';
import { planCartReducer } from '../reducers/planCartReducers';
import { eventCartReducer } from '../reducers/eventCartReducers';
import { giftCardCartReducer } from '../reducers/giftCardCartReducers';

const reducer = combineReducers({
    userLogin: userLoginReducer,
    socialCart: socialCartReducer,
    generalCart: generalCartReducer,
    favorites: favoritesReducer,
    likedPosts: likedPostsReducer,
    planCart: planCartReducer,
    eventCart: eventCartReducer,
    giftCardCart: giftCardCartReducer,
});

const persistConfig = {
    key: "@MundoAkar",
    storage: storage,
    whitelist: ['socialCart', 'userLogin', 'generalCart', 'favorites', 'likedPosts', 'planCart', 'eventCart', 'giftCardCart'],
  };
  
const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
};
const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [thunk];

export const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export const persistedStore = persistStore(store);
