const backgroundPath1 = "M66.3 -77.4C80.7 -66.9 83.7 -41.1 82 -19.1C80.3 2.8 73.9 20.9 64.8 38C55.6 55.1 43.7 71.3 25.9 82.8C8.1 94.3 -15.6 101.2 -39 97C-62.3 92.9 -85.3 77.7 -93.3 57.1C-101.4 36.5 -94.5 10.5 -89.2 -15.1C-83.9 -40.7 -80.2 -65.9 -65.6 -76.4C-51 -86.8 -25.5 -82.5 0.2 -82.7C25.9 -83 51.9 -87.8 66.3 -77.4";
const backgroundPath2 = "M61.1 -70.5C80.6 -56.4 98.9 -38.5 104.7 -16.8C110.5 4.9 103.7 30.4 91.3 53.5C78.9 76.5 60.8 97 39.3 102.5C17.7 108.1 -7.4 98.7 -30 88C-52.5 77.3 -72.6 65.4 -80 48.3C-87.4 31.1 -82.2 8.8 -78.3 -13.9C-74.4 -36.6 -71.9 -59.7 -59.1 -74.9C-46.3 -90.2 -23.1 -97.6 -1.2 -96.2C20.8 -94.8 41.6 -84.5 61.1 -70.5";
const backgroundPath3 = "M64.6 -76.3C84.6 -60.2 102.4 -40.7 109.3 -17C116.2 6.7 112.2 34.6 96.6 50.4C81.1 66.1 53.9 69.8 30.1 75.2C6.3 80.6 -14.1 87.7 -33.2 83.9C-52.2 80.1 -69.8 65.3 -77 47.2C-84.2 29.1 -81 7.7 -75.9 -12C-70.7 -31.6 -63.6 -49.5 -50.7 -66.9C-37.7 -84.3 -18.9 -101.1 1.7 -103.2C22.3 -105.2 44.6 -92.4 64.6 -76.3";
const backgroundPath4 = "M54 -59C74.5 -47.1 98.8 -34.4 106.9 -14.9C115 4.5 106.9 30.8 92.1 50.2C77.3 69.6 55.9 82.3 33.3 89.7C10.7 97.1 -13.1 99.3 -31.7 90.9C-50.3 82.5 -63.7 63.5 -70.3 44.3C-77 25.2 -76.9 5.8 -76.6 -16.6C-76.3 -38.9 -75.7 -64.3 -62.9 -77.5C-50.1 -90.8 -25.1 -91.9 -4.2 -86.9C16.7 -82 33.5 -70.9 54 -59";
const backgroundPath5 = "M70.6 -81.5C88.5 -69.1 97.9 -44 101.9 -18.4C105.9 7.2 104.5 33.4 93.7 56.1C83 78.8 62.9 98.1 40.9 101.2C19 104.3 -4.7 91.2 -27.4 80.6C-50.1 70 -71.8 62 -79.9 46.7C-88 31.5 -82.5 9 -79.1 -14.6C-75.7 -38.2 -74.5 -62.7 -61.7 -76C-48.9 -89.2 -24.4 -91.1 1 -92.3C26.4 -93.4 52.7 -93.8 70.6 -81.5";
const backgroundPath6 = "M71.7 -80.2C90.8 -69.5 102.5 -44.8 103.2 -21.1C103.9 2.6 93.5 25.3 79.2 41.6C64.9 58 46.6 68 27.8 73.8C8.9 79.6 -10.6 81.1 -33.4 78.5C-56.1 76 -82.2 69.2 -91.3 53C-100.4 36.7 -92.5 10.9 -82.9 -9.5C-73.2 -29.9 -61.7 -44.9 -47.6 -56.4C-33.5 -67.9 -16.7 -76 4.8 -81.7C26.4 -87.4 52.7 -90.8 71.7 -80.2";
const backgroundPath7 = "M49.2 -63.5C65 -45.5 79.8 -31.1 84.4 -13.6C89 3.9 83.5 24.4 73.1 41.9C62.7 59.4 47.4 73.9 29.8 79.1C12.2 84.3 -7.8 80.2 -29.1 74.7C-50.5 69.2 -73.3 62.4 -87.7 46.3C-102.2 30.3 -108.2 5.1 -105.1 -19.6C-102.1 -44.3 -89.9 -68.5 -70.9 -86C-51.9 -103.5 -25.9 -114.2 -4.6 -108.8C16.7 -103.3 33.5 -81.6 49.2 -63.5";
const backgroundPath8 = "M61.2 -79C74.1 -62.2 75.5 -37.9 75.4 -16.6C75.3 4.8 73.6 23.1 67.5 43.9C61.4 64.6 50.9 87.8 34.3 93.4C17.8 99 -4.8 87.1 -29 78.7C-53.2 70.3 -79 65.4 -89.7 50.2C-100.4 35 -96 9.4 -91.9 -16.6C-87.8 -42.7 -84.1 -69.2 -68.8 -85.5C-53.6 -101.8 -26.8 -107.9 -1.3 -106.4C24.2 -104.9 48.4 -95.7 61.2 -79";
const backgroundPath9 = "M62.5 -70.2C79.6 -60.2 91 -39.1 95.1 -16.7C99.2 5.8 95.9 29.6 85.5 50.8C75 72 57.3 90.5 35.9 98C14.5 105.6 -10.7 102.1 -34.8 94C-58.9 85.9 -81.8 73.2 -92.1 53.9C-102.4 34.6 -99.9 8.7 -95.9 -17.3C-91.8 -43.4 -86.1 -69.6 -69.8 -79.7C-53.6 -89.9 -26.8 -84 -2 -81.6C22.7 -79.2 45.4 -80.2 62.5 -70.2";
const backgroundPath10 = "M69.6 -79.3C86.5 -68.8 94 -43.3 92.1 -21.1C90.2 1.1 78.9 20.1 68.4 40.6C57.9 61.1 48.1 83.2 32.3 89.2C16.4 95.2 -5.5 85.2 -23.7 74.5C-41.9 63.8 -56.5 52.4 -72.4 36.2C-88.4 20.1 -105.7 -0.8 -107.2 -23.5C-108.6 -46.1 -94.2 -70.6 -73.7 -80.4C-53.1 -90.3 -26.6 -85.7 -0.1 -85.5C26.4 -85.4 52.7 -89.8 69.6 -79.3";
const mainImagePaht1 = "M70.8 -79.9C88 -69.9 95.7 -44.1 97.1 -19.6C98.4 4.8 93.4 27.9 82.7 49.1C72.1 70.3 55.9 89.7 35.2 97.5C14.6 105.2 -10.5 101.5 -29.5 90.5C-48.5 79.5 -61.5 61.2 -75.3 41.5C-89 21.7 -103.6 0.4 -100.9 -18.3C-98.3 -37.1 -78.4 -53.4 -58.7 -63C-39 -72.5 -19.5 -75.3 3.6 -79.7C26.8 -84 53.6 -89.9 70.8 -79.9";
const mainImagePaht2 = "M58.2 -70.3C73.9 -56.1 84.2 -36.4 88.9 -14.9C93.6 6.6 92.7 29.8 81.7 45.6C70.7 61.3 49.5 69.7 28.1 77.6C6.7 85.5 -14.9 93 -37.7 90.4C-60.5 87.9 -84.4 75.2 -91.1 56.2C-97.8 37.1 -87.4 11.8 -80.1 -11.4C-72.7 -34.6 -68.5 -55.6 -55.7 -70.3C-42.9 -85.1 -21.4 -93.5 -0.1 -93.4C21.2 -93.3 42.4 -84.6 58.2 -70.3";
const mainImagePaht3 = "M53.4 -63C70 -49.8 84.7 -33.7 88.7 -15.1C92.6 3.5 85.7 24.5 76.2 47C66.8 69.5 54.8 93.5 36.6 99.7C18.5 105.9 -5.8 94.3 -24.2 81.2C-42.6 68.2 -55.1 53.6 -68.3 36.6C-81.5 19.6 -95.4 0.2 -92.8 -16.7C-90.2 -33.5 -71.1 -47.8 -52.9 -60.7C-34.7 -73.7 -17.4 -85.3 0.5 -86C18.4 -86.6 36.9 -76.3 53.4 -63";
const mainImagePaht4 = "M59.5 -70.6C80 -53.7 101.6 -37.7 108.1 -16.7C114.7 4.4 106.3 30.4 90.2 46.3C74.1 62.1 50.4 67.7 28.2 74.6C5.9 81.4 -14.8 89.6 -34.7 86.3C-54.6 83 -73.6 68.2 -80.5 49.7C-87.4 31.1 -82.2 8.8 -76.4 -11.6C-70.6 -32 -64.2 -50.5 -51.4 -68.7C-38.6 -87 -19.3 -105 0.1 -105.1C19.5 -105.2 39 -87.5 59.5 -70.6";
const mainImagePaht5 = "M66.2 -81.6C80.2 -67.2 81.9 -41 86.9 -15C91.9 11 100.1 36.7 90.6 51.4C81.1 66.1 53.9 69.8 30.3 74.7C6.6 79.6 -13.4 85.9 -34.5 83.2C-55.5 80.6 -77.6 69 -87.6 50.9C-97.6 32.8 -95.6 8.2 -92.1 -17.1C-88.7 -42.4 -83.9 -68.3 -68.3 -82.4C-52.7 -96.5 -26.4 -98.7 -0.1 -98.6C26.1 -98.5 52.3 -96 66.2 -81.6";
const mainImagePaht6 = "M55.3 -65C70.7 -52.9 81.6 -34.6 89.1 -12.7C96.6 9.3 100.7 34.9 89.7 49.8C78.8 64.8 52.7 69.1 28.4 78C4 86.8 -18.7 100.3 -37.1 96.1C-55.6 92 -69.8 70.2 -78 48.6C-86.2 27 -88.4 5.5 -87.8 -18.5C-87.2 -42.6 -83.8 -69.2 -68.7 -81.3C-53.6 -93.5 -26.8 -91.3 -3.4 -87.2C19.9 -83.1 39.9 -77.2 55.3 -65";
const mainImagePaht7 = "M54.9 -71.4C69.5 -53.2 78.6 -34.3 86.1 -12.1C93.7 10 99.7 35.5 89.5 50.3C79.3 65.1 53 69.2 29.5 75.2C6 81.1 -14.6 89 -32.9 84.7C-51.2 80.5 -67.2 64.3 -74.1 45.9C-81.1 27.5 -79 7 -78.4 -16.9C-77.9 -40.7 -78.8 -67.8 -66 -86.4C-53.1 -105 -26.6 -115 -3.2 -111.2C20.1 -107.3 40.3 -89.7 54.9 -71.4";
const mainImagePaht8 = "M60.6 -70.2C77.5 -58.1 89.5 -38 90.8 -17.9C92.2 2.2 82.9 22.4 71.8 41.3C60.7 60.3 47.8 78.1 30.2 85.5C12.7 93 -9.6 90.2 -33.4 84.7C-57.3 79.1 -82.8 70.8 -96.3 52.9C-109.9 35 -111.6 7.5 -101.9 -12.9C-92.2 -33.3 -71.2 -46.6 -52.4 -58.4C-33.5 -70.2 -16.7 -80.6 2.6 -83.7C21.9 -86.7 43.7 -82.4 60.6 -70.2";
const mainImagePaht9 = "M72.9 -80.4C92.3 -70.7 104.3 -45.6 103.6 -22.3C102.9 1 89.5 22.5 77 44.6C64.4 66.6 52.5 89.2 33.4 99.6C14.3 109.9 -12.2 108.1 -35.3 98.8C-58.4 89.6 -78.1 73.1 -86.6 52.7C-95.1 32.4 -92.4 8.3 -84.4 -11.1C-76.5 -30.5 -63.4 -45.2 -48.4 -55.7C-33.5 -66.2 -16.7 -72.6 5 -78.6C26.8 -84.6 53.6 -90.2 72.9 -80.46";
const mainImagePaht10 = "M47.6 -63.2C61.7 -44.9 73.2 -29.9 79.4 -11.5C85.6 6.9 86.5 28.7 76.9 43.4C67.2 58.1 46.9 65.7 27.2 71.5C7.4 77.4 -11.9 81.4 -31.5 78C-51.1 74.5 -71 63.5 -83.2 46.2C-95.4 28.9 -99.9 5.2 -98 -19.4C-96.2 -43.9 -87.9 -69.5 -70.5 -87.2C-53.1 -105 -26.6 -115 -4.9 -109.1C16.7 -103.3 33.5 -81.6 47.6 -63.2";

export const backgroundPaths = [
    backgroundPath1,
    backgroundPath2,
    backgroundPath3,
    backgroundPath4,
    backgroundPath5,
    backgroundPath6,
    backgroundPath7,
    backgroundPath8,
    backgroundPath9,
    backgroundPath10
];
export const mainImagePaths = [
    mainImagePaht1,
    mainImagePaht2,
    mainImagePaht3,
    mainImagePaht4,
    mainImagePaht5,
    mainImagePaht6,
    mainImagePaht7,
    mainImagePaht8,
    mainImagePaht9,
    mainImagePaht10
];
