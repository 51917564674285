import React from 'react';
import { Link } from "react-router-dom";
import Loading from '../../loading2';
import "./style.scss";

const EventShowcaseCard = ({id, name, thumbnail_url, type, date, loading}) => {

    const parsedDate = new Date(date).toLocaleString('pt-BR', {hour12: "true", weekday: "short", day: "2-digit", month: "short"}).toUpperCase();
    const parsedHour = new Date(date).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});

    return (
        <div className="event-card">
            {
                loading ?
                    <Loading loading />
                :
                <Link
                    to={{
                        pathname: "/eventos/" + id,
                    }}
                    className="container"
                    >
                    <img alt={name} src={thumbnail_url} />
                    <div>
                        <p className="date">{parsedDate} - {parsedHour}</p>
                        <p className="name">{name}</p>
                        <p className="event-type">{type}</p>
                    </div>
                </Link>
            }
        </div>
    )
}

export default EventShowcaseCard;