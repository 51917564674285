import React, { Component } from 'react';
import { Modal, Tabs, Checkbox, Button  } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import { logout } from '../../redux/actions/userActions';
import axios from 'axios';
import { Constants } from "../../constants";
import Header from '../../components/header';
import Loading from '../../components/loading';
import EditUser from './EditUser';
import cpfMask from '../../utils/cpfMask';
import formatValue from '../../utils/formatValue';
import phoneMask from '../../utils/phoneMask';
import OrderCard from '../../components/order-card';
import "./style.scss";
import AccessControll from '../../services/access';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            checked: false,
            orders: [],
            parsedBirth: '',
            visible: false,
            messages: [],
            waitList: [],
            loading: true,
        }
    }
    
    componentDidMount = () => {
        this.getUserData();
        this.getStatusOrder();
        this.getUserMessages();
        this.getUserWaitList();
        AccessControll('Perfil')
    }

    handleLogout = () => {
        const { logout } = this.props;

        logout();
        this.props.history.push('/');
    }

    getStatusOrder = async () => {
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios
            .get(Constants.ApiUrl + '/orders/client/' + user.id, config)
            .then((response) => {
                const aux = [];
                response.data.forEach(element => {
                    aux.push(
                        {
                            ...element,
                            date: new Date(element.createdAt).toLocaleString('pt-BR', {dateStyle: 'short'}),
                            formatedValue: formatValue(element.value)
                        }
                    )
                });
                this.setState({orders: aux})
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getUserData = async () => {
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios
            .get(Constants.ApiUrl + '/users/' + user.id, config)
            .then((response) => {
                this.setState({
                    userData: {...response.data,
                         cpf: response.data.cpf ? cpfMask(response.data.cpf) : "",
                         phone: response.data.phone ? phoneMask(response.data.phone): "",
                        },
                    checked: response.data.notify 
                });
            })
            .catch((error) => {
                console.log(error)
            });
        
        const date = new Date(this.state.userData.birth)

        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()

        const parsedData = `${day}/${month}/${year}`

        this.setState({
            parsedBirth: parsedData
        });
    }

    getUserMessages = async () => {
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios
            .get(Constants.ApiUrl + '/messages', config)
            .then((response) => {
                this.setState({
                    messages: response.data,
                    loading: false
                });
                

            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    loading: false
                });
            });
    }

    getUserWaitList = async () => {
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios
            .get(`${Constants.ApiUrl}/waitlistclients/${user.id}`, config)
            .then((response) => {

                const aux = response.data.map((item) => {
                    return {
                        id: item.id,
                        professional: item.waited_professional.name,
                        service: item.waited_service.name,
                        date: item.createdAt,
                    }
                })

                this.setState({
                    waitList: aux,
                    loading: false
                });
                
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    loading: false
                });
            });
    }

    handleDeleteWaitItem = async (id) => {

        const serviceIndex = this.state.waitList.findIndex(service => service.id === id)
        const aux = this.state.waitList
        aux.splice(serviceIndex,1)
        this.setState({
            waitList: aux
        })
        
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios.delete(Constants.ApiUrl + '/waitlistclients/' + id, config);
            
    }

    handleDeleteMsg = async (id) => {
        const serviceIndex = this.state.messages.findIndex(service => service.id === id)
        const aux = this.state.messages
        aux.splice(serviceIndex,1)
        this.setState({
            messages: aux
        })
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios.delete(Constants.ApiUrl + '/messages/' + id, config)
            
    }

    handleCheckboxChange= async (e) => {
        this.setState({
            checked: e.target.checked
        })
        const user = this.props.userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }
        await axios
            .get(Constants.ApiUrl + '/users/notify', config)
            .then((response) => {
                
            })
            .catch((error) => {
                console.log(error)
            });
            
    }

    generateSlug = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }
    
    render() {
        const { TabPane } = Tabs;
        
        return (
            <div id="profile">
                <Loading loading={this.state.loading} />
                <Header/>
                
                <div className="user-box flex-vertical">
                    <div className="user flex-horizontal align-center justify-between">
                        <div className="flex-horizontal">
                            <div className="user-img">
                                <img alt="profile" className="back" src={require("../../assets/images/solar-blue-circle.png")} />
                                <img alt={this.state.userData.name} className="user-profile-img" src={this.state.userData.image_url} />
                            </div>
                            <div className="user-details">
                                <p className="title">{this.state.userData.name}</p>
                                <div className="flex-horizontal alingn-baseline">
                                    <h2>Créditos</h2>
                                    <h1>{this.state.userData.credits ? this.state.userData.credits : 0}</h1>
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.props.userInfo.role !== 'client' &&
                                <Button onClick={() => {this.props.history.push('/relatorios', this.state.userData)}} className={"btn btn-primary"} type="primary" >Relatórios</Button>
                            }
                            {this.props.userInfo.role === 'professional' &&
                                <Button onClick={() => {this.props.history.push('/administracao', this.state.userData)}} className={"btn btn-primary"} type="primary" >Administração</Button>
                            }
                            <Button onClick={() => {this.props.history.push('/agenda', this.state.userData)}} className={"btn btn-primary"} type="primary" >Minha agenda</Button>
                        </div>
                    </div>          
                    <div className="user-data">
                        
                        <div className="personal-data">
                        <button className="edit-button" onClick={()=> this.setState({visible: true})}>
                            Editar Perfil
                        </button>
                        <Modal
                            title={<span className="modal-title">Edite seus dados</span>}
                            visible={this.state.visible}
                            onCancel={() => this.setState({visible: false})}
                            closable={true}
                            footer={null}
                            destroyOnClose={true}
                        >
                            
                            <EditUser userData={{...this.state.userData, token: this.props.userInfo.token}} state={this}/>
                        </Modal>
                            <div className="tab-container">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Dados Pessoais" key="1">
                                    <div className="tab-content">
                                        <div className="data flex-horizontal">
                                            <label>Nome:</label>
                                            <p>{this.state.userData.name || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Sobrenome:</label>
                                            <p>{this.state.userData.last_name || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Email:</label>
                                            <p>{this.state.userData.email || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Telefone:</label>
                                            <p>{phoneMask(this.state.userData.phone) || "Dado não cadastrado"}</p>
                                        </div>
                                        
                                        <div className="data flex-horizontal">
                                            <label>Nascimento:</label>
                                            <p>{this.state.parsedBirth || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Gênero:</label>
                                        <p>{this.state.userData.gender || "Dado não cadastrado"}</p>
                                        </div>
                                        {
                                            this.props.userInfo.role === 'professional' &&
                                            (
                                            <> 
                                                <div className="data flex-horizontal">
                                                    <label>Especialidade/Função:</label>
                                                    <p>{this.state.userData.occupation || "Dado não cadastrado"}</p>
                                                </div>
                                                <div className="data flex-horizontal">
                                                    <label>CRP/CRM/TRT:</label>
                                                    <p>{this.state.userData.crp_crm_trt || "Dado não cadastrado"}</p>
                                                </div>                                   
                                            </>
                                            )
                                        }
                                    </div>
                                    {
                                            this.props.userInfo.role === 'professional' &&
                                            (
                                            <> 
                                                <div className="curriculo">
                                                    <label>Descrição:</label>
                                                    <p>{this.state.userData.obs || "Dado não cadastrado"}</p>
                                                </div>
                                                <div className="curriculo">
                                                    <label>Mini Currículo:</label>
                                                    <p>{this.state.userData.mini_curriculum || "Dado não cadastrado"}</p>
                                                </div>
                                            </>
                                            )
                                        }
                                    
                                </TabPane>
                                <TabPane tab="Dados Nota Fiscal" key="2">
                                    <div className="tab-content">
                                        <div className="data flex-horizontal">
                                            <label>CPF/CNPJ:</label>
                                            <p>{cpfMask(this.state.userData.cpf_cnpj) || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Cep:</label>
                                            <p>{this.state.userData.zip_code || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Cidade:</label>
                                            <p>{this.state.userData.city || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Estado:</label>
                                            <p>{this.state.userData.state || "Dado não cadastrado"}</p>
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Logradouro:</label>
                                            {
                                                this.state.userData.street ?
                                                (<p>{this.state.userData.street + ' ' + this.state.userData.street_number + ' '+ this.state.userData.neighborhood}</p>)
                                                :
                                                <p>Dado não cadastrado</p>
                                            }
                                        </div>
                                        <div className="data flex-horizontal">
                                            <label>Complemento:</label>
                                            <p>{this.state.userData.complement || "Dado não cadastrado"}</p>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Configurações" key="3">
                                    <div className="tab-content">
                                        
                                        <div className="margin-top flex-horizontal config">
                                        <Link to={{ 
                                            pathname:"/forgot-password",
                                            state: 'Mudar Senha'
                                        }}>
                                            <label>Mudar Senha</label>
                                        </Link>
                                        </div>
                                        <div className="margin-top flex-horizontal">
                                            <Checkbox 
                                                checked={this.state.checked}
                                                onChange={this.handleCheckboxChange}
                                            >Receber notificações</Checkbox>
                                        </div>
                                        <div className="margin-top flex-horizontal">
                                            <label>Deletar Conta</label>
                                        </div>
                                        <div className="margin-top flex-horizontal config">
                                            <label onClick={() => {this.handleLogout()}}>Sair/Logout</label>
                                        </div>
                                    </div>

                                </TabPane>
                                
                            </Tabs>
                                
                            </div>
                        </div>
                        
                        <div className="messages">
                            <h1>Mensagens</h1>
                            <div className="container">
                                <div className="messages-container">
                                    {   this.state?.messages.length > 0 ?
                                        this.state?.messages.map((message) => {
                                            const date = new Date(message.createdAt).toLocaleString('pt-BR', {dateStyle: "short", timeStyle: 'short' });
                                            return(
                                                <div key={message.id} className="message flex-vertical">
                                                    <div className="message-header">
                                                        <h3>{message.type}</h3>
                                                        <div className="message-group">
                                                            <span>{date}</span>
                                                            <FiTrash2 color='#c53030' className="delete-button" onClick={() => this.handleDeleteMsg(message.id)} />
                                                        </div>
                                                    </div>
                                                    <p>{message.body}</p>
                                                </div>
                                            )
                                        }) :
                                        <span>Não há mensagens disponíveis</span>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <div className="order-status">
                            <h1>Meus pedidos</h1>
                            <div  className="order-status-cards">
                                { this.state.orders.length > 0 ?
                                    this.state.orders.map(order => {
                                        return (
                                            <OrderCard key={order.id} order={order} />
                                        )
                                    }) :
                                    <span>Não há pedidos disponíveis</span>
                                }
                            </div>
                        </div>

                        {/* <div className="messages">
                            <h1>Lista de espera</h1>
                            <div className="container">
                                <div className="messages-container">
                                    {   this.state?.waitList.length > 0 ?
                                        this.state?.waitList.map((item) => {
                                            const date = new Date(item.date).toLocaleString('pt-BR', {dateStyle: "short", timeStyle: 'short' });
                                            return(
                                                <div key={item.id} className="message flex-vertical">
                                                    <div className="message-header">
                                                        <h3>Entrou no dia</h3>
                                                        <div className="message-group">
                                                            <span>{date}</span>
                                                            <FiTrash2 color='#c53030' className="delete-button" onClick={() => this.handleDeleteWaitItem(item.id)} />
                                                        </div>
                                                    </div>
                                                    <p>Profissional: {item.professional} - Terapia: {item.service}</p>
                                                </div>
                                            )
                                        }) :
                                        <span>Você não está em nenhuma lista de espera</span>
                                    }
                                </div>
                            </div>
                        </div>                                     */}
                    </div>
                    {/* <section className="favorites-section">
                        <div className="favorite">
                            <h1>Profissionais favoritos</h1>
                                <div className="container">
                                <div className="favorites-container">
                                    {   
                                        this.props.favorites.favoriteProfessionals.length > 0 ?
                                        this.props.favorites.favoriteProfessionals.map((prof) => {
                                            return(
                                                <div key={prof.id} className="favorite-box">
                                                    <div>
                                                        <img alt="profile" className="user" src={prof.image_url} />
                                                    </div>
                                                    <div  className="favorite-details">
                                                        <p className="title">{prof.name}</p>
                                                        <p className="description">{prof.obs}</p>
                                                        <Link className="more" to={{
                                                            pathname: `/profissionais/${this.generateSlug(prof.name)}`,
                                                            state: {...prof}
                                                        }}>
                                                            <span>saiba mais</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <span>Você não favoritou nenhum profissional ainda</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="favorite">
                            <h1>Posts favoritos</h1>
                                <div className="container">
                                <div className="favorites-container">
                                    {   this.props.favorites.favoritePosts.length > 0 ?
                                        this.props.favorites.favoritePosts.map((post) => {
                                            return(
                                                <Link
                                                key={post.id}
                                                to={{
                                                    pathname: "/blog-post/" + post.id,
                                                }}>
                                                    <div className="favorite-box">
                                                        <div>
                                                            <img alt="profile" className="user" src={post.thumbnail_url} />
                                                        </div>
                                                        <div  className="favorite-details">
                                                            <p className="title">{post.title}</p>
                                                            <p className="description">{post.abstract}</p>
                                                        </div>
                                                    </div>
                                                </Link>                                                
                                            )
                                        }) :
                                        <span>Você não favoritou nenhum post ainda</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="favorite">
                            <h1>Terapias favoritas</h1>
                                <div className="container">
                                <div className="favorites-container">
                                    {   this.props.favorites.favoriteServices.length > 0 ?
                                        this.props.favorites.favoriteServices.map((service) => {
                                            
                                            return(
                                                <Link className="favorite-box" key={service.id} to={{
                                                pathname: "/atendimento-geral",
                                                state: {...service}
                                                }}>
                                                    <div>
                                                    <img alt="profile" className="user" src={service.image_url} />
                                                </div>
                                                <div className="favorite-details">
                                                    <p className="title">{service.name}</p>
                                                    <Link className="more" to={{
                                                        pathname: `/terapias/${this.generateSlug(service.name)}`,
                                                        state: {...service}
                                                    }}>
                                                        <span>saiba mais</span>
                                                    </Link>
                                                </div>
                                                </Link>
                                            )
                                        }) :
                                        <span>Você não favoritou nenhuma terapia ainda</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            
                </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo,
        favorites: state.favorites
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);