import axios from 'axios';
import { Constants } from '../../constants';
import { LIKE_POST_ADD_SUCCESS, LIKE_POST_ADD_FAIL, LIKE_POST_INITIAL_STATE_FAIL, LIKE_POST_INITIAL_STATE_REQUEST, LIKE_POST_INITIAL_STATE_SUCCESS, LIKE_POST_REMOVE_SUCCESS, LIKE_POST_REQUEST, LIKE_POST_REMOVE_FAIL, LIKE_POST_REMOVE_REQUEST } from "../constants/likesConstants";

export const getUserLikedPosts = (userInfo) => async (dispatch) => {

    if(userInfo){
        dispatch({
            type: LIKE_POST_INITIAL_STATE_REQUEST,
        })
        await axios.get(`${Constants.ApiUrl}/likes`, {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            },
        }).then(response => { 
            dispatch({
                type: LIKE_POST_INITIAL_STATE_SUCCESS,
                payload: response.data
            })
        }).catch(err => {
            console.log(err);
            dispatch({
                type: LIKE_POST_INITIAL_STATE_FAIL,
                payload: {}
            })
        });
    }else{
        dispatch({
            type: LIKE_POST_INITIAL_STATE_FAIL,
            payload: {}
        })
    }
}

export const likesAddRequest = (item, token) => async (dispatch) => {
    
    dispatch({
        type: LIKE_POST_REQUEST,
    })

    await axios.patch(`${Constants.ApiUrl}/likes/post`, null, {
        params: {
            itemId: item.id
        },
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(
        dispatch({
            type: LIKE_POST_ADD_SUCCESS,
            payload: item
        })
    ).catch(err => {
        dispatch({
            type: LIKE_POST_ADD_FAIL,
        })
        console.log(err);
    });
}

export const likesRemoveRequest = (item, token) => async (dispatch) => {

    dispatch({
        type: LIKE_POST_REMOVE_REQUEST
    })

    await axios.patch(`${Constants.ApiUrl}/likes/post`, null, {
        params: {
            itemId: item.id
        },
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(
        dispatch({
            type: LIKE_POST_REMOVE_SUCCESS,
            payload: { item }
        })
    ).catch(err => {
        dispatch({
            type: LIKE_POST_REMOVE_FAIL,
        })
        console.log(err);
    });
}