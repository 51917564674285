import { EVENT_CART_ADD_SUCCESS, EVENT_CART_REMOVE_SUCCESS  } from "../constants/eventCartConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
    events: [],
    ticketType: {}
}

export const eventCartReducer = (state = initialState, action) => {

    switch (action.type){
        case EVENT_CART_ADD_SUCCESS:
            return { ...state, events: [ action.payload.event ], ticketType: action.payload.ticketType }
        case EVENT_CART_REMOVE_SUCCESS:
            return initialState
        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}