import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from 'react-redux';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import Service from '../../components/service';
import Loading2 from '../../components/loading2';
import { Constants } from "../../constants";
import Slide from 'react-slick';
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AccessControll from '../../services/access';

class GeneralSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            date: "",
            loading: true,
            ads: [],
        }
    }
    componentDidMount = () => {
        this.getServices();
        this.getDayName();
        this.getAds();
        AccessControll('Agenda Geral');
    }
    
    getAds = async () => {
        await axios
            .get(`${Constants.ApiUrl}/advertisements`,
            { 
                params: 
                { 
                    range: '[0,10]', 
                    filter: {
                        "active": "true",
                        "local": "general-attendance"
                    }
                }
            })
            .then((response) => {
                this.setState({
                    ads: response.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getDayName = () => {
        const date = new Date()
        .toLocaleString('pt-BR', {dateStyle: "full"})
        .slice(0, -8)
        this.setState({
            date
        })
    }

    getServices = async () => {
        await axios
            .get(`${Constants.ApiUrl}/user-services/available`)
            .then((response) => {
                this.setState({
                    services: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {

        function SampleNextArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
                />
            );
        }
          
        function SamplePrevArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
                />
            );
        }

        const sliderSetings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 1,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            cssEase: "linear",
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <div id="general-schedule">
                <Header />
                <Hero
                    center={true}
                    image={require("../../assets/images/main-image-withback.png")}
                    h1={"Faça já o seu agendamento!"}
                />

                <div className={"blog-box"}>
                    
                    <h3 >Terapias disponíveis</h3>
                    <span className="week-day">{this.state.date}</span>
                    <div id="services" className="service-box" ref={ref => {
                            this.$ref = ref;
                        }}>
                        {this.state.loading ? (
                                <Loading2 loading={this.state.loading} />
                            ):
                            (
                                <div className={"attendance"}>
                                    {  this.state.services.length > 0 ?
                                        this.state.services.map((service) => {
                                            return (
                                                <Link key={service.id} to={{
                                                    pathname: "/atendimento-geral",
                                                    state: {...service}
                                                }}>
                                                    <Service isLoading={this.state.loading} title={service.name} img={service.image_url} id={service.id}/>
                                                </Link>
                                            )
                                        }) : (
                                            <span className="attendance-text">Sem atendimentos disponíveis</span>
                                        )
                                    }
                                </div>
                    
                            )}
                    </div>
                    
                </div>

                <div className={"advertisement-desktop"} >
                    <Slide {...sliderSetings}>
                        {
                            this.state.ads.map((ad) => {
                                return (
                                    <div key={ad.id}>
                                        <a target="_blank" rel="noopener noreferrer" href={ad.link}>
                                            <img src={ad.desktop_image_url} alt={ad.name} />
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </Slide>
                </div>

                <div className={"advertisement-mobile"} >
                    <Slide {...sliderSetings}>
                        {
                            this.state.ads.map((ad) => {
                                return (
                                    <div key={ad.id}>
                                        <a href={ad.link}>
                                            <img src={ad.mobile_image_url} alt={ad.name} />
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </Slide>
                </div>

                <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo
    }
}

export default connect(mapStateToProps)(GeneralSchedule);