/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { Tabs, Button } from "antd";
import { connect } from "react-redux";
import {
  getYear,
  getDate,
  getMonth,
  isAfter,
  parseISO,
  isBefore,
  isEqual,
} from "date-fns";
import axios from "axios";
import { Constants } from "../../constants";
import Header from '../../components/header';
import CardSocialSchedule from '../../components/cardSocialSchedule';
import CardGeneralSchedule from '../../components/cardGeneralSchedule';
import Loading from '../../components/loading';
import "./style.scss";
import EventResumeCard from "../../components/events/event-resume-card";
import AccessControll from "../../services/access";

class Schedule extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      events: [],

      schedulesNormal: [],
      schedulesSocial: [],
      schedulesSocialPC: [],
      
      todayGeneralSchedulesPAC: [],
      allGeneralSchedulesPAC: [],
      futureGeneralSchedulesPAC: [],

      userData: this.props.location.state || {},
      visible: false, 
      loading: true,
    };
  }

  componentDidMount = () => {
    const user = this.props.userInfo;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    
    this.getSchedulerData(user, config);
    if(this.props.location.state === undefined){
      this.getUserData(user.id, config);
    }
    this.getEvents(user.id, config);
    AccessControll('Agenda Pessoal');
  };

  getEvents = async (userId, config) => {
    await axios
    .get(`${Constants.ApiUrl}/events/client/${userId}`, config)
    .then((response) => {
      this.setState({
        events: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  getUserData = async (userId, config) => {
    await axios
      .get(Constants.ApiUrl + "/users/" + userId, config)
      .then((response) => {
        this.setState({
          userData: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSchedulerData = async (user, config) => {
    const date = new Date();
    const year = getYear(date);
    const month = getMonth(date);
    const day = getDate(date);
    const aux = {
      ...config,
      params: {
        year,
        month: month +1,
        day,
      }
    }
    const schedulesNormal = await axios.get(Constants.ApiUrl + `/schedules/${user.role=== 'professional' ? 'professional' : 'client'}/${user.id}`, aux)
    const schedulesSocial = await axios.get(Constants.ApiUrl + `/socialSchedules/${user.role === 'professional' ? 'professional' : 'client'}/id/${user.id}`, config)
    const response = await Promise.all([schedulesNormal, schedulesSocial])
    const scheduler = []
    const schedulerPC = []
    if (user.role === 'professional') {
      const schedulesSocialProfessionalAsClient = await axios.get(Constants.ApiUrl + `/socialSchedules/client/id/${user.id}`, config)
      const schedulesGeneralProfessionalAsClient = await axios.get(Constants.ApiUrl + `/schedules/client/${user.id}`, config).then((response) => {return response.data}).catch((err) => {console.log(err)});

      const todayGeneralSchedulesProfessionalAsClient = schedulesGeneralProfessionalAsClient.filter((schedule) => {
        return isEqual( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )
      })

      const futureGeneralSchedulesProfessionalAsClient = schedulesGeneralProfessionalAsClient.filter((schedule) => {
        return isAfter( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )
      })

      this.setState({
        todayGeneralSchedulesPAC: todayGeneralSchedulesProfessionalAsClient,
        allGeneralSchedulesPAC: schedulesGeneralProfessionalAsClient,
        futureGeneralSchedulesPAC: futureGeneralSchedulesProfessionalAsClient,
      })

      let todayPC = []
      let futurePC = []
      let pastPC = []
      let allPC = []

      schedulesSocialProfessionalAsClient.data.forEach((schedule)=>{
        let date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
        let hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
        if(isEqual( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )){
          if(!isBefore( parseISO(schedule.date), new Date())){
            todayPC.push({
              ...schedule,
              date:  date + ' às ' + hour,
              isoDate: schedule.date ,
              cancelabel: true
            })
          }else {
            todayPC.push({
              ...schedule,
              date:  date + ' às ' + hour,
              isoDate: schedule.date ,
              cancelabel: false
            })
          }
        }
        if (isAfter( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )) {
          futurePC.push({
            ...schedule,
            date:  date + ' às ' + hour,
            isoDate: schedule.date ,
            cancelabel: true
          })
        }
        if(isBefore( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0))){
          pastPC.push({
          ...schedule,
          date:  date + ' às ' + hour,
          isoDate: schedule.date ,
          cancelabel: false
        })
        }
        allPC = [...todayPC, ...futurePC,...pastPC]
      })
      schedulerPC.push({
        todayPC,
        futurePC,
        allPC
      })      
      for (let i = 0; i < response.length; i++) {
        let today = []
        let future = []
        let all = []
        response[i].data.forEach((schedule)=>{
          let date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
          let hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
          if(isEqual( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )){
            today.push({
              ...schedule,
              isoDate: schedule.date ,
              date:  date + ' às ' + hour
            })
          }
          if (isAfter( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )) {
            future.push({
              ...schedule,
              isoDate: schedule.date ,
              date:  date + ' às ' + hour
            })
          }
          
          all.push({
            ...schedule,
            isoDate: schedule.date ,
            date:  date + ' às ' + hour
          })
          
          
        })
        scheduler.push({
          today,
          future,
          all
        })      
      }
    }else {
      for (let i = 0; i < response.length; i++) {
        let today = []
        let future = []
        let past = []
        let all = []
        response[i].data.forEach((schedule)=>{
          let date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
          let hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
          if(isEqual( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )){
            if(!isBefore( parseISO(schedule.date), new Date())){
              today.push({
                ...schedule,
                date:  date + ' às ' + hour,
                isoDate: schedule.date ,
                cancelabel: true
              })
            }else {
              today.push({
                ...schedule,
                date:  date + ' às ' + hour,
                isoDate: schedule.date ,
                cancelabel: false
              })
            }
          }
          if (isAfter( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0) )) {
            future.push({
              ...schedule,
              date:  date + ' às ' + hour,
              isoDate: schedule.date ,
              cancelabel: true
            })
          }
          if(isBefore( parseISO(schedule.date).setHours(0,0,0,0), new Date().setHours(0,0,0,0))){
            past.push({
            ...schedule,
            date:  date + ' às ' + hour,
            isoDate: schedule.date ,
            cancelabel: false
          })
          }
          
        })
        all = [...today, ...future,...past]
        scheduler.push({
          today,
          future,
          all
        })      
      }
    }
    this.setState({
      schedulesNormal: scheduler[0],
      schedulesSocial: scheduler[1],
      schedulesSocialPC: schedulerPC[0],
      loading: false,
    })
  }

  callback(key) {
    //console.log(key);
  }

  render() {
    const { TabPane } = Tabs;
    return (
      <div id="schedule">
        <Loading loading={this.state.loading}/>
        <Header/>
        <div className="user-box flex-vertical">
          <div className="user flex-horizontal align-center justify-between">
            <div className="flex-horizontal">
              <div className="user-img">
                <img
                  alt="profile"
                  className="back"
                  src={require("../../assets/images/solar-blue-circle.png")}
                />
                <img
                  alt={this.state.userData.name}
                  className="user-profile-img"
                  src={this.state.userData.image_url}
                />
              </div>
              <div className="user-details">
                <p className="title">{this.state.userData.name}</p>
                <div className="flex-horizontal alingn-baseline">
                  <h2>Créditos</h2>
                  <h1>
                    {this.state.userData.credits
                      ? this.state.userData.credits
                      : 0}
                  </h1>
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                this.props.history.push("/minha-conta");
              }}
              className={"btn btn-primary"}
              type="primary"
            >
              Voltar
            </Button>
          </div>
        </div>
        
        <main className="data-container">
              
          {this.props.userInfo.role === "professional" ? (
            <>
              <div className="scheduler-container">
                <h1>Clientes Agendados</h1>
                <div className="scheduler-content">
                  <div className="scheduler-normal">
                    <h1>Agendamentos Mundo Akar</h1>
                    <Tabs defaultActiveKey="1" onChange={this.callback}>
                      <TabPane tab="Hoje" key="1">
                        {this.state.schedulesNormal.today && this.state.schedulesNormal.today.length > 0 ? this.state.schedulesNormal.today.map((schedule)=>{
                          return (
                            <CardGeneralSchedule key={schedule.id} service={schedule} type="professional"/>
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                      </TabPane>
                      <TabPane tab="Futuros" key="2">
                      {this.state.schedulesNormal.future && this.state.schedulesNormal.future.length > 0 ? this.state.schedulesNormal.future.map((schedule)=>{
                          return (
                            <CardGeneralSchedule key={schedule.id} service={schedule} type="professional"/>
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                      </TabPane>
                      <TabPane tab="Todos" key="3">
                      {this.state.schedulesNormal.all && this.state.schedulesNormal.all.length > 0 ? this.state.schedulesNormal.all.map((schedule)=>{
                          return (
                            <CardGeneralSchedule key={schedule.id} service={schedule} type="professional"/>
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                    </TabPane>
                    </Tabs>
                  </div>
                  <div className="scheduler-social">
                    <h1>Agendamentos Mundo Akar Social</h1>
                    <Tabs defaultActiveKey="1" onChange={this.callback}>
                      <TabPane tab="Hoje" key="1">
                        <div className="tab-container">
                          {this.state.schedulesSocial.today && this.state.schedulesSocial.today.length> 0 ? this.state.schedulesSocial.today.map((service)=>{
                            return (
                              <CardSocialSchedule key={service.id} service={service} type="professional"/>
                            )
                          }) : (
                            <div className="scheduler-placeholder">
                                <p>Não há agendamentos disponíveis</p>
                            </div>
                          )}
                        </div>
                          
                      </TabPane>
                      <TabPane tab="Futuros" key="2">
                      <div className="tab-container">
                      {this.state.schedulesSocial.future && this.state.schedulesSocial.future.length> 0 ? this.state.schedulesSocial.future.map((service)=>{
                          return (
                            <CardSocialSchedule key={service.id} service={service} type="professional"/>
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                        </div>
                      </TabPane>
                      <TabPane tab="Todos" key="3">
                      <div className="tab-container">
                        {this.state.schedulesSocial.all && this.state.schedulesSocial.all.length> 0 ? this.state.schedulesSocial.all.map((service)=>{
                            return (
                              <CardSocialSchedule key={service.id} service={service} type="professional"/>
                            )
                          }) : (
                            <div className="scheduler-placeholder">
                                <p>Não há agendamentos disponíveis</p>
                            </div>
                          )}
                        </div>
                    </TabPane>
                    </Tabs>
                  </div>
                </div>
                
                <h1>Meus Agendamentos</h1>
                <div className="scheduler-content">
                  <div className="scheduler-normal">
                    <h1>Agendamentos Mundo Akar</h1>
                    <Tabs defaultActiveKey="1" onChange={this.callback}>
                      <TabPane tab="Hoje" key="1">
                          {this.state.todayGeneralSchedulesPAC.length > 0 ? this.state.todayGeneralSchedulesPAC.map((schedule) => {
                            const date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
                            const hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
                            return (
                              <div key={schedule.id} className="card">
                                  <div className="group">
                                      <span className="service-name">{schedule.service_id.name}</span>
                                      <span className="profissional-name">{schedule.professional_id.name}</span>
                                      <span className="data">{`${date} às ${hour}`}</span>
                                  </div>
                                  <div className="group">
                                    {schedule.type && (<span className={`attendance-type-${schedule.type}`}>Atendimento {schedule.type}</span>) }
                                  
                                    { schedule.zoom_url ? 
                                      (
                                        <div className="zoom">
                                          <a href={schedule.zoom_url}>Entre na reunião</a>
                                        </div>
                                      ):
                                      <span></span>
                                    }
                                  </div>
                              </div>
                            )
                          }) : (
                            <div className="scheduler-placeholder">
                                <p>Não há agendamentos disponíveis</p>
                            </div>
                          )}
                      </TabPane>
                      <TabPane tab="Futuros" key="2">
                          {this.state.futureGeneralSchedulesPAC.length > 0 ? this.state.futureGeneralSchedulesPAC.map((schedule) => {
                            const date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
                            const hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
                            return (
                              <div key={schedule.id} className="card">
                                  <div className="group">
                                      <span className="service-name">{schedule.service_id.name}</span>
                                      <span className="profissional-name">{schedule.professional_id.name}</span>
                                      <span className="data">{`${date} às ${hour}`}</span>
                                  </div>
                                  <div className="group">
                                    {schedule.type && (<span className={`attendance-type-${schedule.type}`}>Atendimento {schedule.type}</span>) }
                                  
                                    { schedule.zoom_url ? 
                                      (
                                        <div className="zoom">
                                          <a href={schedule.zoom_url}>Entre na reunião</a>
                                        </div>
                                      ):
                                      <span></span>
                                    }
                                  </div>
                              </div>
                            )
                          }) : (
                            <div className="scheduler-placeholder">
                                <p>Não há agendamentos disponíveis</p>
                            </div>
                          )}
                      </TabPane>
                      <TabPane tab="Todos" key="3">
                          {this.state.allGeneralSchedulesPAC.length > 0 ? this.state.allGeneralSchedulesPAC.map((schedule) => {
                            const date = parseISO(schedule.date).toLocaleString('pt-BR', {dateStyle: "full"})
                            const hour = parseISO(schedule.date).toLocaleString('pt-BR', {timeStyle: "short"})
                            return (
                              <div key={schedule.id} className="card">
                                  <div className="group">
                                      <span className="service-name">{schedule.service_id.name}</span>
                                      <span className="profissional-name">{schedule.professional_id.name}</span>
                                      <span className="data">{`${date} às ${hour}`}</span>
                                  </div>
                                  <div className="group">
                                    {schedule.type && (<span className={`attendance-type-${schedule.type}`}>Atendimento {schedule.type}</span>) }
                                  
                                    { schedule.zoom_url ? 
                                      (
                                        <div className="zoom">
                                          <a href={schedule.zoom_url}>Entre na reunião</a>
                                        </div>
                                      ):
                                      <span></span>
                                    }
                                  </div>
                              </div>
                            )
                          }) : (
                            <div className="scheduler-placeholder">
                                <p>Não há agendamentos disponíveis</p>
                            </div>
                          )}
                    </TabPane>
                    </Tabs>
                  </div>
                  <div className="scheduler-social">
                    <h1>Agendamentos Mundo Akar Social</h1>
                    <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="Hoje" key="1">
                      <div className="tab-container">
                        {this.state.schedulesSocialPC.todayPC && this.state.schedulesSocialPC.todayPC.length> 0 ? this.state.schedulesSocialPC.todayPC.map((service)=>{
                          return (
                            <CardSocialSchedule key={service.id} service={service} />
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                        </div>
                      </TabPane>
                      <TabPane tab="Futuros" key="2">
                        <div className="tab-container">
                          {this.state.schedulesSocialPC.futurePC && this.state.schedulesSocialPC.futurePC.length> 0 ? this.state.schedulesSocialPC.futurePC.map((service)=>{
                              return (
                                <CardSocialSchedule key={service.id} service={service} />
                              )
                            }) : (
                              <div className="scheduler-placeholder">
                                  <p>Não há agendamentos disponíveis</p>
                              </div>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tab="Todos" key="3">
                      <div className="tab-container">
                      {this.state.schedulesSocialPC.allPC && this.state.schedulesSocialPC.allPC.length> 0 ? this.state.schedulesSocialPC.allPC.map((service)=>{
                          return (
                            <CardSocialSchedule key={service.id} service={service} />
                          )
                        }) : (
                          <div className="scheduler-placeholder">
                              <p>Não há agendamentos disponíveis</p>
                          </div>
                        )}
                        </div>
                    </TabPane>
                    </Tabs>
                  </div>
                </div>
                
              </div>
              <div className="scheduler-container">
                <h1>Eventos</h1>
                <div className="events-container">
                  {this.state.events && this.state.events.length> 0 ? this.state.events.map((event)=>{
                    return (
                      <EventResumeCard
                        key={event.id}
                        date={event.date}
                        id={event.id}
                        name={event.name}
                        thumbnail_url={event.thumbnail_url}
                        type={event.type}
                        conclusion={event.conclusion}
                        zoom_url={event.zoom_url}
                        address={event.address}
                      />
                    )
                  }) : (
                    <div className="scheduler-placeholder">
                        <p>Você ainda não se increveu em nenhum evento</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
           <>
            <div className="scheduler-container">
              <h1>Agendamentos</h1>
              <div className="scheduler-content">
              
                <div className="scheduler-normal">
                  <h1>Agendamentos Mundo Akar</h1>
                  <Tabs defaultActiveKey="1" onChange={this.callback} tabBarStyle={{width: "100%"}}>
                    <TabPane tab="Hoje" key="1">
                    {this.state.schedulesNormal.today && this.state.schedulesNormal.today.length > 0 ? this.state.schedulesNormal.today.map((service)=>{
                        return (
                          <CardGeneralSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                    </TabPane>
                    <TabPane tab="Futuros" key="2">
                      {this.state.schedulesNormal.future && this.state.schedulesNormal.future.length > 0 ? this.state.schedulesNormal.future.map((service)=>{
                        return (
                          <CardGeneralSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                    </TabPane>
                    <TabPane tab="Todos" key="3">
                    {this.state.schedulesNormal.all && this.state.schedulesNormal.all.length > 0 ? this.state.schedulesNormal.all.map((service)=>{
                        return (
                          <CardGeneralSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                  </TabPane>
                  </Tabs>
                </div>
                <div className="scheduler-social">
                  <h1>Agendamentos Mundo Akar Social</h1>
                  <Tabs defaultActiveKey="1" onChange={this.callback} tabBarStyle={{width: "100%"}}>
                  
                    <TabPane tab="Hoje" key="1">
                    <div className="tab-container">
                    {this.state.schedulesSocial.today && this.state.schedulesSocial.today.length> 0 ? this.state.schedulesSocial.today.map((service)=>{
                        return (
                          <CardSocialSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                      </div>
                    </TabPane>
                    <TabPane tab="Futuros" key="2">
                    <div className="tab-container">
                      {this.state.schedulesSocial.future && this.state.schedulesSocial.future.length> 0 ? this.state.schedulesSocial.future.map((service)=>{
                        return (
                          <CardSocialSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                      </div>
                    </TabPane>
                    <TabPane tab="Todos" key="3">
                    <div className="tab-container">
                    {this.state.schedulesSocial.all && this.state.schedulesSocial.all.length> 0 ? this.state.schedulesSocial.all.map((service)=>{
                        return (
                          <CardSocialSchedule key={service.id} service={service} />
                        )
                      }) : (
                        <div className="scheduler-placeholder">
                            <p>Não há agendamentos disponíveis</p>
                        </div>
                      )}
                      </div>
                  </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="scheduler-container">
              <h1>Eventos</h1>
              <div className="events-container">
                {this.state.events && this.state.events.length> 0 ? this.state.events.map((event)=>{
                  return (
                    <EventResumeCard
                      key={event.id}
                      date={event.date}
                      id={event.id}
                      name={event.name}
                      thumbnail_url={event.thumbnail_url}
                      type={event.type}
                      conclusion={event.conclusion}
                      zoom_url={event.zoom_url}
                      address={event.address}
                    />
                  )
                }) : (
                  <div className="scheduler-placeholder">
                      <p>Você ainda não se increveu em nenhum evento</p>
                  </div>
                )}
              </div>
            </div>
           </>
          )}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userLogin.userInfo,
  };
};

export default connect(mapStateToProps)(Schedule);
