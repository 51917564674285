import React, { Component } from 'react';
import { addHours } from 'date-fns';
import { Modal } from "antd";
import "./style.scss";

export default class CardSocialSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addToGoogleCalendarURI: '',
          };
    }
    
    handleSchedulerCancel = () => {
        this.setState({visible: true})
    }

    generateAddToGoogleCalendarURI = (schedule, type) => {
        const convertToDateTIME = (date) => {
            return (new Date(date)).toISOString().replace(/-|:|\.\d\d\d/g,"")
        }

        let details;

        if(type === 'professional'){
            details = encodeURIComponent(`${schedule.socialScheduleService.name} com as(os) cliente(s) ${schedule.max_clients > 1 && `${schedule.socialScheduleClient[0].name} ${schedule.socialScheduleClient[0].last_name}`}`);
        }else{
            details = encodeURIComponent(`${schedule.socialScheduleService.name} com a(o) profissional ${schedule.socialScheduleProfessional.name} ${schedule.socialScheduleProfessional.last_name}`);
        }

        const initialDate = convertToDateTIME(schedule.isoDate);

        const auxEndDate = addHours(new Date(schedule.isoDate), 1).toISOString();
        const endDate = convertToDateTIME(auxEndDate);

        const text =  encodeURIComponent(`Consulta no Mundo Akar Social`);
        const location = encodeURIComponent(`Mundo Akar ${schedule.type}`);
        const dates = encodeURIComponent(`${initialDate}/${endDate}`);

        const ctz = 'America%2FSao_Paulo';

        const URI = `https://calendar.google.com/calendar/r/eventedit?text=${text}&details=${details}&location=${location}&dates=${dates}&ctz=${ctz}`

        this.setState({
            addToGoogleCalendarURI: URI
        })
    }

    componentDidMount = () => {

        const { service, type } = this.props;
        this.generateAddToGoogleCalendarURI(service, type);

    }

    
    render() {
        const { service, type } = this.props;
        if(type === 'professional'){
            return (
                <>
                    <Modal
                        title={<span className="modal-title">Cancelar consulta</span>}
                        visible={this.state.visible}
                        onCancel={() => this.setState({visible: false})}
                        onOk={() => this.setState({visible: false})}
                        closable={true}
                        destroyOnClose={true}
                    >
                        <div>
                        <p>Para cancelamento da sua consulta entre em contato com nossa equipe pelo número 85 99199-6231.</p>
                        <p>Não é possível o reembolso do valor e o reagendamento fica sujeito à comunicação formal com mínimo de 48h úteis de antecedência.</p>  
                        </div>               
                    </Modal>
                    <div key={service.id} className="social-card" style={{border: service.socialScheduleClient.length > 0 ? 'none': '1px dashed red'}} >
                        <div className="group">
                            <span className="service-name">{service.socialScheduleService.name}</span>
                            <span className="profissional-name">Quant. de clientes {service.socialScheduleClient.length}</span>
                            <span className="data">{service.date}</span>
                        </div>
                    <div className="group">
                        <div className="addToCalendar">
                            <a target="_blank" rel="noopener noreferrer" href={this.state.addToGoogleCalendarURI}>Adicione ao Calendar</a>
                        </div>
                        <span className={`attendance-type-${service.type}`}>Atendimento {service.type}</span>
                        { service.zoom_url &&
                            <div className="zoom">
                                <a href={service.zoom_url} target="_blank" rel="noopener noreferrer">Entre na reunião</a>
                            </div>
                        }
                    </div>
                    <div></div>
                    {service.cancelabel && 
                        <div className="cancel">
                            <button onClick={this.handleSchedulerCancel} className="cancel-button">Cancelar</button>
                        </div>
                    }
                    </div>
                </>
            )
        }else{
            return (
                <>
                <Modal
                    title={<span className="modal-title">Cancelar consulta</span>}
                    visible={this.state.visible}
                    onCancel={() => this.setState({visible: false})}
                    onOk={() => this.setState({visible: false})}
                    closable={true}
                    destroyOnClose={true}
                >
                     <div>
                      <p>Para cancelamento da sua consulta entre em contato com nossa equipe pelo número 85 99199-6231.</p>
                      <p>Não é possível o reembolso do valor e o reagendamento fica sujeito à comunicação formal com mínimo de 48h úteis de antecedência.</p>  
                     </div>               
                                </Modal>
                <div key={service.id} className="social-card" style={{border: service.socialScheduleClient.length > 0 ? 'none': '1px dashed red'}} >
                    <div className="group">
                        <span className="service-name">{service.socialScheduleService.name}</span>
                        <span className="profissional-name">Quant. de clientes {service.socialScheduleClient.length}</span>
                        <span className="data">{service.date}</span>
                    </div>
                    <div className="group">
                        <div className="addToCalendar">
                            <a target="_blank" rel="noopener noreferrer" href={this.state.addToGoogleCalendarURI}>Adicione ao Calendar</a>
                        </div>
                        <span className={`attendance-type-${service.type}`}>Atendimento {service.type}</span>
                        { service.zoom_url &&
                            <div className="zoom">
                                <a href={service.zoom_url} target="_blank" rel="noopener noreferrer">Entre na reunião</a>
                            </div>
                        }
                    </div>
                    <div></div>
                    {service.cancelabel && 
                        <div className="cancel">
                            <button onClick={this.handleSchedulerCancel} className="cancel-button">Cancelar</button>
                        </div>
                    }
                </div>
                </>
            )
        }
    }
}

