import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Input, Button, Modal } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Socials from '../../components/socials';
import Footer from '../../components/footer';
import "./style.scss";
import { Constants } from '../../constants';
import AccessControll from '../../services/access';
import Loading from '../../components/loading';
import phoneMask from '../../utils/phoneMask';

const Contact = () => {

    const { TextArea } = Input;

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema:  Yup.object().shape({
            name: Yup.string().required('campo obrigatório'),
            email: Yup.string().required('campo obrigatório').email('Digite um email válido'),
            phone: Yup.string().nullable(),
            message: Yup.string().required('campo obrigatório'),
    }),
        onSubmit: async (values) => {
            setLoading(true)
            const { name, email, phone, message, type = 'Dúvida em geral'} = values	

            await axios.post(`${Constants.ApiUrl}/messages/contact`, {
                    name, email, phone, message, type
                })
                .then((response)=>{
                    setLoading(false)
                    const aux = Modal.success({
                        title: 'Sucesso!',
                        content: <p className="teste">{response.data.message}</p>,
                        onOk: ()=> {
                            aux.destroy();
                        },
                        maskClosable: true,
                    });
                })
                .catch((err)=> {
                    Modal.error({
                        title: "Ops!!",
                        content: err.response.data.message,
                        onOk: ()=> {
                            setLoading(false)
                        }
                    });
                })
        },

    })

    useEffect(() => {
        AccessControll('Contato');
    }, [])

    return (
        <div id="contato">
            <Loading loading={loading} />
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/main-image-withback.png")}
                h1={"Deseja entrar em contato conosco?"}
            />

            <div className={"contato-box"}>
                <h3>Contato</h3>
                <p>
                    Caso tenha dúvidas ou precise de ajuda entre em contato 
                    conosco pelo email mundoakar@mundoakar.com.br ou pelo 
                    telefones abaixo:
                </p>
                <p>
                Fixo: (85) 3879.2444 | (85) 3121-0271 ou (85) 98165-1765.
                </p>
            </div>

            <section className="contact-form">
                <form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className={"contact-form-box flex-horizontal align-center"}>
                        <Col span={12}>
                            <div>
                                <label>Nome*</label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (<div className="form-field-edit-validate">{formik.errors.name}</div>) : null}
                            <div>
                                <label>Tel</label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={phoneMask(formik.values.phone)}
                                />
                            </div>
                            {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}
                            <div>
                                <label>Email*</label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                            {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                            <div>
                                <TextArea
                                    rows={5}
                                    placeholder={"Escreva aqui sua mensagem"}
                                    id="message"
                                    name="message"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                />
                            </div>
                            {formik.touched.message && formik.errors.message ? (<div className="form-field-edit-validate">{formik.errors.message}</div>) : null}
                            <div>
                            <Button type="submit" onClick={()=>formik.submitForm()} className={"btn btn-primary"}>Enviar</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
                <Socials />
            </section>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );
}

export default Contact;