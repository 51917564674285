import React from 'react';
import "./style.scss";

const EventResumeCard = ({id, name, thumbnail_url, type, date, loading, conclusion, zoom_url, address}) => {

    const generateAddToGoogleCalendarURI = () => {

        const convertToDateTIME = (date) => {
            return (new Date(date)).toISOString().replace(/-|:|\.\d\d\d/g,"")
        }

        const parsedDate = new Date(date).toLocaleString('pt-BR', {hour12: "true", weekday: "short", day: "2-digit", month: "short"}).toUpperCase();
        const parsedInitialHour = new Date(date).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});
        const parsedConclusionHour = new Date(conclusion).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});

        const details = encodeURIComponent(`Participação no evento ${name}, ${type} no dia ${parsedDate}, às ${parsedInitialHour} até ${parsedConclusionHour}`);

        const initialDate = convertToDateTIME(date);
        const endDate = convertToDateTIME(conclusion ? conclusion : date);

        const text =  encodeURIComponent(`Evento no Mundo Akar`);
        const location = encodeURIComponent(type === 'Presencial' ? address : type);
        const dates = encodeURIComponent(`${initialDate}/${endDate}`);

        const ctz = 'America%2FSao_Paulo';

        const URI = `https://calendar.google.com/calendar/r/eventedit?text=${text}&details=${details}&location=${location}&dates=${dates}&ctz=${ctz}`

        return URI;
    }

    const parsedDate = new Date(date).toLocaleString('pt-BR', {hour12: "true", weekday: "short", day: "2-digit", month: "short"}).toUpperCase();
    const parsedInitialHour = new Date(date).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});
    const parsedConclusionHour = new Date(conclusion).toLocaleString('pt-BR', {timeStyle: "short", hourCycle: "h24"});

    return (
        <div className="event-card">
            <div className="container" >
                <img alt={name} src={thumbnail_url} />
                <div>
                    <p className="date">{parsedDate} - {parsedInitialHour} até {parsedConclusionHour}</p>
                    <p className="name">{name}</p>
                    { zoom_url &&
                        <div className="zoom">
                            <a href={zoom_url} target="_blank" rel="noopener noreferrer">Link do evento</a>
                        </div>
                    }
                    <p className={`attendance-type-${type}`}>{type}</p>
                    <div className="addToCalendar">
                        <a target="_blank" rel="noopener noreferrer" href={generateAddToGoogleCalendarURI()}>Adicione ao Calendar</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventResumeCard;