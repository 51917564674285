import React, { Component } from 'react';
import "./style.scss";

export default class Hero extends Component {
    render() {
        return (
            <div id="hero" style={(this.props.center ? {
                backgroundImage: "url(" + this.props.image + ")",
            } :
                {
                    backgroundImage: "url(" + this.props.image + ")",
                    
                })}
            >
                <div className="title-container">
                    <h1 style={{color: `${this.props.color ? this.props.color : '#fff' }`}}>{this.props.h1 ? this.props.h1 : ""}</h1>
                    <h2>{this.props.h2 ? this.props.h2 : ""}</h2>
                </div>
                
            </div>
        )
    }
}
