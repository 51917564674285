import history from '../../services/history'
import { SCART_ADD_SUCCESS, SCART_REMOVE_SUCCESS } from "../constants/socialCartConstants"

export const socialCartAddRequest = (service) => async (dispatch) => {
        dispatch({
            type: SCART_ADD_SUCCESS,
            payload: { service }
        })
        history.push('/pagamento-social')

}

export const socialCartRemoveService = (services) => async (dispatch) => {
    dispatch({
        type: SCART_REMOVE_SUCCESS,
        payload: { services }
    })
}