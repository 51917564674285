import { FAVORITE_ADD_SUCCESS, FAVORITE_REMOVE_SUCCESS, FAVORITE_INITIAL_STATE_SUCCESS, FAVORITE_INITIAL_STATE_FAIL } from '../constants/favoritesConstants';
import { USER_LOGOUT } from '../constants/userConstants';

const initialState = {
    favorites: {},
    loading: false,
    error: '',
}

export const favoritesReducer = (state = initialState, action) => {

    switch (action.type){
        case FAVORITE_INITIAL_STATE_FAIL:
            return initialState;
        case FAVORITE_INITIAL_STATE_SUCCESS:
            return action.payload
        case FAVORITE_ADD_SUCCESS:
            if(action.payload.type === 'service' && state.favoriteServices){
                state.favoriteServices.push(action.payload.item);
            }
            if(action.payload.type === 'professional' && state.favoriteProfessionals){
                state.favoriteProfessionals.push(action.payload.item);
            }
            if(action.payload.type === 'post' && state.favoritePosts){
                state.favoritePosts.push(action.payload.item);
            }
            return state
        case FAVORITE_REMOVE_SUCCESS:

        let favoriteServiceAuxState;
        let favoriteProfAuxState;
        let favoritePostAuxState;

            if(action.payload.type === 'service' && state.favoriteServices){
                favoriteServiceAuxState = state.favoriteServices.filter(item => {
                    return item.id !== action.payload.item.id
                });
            }
            if(action.payload.type === 'professional' && state.favoriteProfessionals){
                favoriteProfAuxState = state.favoriteProfessionals.filter(item => {
                    return item.id !== action.payload.item.id
                });
            }
            if(action.payload.type === 'post' && state.favoritePosts){
                favoritePostAuxState = state.favoritePosts.filter(item => {
                    return item.id !== action.payload.item.id
                });
            }

            const auxState = {
                favoritePosts: favoritePostAuxState ? favoritePostAuxState : state.favoritePosts, 
                favoriteProfessionals: favoriteProfAuxState ? favoriteProfAuxState : state.favoriteProfessionals, 
                favoriteServices :favoriteServiceAuxState ? favoriteServiceAuxState : state.favoriteServices
            }
            return auxState;

        case USER_LOGOUT:
            return initialState
        default:
            return state;
    }
}