/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "./style.scss";

export default class Button extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { loading, loadingSize, children, className,...rest} = this.props;
        const antIcon = <LoadingOutlined className='loading-svg-button' spin style={{fontSize: loadingSize}}/>;
        return (
            <button className={"button-container " + className} {...rest}>
                <div className="loaging-container-button" style={{display: loading ? '' : 'none' }}>
                    <Spin indicator={antIcon} />
                </div>
                {children}
            </button>
            
        )
    }
}

