import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, notification } from "antd";
import "./style.scss";
import { Constants } from "../../constants";

const OrderCard = ({ order }) => {
  const userInfo = useSelector((state) => state.userLogin.userInfo);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: "Erro.",
      description: message,
    });
  };

  const handleDownloadInvoice = async (order_id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios
      .get(`${Constants.ApiUrl}/invoices/download/${order_id}`, config)
      .then((response) => {
        const url = response.data;
        console.log(url);
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.log(err);
        openNotificationWithIcon(
          "error",
          "Essa nota ainda não foi emitida ou está aguardando emissão."
        );
      });
  };

  return (
    <div className="order-card">
      <div className="order-group">
        <div className="order-item">
          <label>Descrição:</label>
          <span>{order.description}</span>
        </div>
        <div className="order-item">
          <label>Data:</label>
          <span>{order.date}</span>
        </div>
        <div className="order-item">
          <label>Link pagamento:</label>
          <span>
            {order.payment_link ? (
              <a
                href={order.payment_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.payment_link}
              </a>
            ) : order.used_coupon ? (
              `Cupom ${order.order_coupon.code} utilizado!`
            ) : order.used_plan ? (
              `Plano ${order.order_plan.name} utilizado!`
            ) : null}
          </span>
        </div>
        <div className="order-item">
          <label>Valor:</label>
          <span>{order.formatedValue}</span>
        </div>
      </div>
      <div>
        <div className="order-item">
          <label>Status:</label>
          <span className={order.status + " status"}>{order.status}</span>
        </div>
        <div className="order-item">
          <Button
            onClick={() => {
              handleDownloadInvoice(order.id);
            }}
            className={"btn btn-primary"}
            type="primary"
          >
            Baixar Nota Fiscal
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
