import React from 'react';
import { Link } from "react-router-dom";
import "./style.scss";

const SpaceCard = ({id, thumbnail_url, name, category}) => {

    return (
        <div className="space-card">
            <Link
                to={{
                    pathname: "/espacos/" + id,
                }}>
                <div className="box">
                    <img alt={name} src={thumbnail_url} />
                    <p className="name">{name}</p>
                    <p className="category">{category}</p>
                </div>
            </Link>
        </div>
    )
}

export default SpaceCard;