import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Constants } from "../../constants";
import "./style.scss";
import axios from "axios";
import AccessControll from '../../services/access';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: false,
            loading: false,
        }
    }

    componentDidMount = () => {
        AccessControll('Esqueci a senha');
    }

    handleResetPassword = async () => {
        this.setState({loading: true, error: false})

        const schemaValidate = Yup.object().shape({
            email: Yup.string().email().required(),
        })
        const email = {email: this.state.email}
        if(await schemaValidate.isValid(email)){
            await axios.post(Constants.ApiUrl + '/auth/forgotpass', {
                email: this.state.email
            }).then((response) => {
                Modal.success({title: 'Sucesso!', content:'Foi enviado um link para seu email para mudança da senha.'})
                this.setState({loading: false})
            })         
            .catch((error) => {
                console.log(error)
                Modal.error({
                    title: 'Ops!!',
                    content: error.response.data.message
                })
                this.setState({loading: false})
    
            })
            
        }else{
            this.setState({error: true, loading: false})
        }
        
    }

    render() {
        return (
            <div id="forgot-password">
                <div className={"flex-horizontal head align-center"}>
                    <Link to={'/'}>
                    <img alt="logo" src={require("../../assets/images/logo-with-name.png")} />
                    </Link>
                    <h1>{this.props.location.state}</h1>
                </div>
                <div className={"forgot-password-container"}>
                    <div>
                        <img alt="forgot-password" src={require("../../assets/images/login.png")} />
                    </div>
                    <div>
                    {this.state.error && <span className="forgot-error">Digite um email válido.</span>}
                        <div className="forgot-input-group">
                            <label>Email</label>
                            <Input 
                                onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                }}
                                onFocus={() => {this.setState({error: false})}}
                                placeholder={"exemplo@email.com"}
                                type="email"
                                name="email"
                                id="email"
                            />
                        </div>

                            <Button 
                                loading={this.state.loading}
                                loadingSize={18}
                                onClick={() => {
                                  this.handleResetPassword();
                                }}
                                className={"forgot-button"}
                            >{this.props.location.state}</Button>
                            <br/>                       
                    </div>
                </div>
            </div>
        )
    }
}

export default (ForgotPassword);
