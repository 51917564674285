import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsBookmarkFill, BsBookmarkPlus } from 'react-icons/bs';
import { favoritesAddRequest, favoritesRemoveRequest } from '../../redux/actions/favoritesActions';
import "./style.scss";

const FavoriteButton = ({type, item}) => {

    const [isFavorite, setIsFavorite] = useState(false);
    const { favoriteProfessionals, favoriteServices, favoritePosts } = useSelector((state) => state.favorites);
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const dispatch = useDispatch();

    useEffect(() => {
        if(!userInfo){
            return
        }

        switch(type){
            case 'professional':
                const checkProfessionalFav = favoriteProfessionals.filter((fav) => {
                    return fav.id === item.id
                })
                if(checkProfessionalFav[0]){
                    setIsFavorite(true)
                }
                break;
            case 'service':
                const checkServiceFav = favoriteServices.filter((fav) => {
                    return fav.id === item.id
                })
                if(checkServiceFav[0]){
                    setIsFavorite(true)
                }
                break;
            default:
                const checkPostFav = favoritePosts.filter((fav) => {
                    return fav.id === item.id
                })
                if(checkPostFav[0]){
                    setIsFavorite(true)
                }
        }
    }, [favoriteProfessionals, favoriteServices, favoritePosts, item, type, userInfo])

    const handleRemoveFav = () => {
        dispatch(favoritesRemoveRequest(item, type, userInfo.token));
        setIsFavorite(false);
    }
    const handleAddFav = () => {
        dispatch(favoritesAddRequest(item, type, userInfo.token));
        setIsFavorite(true)
    }

    return (
        <div className="favorite-button">
            {   userInfo ?
                isFavorite ?
                <BsBookmarkFill onClick={() => {handleRemoveFav()}}/>
                :
                <BsBookmarkPlus onClick={() => {handleAddFav()}}/>
                :
                <>
                </>
            }
        </div>
    )
}

export default FavoriteButton;