import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Input, Select, Menu, Checkbox, Tooltip } from 'antd';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiDelete } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import HeaderI from '../../components/HeaderI';
import { Constants } from "../../constants";
import axios from "axios";
import Loading from '../../components/loading';
import Button from '../../components/button';
import formatValue from '../../utils/formatValue';
import cpfMask from '../../utils/cpfMask';
import phoneMask from '../../utils/phoneMask';
import cepMask from '../../utils/cepMask';
import cardNumberMask from '../../utils/cardNumberMask';
import { generalCartRemoveRequest  } from '../../redux/actions/generalCartActions';
import AccessControll from '../../services/access';

import "./style.scss";

const { Option } = Select;

const GeneralPayment = (props) => {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [cupom, setCupom] = useState('');
    const [giftedUserName, setGiftedUserName] = useState();
    const [giftedUserEmail, setGiftedUserEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [cupomError, setCupomError] = useState(false);
    const [cupomErrorMessage, setCupomErrorMessage] = useState();
    const [cupomLoading, setCupomLoading] = useState(false);
    const [discontValue, setDiscontValue] = useState(0);
    const [formatedSubtotal, setFormatedSubtotal] = useState('');
    const [formatedTotal, setFormatedTotal] = useState('');
    const [cartTotal, setCartTotal] = useState();
    const [userPlans, setUserPlans] = useState([]);
    const [planId, setPlanId] = useState();
    const cepRegex = RegExp(/^\d{5}-\d{3}$/);
    const nomeRegex = RegExp(/^[a-zA-Z\u00C0-\u00FF ]+$/);
    const numbersRegex = RegExp(/^[0-9]+$/);
    const history = useHistory();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userLogin.userInfo);
    const userData = useSelector(state => state.userLogin.userData);
    const [schedules, setSchedules] = useState([]);
    const state = useSelector(state => state);
    const generalCart = useSelector(state => state.generalCart.schedules);
    const { SubMenu } = Menu;
    const formRef = useRef(null);

    const getUserPlans = async (user, schedules) => {

        setLoading(true)

        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await axios.get(`${Constants.ApiUrl}/plans/user/${user.id}`, config)
                .then((response) => {

                    const userPlansAux = response.data;
                    let userPlans = [];

                    userPlansAux.forEach((userPlan) => {
                        const planServices = userPlan.plan.services.map((service) => {
                            return service.id;
                        })

                        const planProfessionals = userPlan.plan.professionals.map((professional) => {
                            return professional.id;
                        })

                        const available = userPlan.plan.quantity - userPlan.quantity_used;

                        schedules.forEach((schedule) => {
                            if(planServices.includes(schedule.id) && planProfessionals.includes(schedule.profissional.id) && available > 0){
                                userPlans.push(userPlan);
                            }
                        })
                    })
                    setUserPlans(userPlans);
                    setLoading(false);
                })
                .catch((err) => {console.log(err); setLoading(false);});

    }

    useEffect(() => {
        
       if(!!generalCart.length){
            let subtotal = 0
            const aux = generalCart.map((schedule) => {
                subtotal += Number(schedule.price)
                return {
                    ...schedule,
                    formatedPrice: formatValue(schedule.price)
                }
            })
            setSchedules(aux)
            setFormatedSubtotal(formatValue(subtotal))
            setFormatedTotal(formatValue(subtotal - subtotal*(discontValue/100)))
            setCartTotal(subtotal - subtotal*(discontValue/100))
            getUserPlans(userInfo, aux);
        }
        else {
            Modal.warning({
                title: "Ops!!",
                content: "Parece que seu carrinho está vazio, volte e agende um atendimento.",
                onOk: () => {
                    history.push('/agenda-geral')
                }
            })
        }

    }, [generalCart, discontValue, history, userInfo])
    
    useEffect(()=> {
        async function getStates(){
          const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
          const ufInitials = response.data.map(uf => uf.sigla);
          setStates(ufInitials.sort())
        }
        getStates()
        AccessControll('Pagamento Geral');
    },[])
      
    const formik = useFormik({
        initialValues: {
            pnome: userData.name || '',
            sobrenome: userData.last_name || '',
            email: userData.email || '',
            phone: userData.phone || '',
            cpf: userData.cpf_cnpj || '',
            cep: userData.zip_code || '',
            av: userData.street || '',
            numero: userData.street_number || '',
            comp:userData.complement || '',
            bairro: userData.neighborhood || '',
            cidade: userData.city || '',
            estado: userData.state || '',
            cardHolderName: '',
            cardNumber: '',
            cardCVV: '',
            cardExpiryMonth: '',
            cardExpiryYear: '',
            creditCardHolderName: '',
            creditCardHolderEmail: '',
            creditCardHolderPhone: '',
            creditCardHolderCPFCNPJ: '',
            creditCardHolderCEP: '',
            creditCardHolderAddressNumber: '',
        },
        validationSchema:  Yup.object().shape({
            pnome: Yup.string().required('campo obrigatório').matches(nomeRegex, 'Números não são permitidos'),
            sobrenome: Yup.string().required('campo obrigatório').matches(nomeRegex, 'Números não são permitidos'),
            email: Yup.string().required('campo obrigatório').email('Digite um email válido'),
            phone: Yup.string().required('campo obrigatório'),
            cpf: Yup.string().required('campo obrigatório'),
            cep: Yup.string().required('campo obrigatório').matches(cepRegex, 'Digite um cep no formato válido, ex.: 11111-111'),
            av: Yup.string().required('campo obrigatório').required('O campo obrigatório'),
            numero: Yup.number('Digite um número').required('campo obrigatório'),
            comp: Yup.string(),
            bairro: Yup.string().required('campo obrigatório'),
            cidade: Yup.string().required('campo obrigatório'),
            estado: Yup.string().required('campo obrigatório'),
            cardHolderName: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').matches(nomeRegex, 'Números não são permitidos') : Yup.string().matches(nomeRegex, 'Números não são permitidos'),
            cardNumber: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').min(19, 'Insira todos os números do cartão.') : null,
            cardCVV: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').matches(numbersRegex, 'Digite apenas números, ex.: 123') : Yup.string().matches(numbersRegex, 'Digite apenas números, ex.: 123'),
            cardExpiryMonth: !planId || cartTotal > 0 ? Yup.number('Apenas números').typeError('Apenas números').required('campo obrigatório').min(1, 'Mês entre 01 e 12').max(12, 'Valor entre 01 e 12') : Yup.number('Apenas números').typeError('Apenas números').min(1, 'Mês entre 01 e 12').max(12, 'Valor entre 01 e 12').nullable(),
            cardExpiryYear: !planId || cartTotal > 0 ? Yup.number('Apenas números').typeError('Apenas números').required('campo obrigatório').min(2021, 'Digite um ano válido') : Yup.number('Apenas números').typeError('Apenas números').required('campo obrigatório').min(2021, 'Digite um ano válido').nullable(),
            creditCardHolderName: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').matches(nomeRegex, 'Números não são permitidos') : Yup.string().matches(nomeRegex, 'Números não são permitidos'),
            creditCardHolderEmail: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').email('Digite um email válido') : Yup.string().email('Digite um email válido'),
            creditCardHolderPhone: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório') : null,
            creditCardHolderCPFCNPJ: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório') : null,
            creditCardHolderCEP: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').matches(cepRegex, 'Digite um cep no formato válido, ex.: 11111-111') : Yup.string().matches(cepRegex, 'Digite um cep no formato válido, ex.: 11111-111'),
            creditCardHolderAddressNumber: !planId || cartTotal > 0 ? Yup.string().required('campo obrigatório').matches(numbersRegex, 'Somente numeros, ex.: 111111') : Yup.string().matches(numbersRegex, 'Somente numeros, ex.: 111111'),
    }),
        onSubmit: async (values) => {
            setLoading(true);
            const user = userInfo;
            const config = {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
            }
            const {
                pnome,
                sobrenome,
                email,
                phone,
                cpf,
                cep,
                av,
                numero,
                comp,
                bairro,
                cidade,
                estado,
                cardHolderName,
                cardNumber,
                cardCVV,
                cardExpiryMonth,
                cardExpiryYear,
                creditCardHolderName,
                creditCardHolderEmail,
                creditCardHolderPhone,
                creditCardHolderAddressNumber,
                creditCardHolderCEP,
                creditCardHolderCPFCNPJ,
                } = values	
                try {
                await axios.put(Constants.ApiUrl + '/users/' + user.id, {
                    name: pnome,
                    email: email,
                    last_name: sobrenome,
                    phone: phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
                    cpf_cnpj: cpf.replace(/(\.)/g, "").replace('-', ''),
                    zip_code: cep,
                    street: av,
                    street_number: numero,
                    complement: comp,
                    neighborhood: bairro,
                    city: cidade,
                    state: estado,
                },
                    config
                )
                    dispatch({
                        type: 'USER_LOGIN_SUCCESS',
                        payload: {
                         data: state.userLogin.userInfo,
                         userData: {
                            name: pnome,
                            email: email,
                            last_name: sobrenome,
                            phone: phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
                            cpf_cnpj: cpf.replace(/(\.)/g, "").replace('-', ''),
                            zip_code: cep,
                            street: av,
                            street_number: numero,
                            complement: comp,
                            neighborhood: bairro,
                            city: cidade,
                            state: estado,
                        }
                        }
                    })
                } catch (error) {
                    setLoading(false)
                    Modal.warning({
                        title: "Erro ao atualizar Perfil!",
                        content: error.response.data.message
                    })
                }
            
            const schedulesToConfirm = schedules.map(schedule => {
                return { service: schedule.id, professional: schedule.profissional.id, date: schedule.date.dateIso}
            })
                    
            await axios
                .post(Constants.ApiUrl + '/schedules/', {
                    client: user.id,
                    schedulesToConfirm,
                    couponCode: cupom,
                    giftedUserEmail: giftedUserEmail,
                    gitftedUserName: giftedUserName,
                    planId: planId,
                    creditCard: {
                        holderName: cardHolderName,
                        number: cardNumber.replaceAll(' ', ''),
                        expiryMonth: cardExpiryMonth,
                        expiryYear: cardExpiryYear,
                        ccv: cardCVV,
                    },
                    creditCardHolderInfo: {
                        name: creditCardHolderName,
                        email: creditCardHolderEmail,
                        cpfCnpj: creditCardHolderCPFCNPJ,
                        postalCode: creditCardHolderCEP,
                        addressNumber: creditCardHolderAddressNumber,
                        addressComplement: null,
                        phone: creditCardHolderPhone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
                        mobilePhone: creditCardHolderPhone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
                    },
                }, config)
                .then((response)=>{
                    setLoading(false)
                    if(discontValue === 100){
                        const aux = Modal.success({
                            title: 'Agendado com sucesso!',
                            content: <p className="teste">Seu agendamento foi confirmado, cheque sua agenda.</p>,
                            onOk: ()=> {
                                dispatch(generalCartRemoveRequest([]))
                                history.push('/minha-conta')
                                aux.destroy();
                            },
                            maskClosable: true,
                        });
                    }else{
                        const aux = Modal.success({
                            title: 'Sucesso!',
                            content: <p className="teste">{response.data.message}</p>,
                            onOk: ()=> {
                                dispatch(generalCartRemoveRequest([]))
                                history.push('/minha-conta')
                                aux.destroy();
                            },
                            maskClosable: true,
                        });
                    }
                })
                .catch((err)=> {
                    Modal.error({
                        title: "Ops!!",
                        content: err.response.data.message,
                        onOk: ()=> {setLoading(false)}
                    });
                })
                
        },

    })
    
    useEffect(()=> {
        async function getCities(){
          const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${formik.values.estado}/municipios`)
          const cityName = response.data.map(city => city.nome);
          setCities(cityName.sort())
        }
        getCities()
      }, [formik.values.estado])    

    const handleVerifyCupom = async () => {
        setCupomError(false)
        setDiscontValue(0)
        setCupomLoading(true)
        const user = userInfo;
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            params: {
                service: schedules[0].id,
                professional: schedules[0].profissional.id
            }
        }
        try {
            const response = await axios.get(`${Constants.ApiUrl}/coupons/verify/${cupom}`, config)
            if(!!response.data.discount){
                setDiscontValue(response.data.discount)
            }else {
                setCupomErrorMessage(response.data.message);
                setCupomError(true)
            }
        } catch (error) {
            setCupomErrorMessage(error.response.data.message);
            setCupomError(true)
        }
        setCupomLoading(false)
    }

    const handleDeleteService = async (id) => {
        const serviceIndex = schedules.findIndex(service => service.id === id)
        const aux = schedules
        aux.splice(serviceIndex,1)
        dispatch(generalCartRemoveRequest(aux))
        setSchedules(aux)
    }  

    const handleAutoComplete = async (cep) => {
        
        await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {
                const address = response.data;

                formik.setFieldValue('av', address.logradouro);
                formik.setFieldValue('bairro', address.bairro);
                formik.setFieldValue('cidade', address.localidade);
                formik.setFieldValue('estado', address.uf);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleCopyData = () => {
        const values = formik.values;
        formik.setFieldValue('creditCardHolderName', `${values.pnome} ${values.sobrenome}`);
        formik.setFieldValue('creditCardHolderEmail', values.email);
        formik.setFieldValue('creditCardHolderPhone', values.phone);
        formik.setFieldValue('creditCardHolderCPFCNPJ', values.cpf);
        formik.setFieldValue('creditCardHolderCEP', values.cep);
        formik.setFieldValue('creditCardHolderAddressNumber', values.numero);
    }
   
        return (
            <div className="container">
                <Loading loading={loading} />
                
                <HeaderI>
                    <div className="payment-header">
                        <h1>Pagamento</h1>
                        <button className="payment-header-button" onClick={()=> history.push('/agenda-geral')}>Adicionar mais itens</button>
                    </div>
                </HeaderI>
                <div className="payment-container">
                    <div className="payment-content">
                        <div className="cart">
                            {schedules.length > 0 && schedules.map((service) => {
                                return (
                                    <div className="card" key={service.id}>
                                        <div className="group">
                                            <span className="text">{service.name}</span>
                                            <button className="delete" onClick={() => handleDeleteService(service.id)}>
                                                <FiDelete size={20}/>
                                            </button>
    
                                        </div>
                                        <span className="text">{service.profissional.name}</span>
                                        <span className="text">{service.date.name + ' às ' + service.date.hour}</span>
                                        <span className="text">Modalidade - {service.type}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="payment-method">
                            <h2 className="payment-title">Forma de pagamento</h2>
                            <form ref={formRef} onSubmit={formik.handleSubmit}>
                                <div className="personal-data">
                                    <div className="input-grid-group">
                                        <div className="input">
                                            <label htmlFor="pname" className="label">Primeiro nome*</label>
                                            <Input
                                            id="pnome"
                                            name="pnome"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.pnome}/>
                                            {formik.touched.pnome && formik.errors.pnome ? (<div className="form-field-edit-validate">{formik.errors.pnome}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="sobrenome" className="label">Sobrenome*</label>
                                            <Input id="sobrenome" name="sobrenome" type="text" onChange={formik.handleChange}
                                                            value={formik.values.sobrenome} className="form-input"/>
                                            {formik.touched.sobrenome && formik.errors.sobrenome ? (<div className="form-field-edit-validate">{formik.errors.sobrenome}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="input">
                                    <label htmlFor="email" className="label">Email*</label>
                                    <Input id="email" name="email" onChange={formik.handleChange}
                                                        value={formik.values.email}type="text" className="form-input"/>
                                    {formik.touched.email && formik.errors.email ? (<div className="form-field-edit-validate">{formik.errors.email}</div>) : null}
                                    </div>

                                    <div className="input-grid-group">
                                        <div className="input">
                                        <label htmlFor="phone" className="label">Telefone | Whatsapp*</label>
                                        <Input 
                                            id="phone" 
                                            type="text" 
                                            name="phone" 
                                            onChange={formik.handleChange}
                                            maxLength="15"
                                            value={phoneMask(formik.values.phone)} className="form-input"/>
                                        {formik.touched.phone && formik.errors.phone ? (<div className="form-field-edit-validate">{formik.errors.phone}</div>) : null}
                                        </div>
                                        <div className="input">
                                        <label htmlFor="cpf" className="label">CPF/CNPJ*</label>
                                        <Input 
                                            id="cpf" 
                                            type="text" 
                                            name="cpf" 
                                            onChange={formik.handleChange}
                                            maxLength="14"
                                            value={cpfMask(formik.values.cpf)} className="form-input"
                                        />

                                        {formik.touched.cpf && formik.errors.cpf ? (<div className="form-field-edit-validate">{formik.errors.cpf}</div>) : null}
                                        </div>
                                    </div>
                                    
                                    <div className="input">
                                        <label htmlFor="av" className="label">Av./Rua*</label>
                                        <Input id="av" type="text" name="av" onChange={formik.handleChange}
                                                        value={formik.values.av} className="form-input"/>
                                        {formik.touched.av && formik.errors.av ? (<div className="form-field-edit-validate">{formik.errors.av}</div>) : null}
                                    </div>
                                    <div className="input-group">
                                        <div className="input">
                                        <label htmlFor="cep" className="label">CEP*</label>
                                        <Input id="cep" type="text" name="cep" onChange={formik.handleChange}
                                                maxLength="9" onBlur={e => {handleAutoComplete(e.target.value)}} value={cepMask(formik.values.cep)} className="form-input"/>
                                        {formik.touched.cep && formik.errors.cep ? (<div className="form-field-edit-validate">{formik.errors.cep}</div>) : null}
                                        </div>
                                        
                                        <div className="input">
                                            <label htmlFor="numero" className="label">Número*</label>
                                            <Input id="numero" type="number" name="numero" onChange={formik.handleChange}
                                                            value={formik.values.numero} className="form-input"/>
                                            {formik.touched.numero && formik.errors.numero ? (<div className="form-field-edit-validate">{formik.errors.numero}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="comp" className="label">Complemento*</label>
                                            <Input id="comp" type="text" name="comp" onChange={formik.handleChange}
                                                            value={formik.values.comp} className="form-input"/>
                                            {formik.touched.comp && formik.errors.comp ? (<div className="form-field-edit-validate">{formik.errors.comp}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input">
                                            <label htmlFor="bairro" className="label">Bairro*</label>
                                            <Input id="bairro" type="text" name="bairro" onChange={formik.handleChange}
                                                            value={formik.values.bairro} className="form-input"/>
                                            {formik.touched.bairro && formik.errors.bairro ? (<div className="form-field-edit-validate">{formik.errors.bairro}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="estado" className="label">Estado*</label>
                                            <Select
                                                id="estado"
                                                name="estado"
                                                size='middle'
                                                showSearch
                                                className="form-field-select-payment"
                                                onChange={(value)=> formik.setFieldValue('estado', value, false)}
                                                value={formik.values.estado}
                                                >
                                                {states.map((uf) => (
                                                <Option key={uf} value={uf}>{uf}</Option>
                                                ))}
                                                </Select>
                                            {formik.touched.estado && formik.errors.estado ? (<div className="form-field-edit-validate">{formik.errors.estado}</div>) : null}
                                        </div>

                                        <div className="input" >
                                            <label htmlFor="cidade" className="label">Cidade*</label>
                                            <Select
                                                id="cidade"
                                                name="cidade"
                                                size='middle'
                                                showSearch
                                                className="form-field-select-signup"
                                                onChange={(value) => formik.setFieldValue('cidade', value, false)}
                                                value={formik.values.cidade}
                                                >
                                                {cities.map((city) => (
                                                <Option key={city} value={city}>{city}</Option>
                                                ))}
                                                </Select>
                                            {formik.touched.cidade && formik.errors.cidade ? (<div className="form-field-edit-validate">{formik.errors.cidade}</div>) : null}
                                        </div>
                                    </div>
                                    <h2 className="payment-title">Dados do cartão</h2>
                                    <div className="input">
                                        <label htmlFor="cardNumber" className="label">Numero do cartão*</label>
                                        <Input
                                            id="cardNumber"
                                            name="cardNumber"
                                            onChange={formik.handleChange}
                                            placeholder="0000 0000 0000 0000"
                                            value={cardNumberMask(formik.values.cardNumber)}
                                            maxLength="19"
                                            type="text"
                                            className="form-input"
                                        />
                                        {formik.touched.cardNumber && formik.errors.cardNumber ? (<div className="form-field-edit-validate">{formik.errors.cardNumber}</div>) : null}
                                    </div>
                                    <div className="input">
                                        <label htmlFor="cardHolderName" className="label">Nome do titular do cartão*</label>
                                        <Input
                                            id="cardHolderName"
                                            name="cardHolderName"
                                            placeholder="Nome impresso no cartão"
                                            onChange={formik.handleChange}
                                            value={formik.values.cardHolderName}
                                            type="text"
                                            className="form-input"
                                        />
                                        {formik.touched.cardHolderName && formik.errors.cardHolderName ? (<div className="form-field-edit-validate">{formik.errors.cardHolderName}</div>) : null}
                                    </div>
                                    <div className="input-card-group">
                                        <div className="input">
                                            <label htmlFor="cardCVV" className="label">CVV* <Tooltip color="#ec923c" title=""><span><AiOutlineQuestionCircle /></span></Tooltip></label>
                                            <Input 
                                                id="cardCVV" 
                                                type="text"
                                                name="cardCVV" 
                                                placeholder="000"
                                                onChange={formik.handleChange}
                                                maxLength="4"
                                                value={formik.values.cardCVV} className="form-input"
                                            />
                                            {formik.touched.cardCVV && formik.errors.cardCVV ? (<div className="form-field-edit-validate">{formik.errors.cardCVV}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="cardExpiryMonth" className="label">Mês*</label>
                                            <Input 
                                                id="cardExpiryMonth" 
                                                type="text" 
                                                name="cardExpiryMonth"
                                                placeholder="00"
                                                onChange={formik.handleChange}
                                                maxLength="2"
                                                value={formik.values.cardExpiryMonth} className="form-input"
                                            />
                                            {formik.touched.cardExpiryMonth && formik.errors.cardExpiryMonth ? (<div className="form-field-edit-validate">{formik.errors.cardExpiryMonth}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="cardExpiryYear" className="label">Ano*</label>
                                            <Input 
                                                id="cardExpiryYear" 
                                                type="text" 
                                                name="cardExpiryYear"
                                                placeholder="0000"
                                                onChange={formik.handleChange}
                                                maxLength="4"
                                                value={formik.values.cardExpiryYear} className="form-input"
                                            />
                                            {formik.touched.cardExpiryYear && formik.errors.cardExpiryYear ? (<div className="form-field-edit-validate">{formik.errors.cardExpiryYear}</div>) : null}
                                        </div>
                                    </div>   
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <h2 className="payment-title">Dados do titular do cartão <Tooltip color="#eba360" title="Sua compra será recusada caso os dados do titular do cartão estejam em conflito com os dados cadastrados na operadora."><span><AiOutlineQuestionCircle /></span></Tooltip></h2>
                                        <button type="button" className="copy-data-button" onClick={() => handleCopyData()}>Copiar dados da nota</button>
                                    </div>
                                    <div className="input-grid-group">
                                        <div className="input">
                                            <label htmlFor="pname" className="label">Nome completo*</label>
                                            <Input
                                            id="creditCardHolderName"
                                            name="creditCardHolderName"
                                            type="text"
                                            placeholder="Nome do titular do cartão"
                                            onChange={formik.handleChange}
                                            value={formik.values.creditCardHolderName}/>
                                            {formik.touched.creditCardHolderName && formik.errors.creditCardHolderName ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderName}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="creditCardHolderEmail" className="label">Email*</label>
                                        <Input
                                            id="creditCardHolderEmail"
                                            name="creditCardHolderEmail"
                                            placeholder="email@email.com"
                                            onChange={formik.handleChange}
                                            value={formik.values.creditCardHolderEmail}
                                            type="text"
                                            className="form-input"
                                        />
                                        {formik.touched.creditCardHolderEmail && formik.errors.creditCardHolderEmail ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderEmail}</div>) : null}
                                    </div>
                                    <div className="input-grid-group">
                                        <div className="input">
                                        <label htmlFor="creditCardHolderPhone" className="label">Telefone | Whatsapp*</label>
                                        <Input 
                                            id="creditCardHolderPhone" 
                                            type="text" 
                                            name="creditCardHolderPhone"
                                            placeholder="(00) 00000-0000"
                                            onChange={formik.handleChange}
                                            maxLength="15"
                                            value={phoneMask(formik.values.creditCardHolderPhone)} className="form-input"/>
                                        {formik.touched.creditCardHolderPhone && formik.errors.creditCardHolderPhone ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderPhone}</div>) : null}
                                        </div>
                                        <div className="input">
                                        <label htmlFor="creditCardHolderCPFCNPJ" className="label">CPF/CNPJ*</label>
                                        <Input 
                                            id="creditCardHolderCPFCNPJ" 
                                            type="text" 
                                            name="creditCardHolderCPFCNPJ"
                                            placeholder="000.000.000-00"
                                            onChange={formik.handleChange}
                                            maxLength="14"
                                            value={cpfMask(formik.values.creditCardHolderCPFCNPJ)} className="form-input"
                                        />

                                        {formik.touched.creditCardHolderCPFCNPJ && formik.errors.creditCardHolderCPFCNPJ ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderCPFCNPJ}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="input-card-group">
                                        <div className="input">
                                        <label htmlFor="creditCardHolderCEP" className="label">CEP*</label>
                                        <Input
                                            id="creditCardHolderCEP"
                                            type="text"
                                            name="creditCardHolderCEP"
                                            placeholder="00000-000"
                                            onChange={formik.handleChange}
                                            maxLength="9"
                                            value={cepMask(formik.values.creditCardHolderCEP)}
                                            className="form-input"
                                        />
                                        {formik.touched.creditCardHolderCEP && formik.errors.creditCardHolderCEP ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderCEP}</div>) : null}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="creditCardHolderAddressNumber" className="label">Número*</label>
                                            <Input
                                            id="creditCardHolderAddressNumber"
                                            type="number"
                                            name="creditCardHolderAddressNumber"
                                            placeholder="000"
                                            onChange={formik.handleChange}
                                            value={formik.values.creditCardHolderAddressNumber}
                                            className="form-input"
                                        />
                                        {formik.touched.creditCardHolderAddressNumber && formik.errors.creditCardHolderAddressNumber ? (<div className="form-field-edit-validate">{formik.errors.creditCardHolderAddressNumber}</div>) : null}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="payment">
                        <img className="payment-img" src={require("../../assets/images/register.png")} alt=""/>
                        <span className="order-number">Resumo do pedido</span>
                        <div className="payment-subtotal">


                            {schedules.map((service) => (
                                <div key={service.id}className="group">
                                    <span className="text">{service.name}</span>
                                    <span className="text">{service.formatedPrice}</span>
                                </div>
                            ))}

                            <div className="group">
                                <span className="text">Subtotal:</span>
                                <span className="text">{formatedSubtotal}</span>
                            </div>
                            <div className="gift-group">
                                <Menu
                                    onClick={()=>{}}
                                    style={{ width: '100%' }}
                                    defaultSelectedKeys={[]}
                                    defaultOpenKeys={[]}
                                    mode="inline"
                                >
                                <SubMenu
                                key="sub1"
                                title={<span>Possui Cupom?</span>}
                                >
                                    <div className="cupom-container">
                                        <div className="cupom">
                                            <span className="text">Cupom:</span>
                                            <div className="cupom-group">
                                                <Input 
                                                    onChange={(e) => {
                                                        setCupom(e.target.value.toUpperCase());
                                                    }}
                                                    value={cupom}
                                                    onBlur={handleVerifyCupom}
                                                    placeholder="Cupom"
                                                    style={{width: '100%'}}
                                                />
                                                <Button loading={cupomLoading} loadingSize={10} className="cupom-button">Aplicar</Button>
                                                
                                            </div>
                                            
                                        </div>
                                        {cupomError ? 
                                            (<span className="cupom-error">{cupomErrorMessage}</span>)
                                            : 
                                            (discontValue > 0) 
                                            ? <span className="cupom-valid">Desconto de {discontValue}%</span>
                                            :
                                            (<span></span>)
                                        }
                                    </div>
                                </SubMenu>
                                </Menu>
                                <Menu
                                    onClick={()=>{}}
                                    style={{ width: '100%' }}
                                    defaultSelectedKeys={[]}
                                    defaultOpenKeys={[]}
                                    mode="inline"
                                >
                                <SubMenu
                                key="sub1"
                                title={<span>Comprar como presente</span>}
                                >
                                    <div className="gift-container">
                                        <div className="gift">
                                            <div className="gift-group">
                                            <div>
                                                <span className="text">Nome: </span>
                                                <Input 
                                                    onChange={(e) => {
                                                        setGiftedUserName(e.target.value);
                                                    }}
                                                    value={giftedUserName}
                                                    placeholder="Nome"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div>
                                                <span className="text">Email: </span>
                                                <Input 
                                                    onChange={(e) => {
                                                        setGiftedUserEmail(e.target.value);
                                                    }}
                                                    type="email"
                                                    value={giftedUserEmail}
                                                    placeholder="email"
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </SubMenu>
                                </Menu>
                            </div>
                        </div>
                        <div className="payment-total">
                            <span className="text">Total:</span>
                            <span className="text">{formatedTotal}</span>
                        </div>
                        {userPlans.length > 0 &&

                            <div className="plan-option" >
                                <h2>Planos disponíveis</h2>
                                {
                                    userPlans?.map((userPlan) => {
                                        return (
                                            <div key={userPlan.plan_id} className="plan-card" >
                                                <p>Plano: <span>{userPlan.plan.name}</span></p>
                                                <p>Unidades disponíveis: <span>{userPlan.quantity_available}</span></p>
                                                <Checkbox onChange={(e) => {
                                                    if(e.target.checked){
                                                        setPlanId(userPlan.plan_id);
                                                    }else{
                                                        setPlanId(undefined);
                                                    }
                                                }}>
                                                    Utilizar este plano na compra
                                                </Checkbox>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }                        
                        <button type='submit' className="payment-button" onClick={()=>formik.submitForm()}>
                            Pagar
                        </button>
                    </div>
                </div>
            </div>
        )
    
}

export default GeneralPayment;