import { Constants } from '../constants';
import axios from 'axios';

const AccessControll = async (page) => {

    const userInfoFromStorage = localStorage.getItem('persist:@MundoAkar')
    ? JSON.parse(localStorage.getItem('persist:@MundoAkar'))
    : null;

    const userLogin = JSON.parse(userInfoFromStorage ? userInfoFromStorage.userLogin : null);

    const userInfo = userLogin ? userLogin.userInfo : null;

    const body = {
        page,
        userId: userInfo ? userInfo.id : null 
    }

    await axios.post(`${Constants.ApiUrl}/access`, body)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            })
}

export default AccessControll;