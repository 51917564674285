import React, { useCallback, useEffect, useState } from 'react';
import { Pagination, Input } from 'antd';
import Header from '../../components/header';
import Hero from '../../components/hero';
import PostCard from '../../components/post-card';
import Footer from '../../components/footer';
import AccessControll from '../../services/access';
import Advertisement from '../../components/advertisement';
import { Constants } from "../../constants";
import axios from "axios";
import "./style.scss";

const HomeBlog = () => {

    const { Search } = Input;

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [postsTotalPages, setPostsTotalPages] = useState();

    const getPosts = useCallback(async (page, filterTitle) => {
        setIsLoading(true);
        const LIMIT = 8;
        await axios
            .get(`${Constants.ApiUrl}/posts`, {
                params: {
                    range: `[${(page -1)*LIMIT},${page*LIMIT -1}]`,
                    sort: `["createdAt", "DESC"]`,
                    filter: {
                        "title": `${filterTitle ? filterTitle : ''}`
                    }
                }
            })
            .then((response) => {
                const range = response.headers["content-range"];
                const total = range.split('/').pop();
                setPostsTotalPages(Math.ceil(total/8));
                setPosts(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [])

    useEffect(() => {
        getPosts(1);
        AccessControll('Home Blog')
    }, [getPosts]);

    return (
        <div id="home-blog">
            <Header />
            <Hero
                image={require("../../assets/images/home-main-image.png")}
                h1={"Blog Mundo Akar"}
            />

            <section className="blog-box">
                <div className="section-header">
                    <h3>Últimos artigos</h3>
                    <div>
                        <Search className="search-box" placeholder="Buscar" onSearch={(value) => {getPosts(1, value)}} />
                    </div>
                </div>
                <div className="flex-horizontal flex-wrap justify-around">
                    {   
                        posts?.length > 0 ?
                        posts?.map((post) => {
                            return (
                                <PostCard
                                    id={post.id}
                                    title={post.title}
                                    abstract={post.abstract}
                                    thumbnail_url={post.thumbnail_url}
                                    date={post.createdAt}
                                    key={post.id}
                                    loading={isLoading}
                                />
                            )
                        })
                        :
                        <h1>Não foi encontrada nenhum artigo para o termo buscado</h1>
                    }
                </div>
                <div className={"flex-horizontal justify-center"}>
                    <Pagination className="pagination-comp" onChange={(page) => {getPosts(page)}} defaultCurrent={1} pageSize={true} total={postsTotalPages}/>
                </div>
            </section>

            <Advertisement local="home-blog" />

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );

}

export default HomeBlog;