import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, Button, Radio, Modal } from 'antd';
import Header from '../../components/header';
import { getDate, getYear, getMonth, format, getHours, getMinutes, parseISO, isEqual } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ptBRA from 'antd/lib/locale/pt_BR';
import { Constants } from "../../constants";
import Loading from '../../components/loading';
import ProfileProf from '../profileProfessional'
import axios from "axios";
import { generalCartAddRequest, generalCartRemoveRequest } from '../../redux/actions/generalCartActions';
import { backgroundPaths, mainImagePaths } from '../../components/service/paths';
import "./styles.scss";
import AccessControll from '../../services/access';

class GeneralAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            professionals: [],
            monthAvailability: [],
            currentService: {},
            currentProfessional: {},
            availableSchedules: [],
            scheduleHour: '',
            scheduleMinute: '',
            selectedSchedule: {},
            dayName: '',
            year: '',
            month: '',
            day: '',
            loading: true,
            modalProfessional: {
                visibility: false,
                professional: {}
            },
            modalWaitList: {
                visibility: false,
            }
        }
    }
    
    componentDidMount = () => {
        this.verifyCart()

        if (this.props.location.state){
            this.handleServiceChoice(this.props.location.state, this.props.userInfo)
            const weekNames = ['D', 'S', 'T','Q','Q','S','S',]
            let tableHeaders = document.querySelectorAll('th')
            for (let i = 0; i < weekNames.length; i++) {
                tableHeaders[i].innerHTML = weekNames[i]
            }
        }else {
            this.setState({
                loading: false
            })
            Modal.error({
                title: 'Ops!',
                content: 'Serviço indisponível ou inexistente.',
                onOk: () => {
                    this.props.history.push('/agendar')
                }
            })
        }
        
        AccessControll('Selecionar Agendamentos');
    }

    verifyCart = async () => {
        const {generalCart, generalCartRemoveRequest} = this.props;
                
        if(generalCart?.length > 0 ){
            generalCart.forEach((service, index) => {
                const fifteenMinutes = 900000;
                if(Math.abs(new Date() - new Date(service.created_at)) > fifteenMinutes){
                    const aux = []
                    generalCart.forEach((s, i) => {
                        if(i !== index ){
                            aux.push(s)
                        }
                    })
                    generalCartRemoveRequest(aux)
                }            
            });
        }
        
    }

    getMonthAvailability = async (currentProfessional) => {
        const date = new Date();
        const year = getYear(date);
        const month = getMonth(date);
        const service = this.props.location.state;
        
        const config = {
            params: {
                year,
                month: month +1,
                service: service.id,
            }
        }
        //retorna os dias que tem ou nao agendamentos
        await axios
            .get(Constants.ApiUrl + '/schedules/availability/month/' + currentProfessional.id, config) //professionalId
            .then((response) => {
                this.setState({
                    monthAvailability: response.data
                })
            }).catch((error) => {
                console.log(error.message);
            });
    }

    //when professionalChange trigers it does not update the calendar
    dateCellRender = (value) => {
        if (this.state.monthAvailability.length > 0 ){
            const found = this.state.monthAvailability.find((service) =>  { 
                return isEqual(new Date(service.day).setHours(0,0,0,0), value._d.setHours(0,0,0,0)) && service.available === true}
                )
                if(found){
                return (
                    <div className="circle"></div>
                )
            }else{
                return (
                    <div></div>
                )
            }
        }
        return (
            <div></div>
        )
    }

    handleServiceChoice = async (service, user) => {

        let auxProfessional;

        await axios
            .get(`${Constants.ApiUrl}/services/${service.id}/professionals/`)
            .then((response) => {
                response.data.forEach((data) => {
                    this.setState({
                        professionals: data.serviceProfessionals,
                        currentProfessional: data.serviceProfessionals[0],
                        currentService: service,
                        loading: false,
                    });
                    auxProfessional = data.serviceProfessionals[0];
                });
            }).catch((error) => {
                console.log(error.message);
            });
            this.handleInitialDate(new Date(), auxProfessional);
        
            this.getMonthAvailability(auxProfessional)
    }

    handleInitialDate = async (date, professional) => {
        const year = getYear(date);
        const month = getMonth(date);
        const day = getDate(date);
        const dayName = format(date, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
        // const firstProfessional = this.state.professionals[0];
        const service = this.state.currentService;

        this.setState({
            year,
            month: month +1,
            day,
            dayName,
        });

        this.handleProfessionalChange(professional);
        const config = {
            params: {
                year,
                month: month,
                day,
                service: service.id,
            }
        }

        if(!professional){
            return;
        }

        await axios
            .get(Constants.ApiUrl + '/schedules/availability/day/' + professional.id, config)
            .then(response => {
                
                const aux = response.data.map((schedule, index) => {
                    let min = getMinutes(parseISO(schedule.date))
                    
                    return {
                        ...schedule,
                        hour: getHours(parseISO(schedule.date)),
                        minutes: this.parseMinuteTo00(min),
                        id: index
                    }
                })
                this.setState({
                    availableSchedules: aux
                })
            }).catch((error) => {
                console.log(error);
            });
    }
    
    handleProfessionalChange = async (professional) => {
        const day = this.state.day;
        const month = this.state.month;
        const year = this.state.year;
        const service = this.state.currentService;

        this.setState({
            currentProfessional: professional,
        });
        const config = {
            params: {
                year,
                month: month,
                day,
                service: service.id
            }
        }
        await axios
            .get(Constants.ApiUrl + '/schedules/availability/day/' + professional.id, config)
            .then(response => {
                const aux = response.data.map((schedule, index) => {
                    let min = getMinutes(parseISO(schedule.date))
                    
                    return {
                        ...schedule,
                        hour: getHours(parseISO(schedule.date)),
                        minutes: this.parseMinuteTo00(min),
                        id: index
                    }
                })
                this.setState({
                    availableSchedules: aux
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    handleDateChange = async (date) => {
        const year = getYear(date._d);
        const month = getMonth(date._d);
        const day = getDate(date._d);
        const dayName = format(date._d, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
        const professional = this.state.currentProfessional;
        const service = this.state.currentService;
        
        this.setState({
            year,
            month: month +1,
            day,
            dayName
        });
        const config = {
            params: {
                year,
                month: month,
                day,
                service: service.id
            }
        }
        await axios
            .get(Constants.ApiUrl + '/schedules/availability/day/' + professional.id, config)
            .then(response => {
                const aux = response.data.map((schedule, index) => {
                    let min = getMinutes(parseISO(schedule.date))
                    
                    return {
                        ...schedule,
                        hour: getHours(parseISO(schedule.date)),
                        minutes: this.parseMinuteTo00(min),
                        id: index
                    }
                })
                this.setState({
                    availableSchedules: aux
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    handleMonthChange = async (date) => {
        const month = getMonth(date._d);
        this.setState({
            month: month +1
        });
        const service = this.props.location.state;
        const config = {
            params: {
                year: getYear(date._d),
                month: getMonth(date._d) +1,
                service: service.id
            }
        }
        
        await axios
        .get(Constants.ApiUrl + '/schedules/availability/month/' + this.state.currentProfessional.id, config)
        .then((response) => {
            this.setState({
                monthAvailability: response.data
            })
        }).catch((error) => {
            console.log(error.description);
        });
    }

    handleScheduleChange = (schedule) => {
        if(schedule.target.value){
            const hour = getHours(parseISO(schedule.target.value.date));
            const minutes = getMinutes(parseISO(schedule.target.value.date));
            const parsedMinute = this.parseMinuteTo00(minutes);
            this.setState({
                scheduleHour: hour,
                scheduleMinute: parsedMinute,
                selectedSchedule: schedule.target.value
            })
        }else {
            this.setState({
                selectedSchedule: {}
            })
        }
    }

    parseMinuteTo00(minute) {
        return (minute < 10 ? '0' : '') + minute;
    }

    handleNextButton = async () => {
        if (this.props.location.state){
            const { generalCartAddRequest, generalCart } = this.props;
            const aux = this.state;
            const service = {...this.props.location.state,
                price: aux.selectedSchedule.price, 
                profissional: aux.currentProfessional,
                type: aux.selectedSchedule.type,
                 date: {
                   dateIso: aux.selectedSchedule.date, 
                   name: aux.dayName,
                   hour: `${aux.scheduleHour}:${aux.scheduleMinute}`,
                },
                created_at: new Date()
               }
            if(!!generalCart.find((schedule)=>{
                return schedule['profissional']['id'] !== aux.currentProfessional.id
            })){
                
            Modal.error({
                content: 'Atenção! Você não pode adicionar horários de diferentes profissionais no carrinho!',
            });
            }else{
                generalCartAddRequest(service)
            }
            
        }
    }

    handleSwitchWaitListModalState = () => {
        this.setState({modalWaitList: { visibility: !this.state.modalWaitList.visibility }})
    }

    handleWaitListRequest = async () => {
        const user = this.props.userInfo;
        const service = this.props.location.state;

        this.handleSwitchWaitListModalState();
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        }

        await axios.post(Constants.ApiUrl + '/waitlistclients/', { user_id: user.id, professional_id: this.state.currentProfessional.id, service_id: service.id }, config)
        .then(response => {
            Modal.success({
                content: 'Você foi adicionado com sucesso nessa lista de espera!'
            })
        }).catch((error) => {
            Modal.error({
                content: error.response.data.message,
            });
        });
    }

    generateSlug(str) {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    render() {
        return (
            <div id="general-attendance">
                <Loading loading={this.state.loading} />
                <Header />
                <div className={"flex-vertical head"}>
                    <div className={"flex-vertical"} >
                        <div className={"service-banner flex-horizontal"}>
                            {this.state.currentService.name &&
                                <div>
                                    <svg className="background-service-image" >
                                        <defs>
                                            <pattern id="background-services" width="100%" height="100%" viewBox="0 0 200 200" preserveAspectRatio="none" z="-1">
                                                <image 
                                                    href={require("../../assets/images/background-services.png")} alt="background-services" x="-50" y="0" width="100%" height="100%" />
                                            </pattern>
                                        </defs>
                                        <path fill="url(#background-services)" d={backgroundPaths[this.getRandomInt(0, 10)]} transform="translate(165 115)" />
                                    </svg>
                                    <svg className="main-service-image">
                                        <defs>
                                            <pattern id={this.generateSlug(this.state.currentService.name)} width="100%" height="100%" viewBox="0 0 200 200" preserveAspectRatio="none">
                                                <image 
                                                    href={this.state.currentService.image_url} alt={this.generateSlug(this.state.currentService.name)} x="-50" y="0" width="100%" height="100%" />
                                            </pattern>
                                        </defs>
                                        <path fill={`url(#${this.generateSlug(this.state.currentService.name)})`} d={mainImagePaths[this.getRandomInt(0, 10)]} transform="translate(165 115)" />
                                    </svg>
                                </div>
                            }
                            <div>
                                <h1>{this.state.currentService.name}</h1>
                                <p>{this.state.currentService.description}</p>
                            </div>
                        </div>
                        <div className={"flex-vertical"}>
                            <h1>Atendimento Geral</h1>
                            <h3>Escolha um profissional, data e horário abaixo</h3>
                        </div>
                    </div>
                </div>
                    <div className="professional-box">
                        
                            <Modal
								title={<span className="modal-title">Perfil</span>}
								visible={this.state.modalProfessional.visibility}
								onCancel={() => this.setState({modalProfessional: {visibility: false}})}
								closable={true}
								footer={null}
                                destroyOnClose={true}
							>
                                <ProfileProf professional={this.state.modalProfessional.professional}/>
                               
							</Modal>
                        <div className="professional-container">
                            {
                                this.state.professionals?.map((professional) => {
                                    return (
                                        <div 
                                            key={professional.id} 
                                            onClick={() => {this.handleProfessionalChange(professional)}} 
                                            className=
                                            {professional.id === this.state.currentProfessional?.id ?
                                                "professional-SA flex-horizontal align-center professional-selected"
                                                : "professional-SA flex-horizontal align-center"}
                                        >
                                            <div className="professional-img">
                                                <img alt="profile" className="back" src={require("../../assets/images/solar-blue-circle.png")} />
                                                <img alt="profile" className="user" src={professional.image_url} />
                                            </div>
                                            <div className="professional-description">
                                                <p className="title">{professional.name}</p>
                                                <span onClick={()=> {this.setState({modalProfessional: {
                                                    visibility: true,
                                                    professional: professional
                                                }})}}>
                                                    Ver perfil
                                                </span>    
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={"schedule-container"}>
                            <div className={"schedule"} >
                                <h1>Agenda</h1>
                                <Calendar
                                    fullscreen={false}
                                    onChange={(date) => {this.handleDateChange(date)}}
                                    onPanelChange={(date) => {this.handleMonthChange(date)}}
                                    dateCellRender={this.dateCellRender}
                                    locale={ptBRA}
                                    disabledDate={(date) => {
                                        const month = getMonth(new Date());
                                        const day = getDate(new Date());
                                        const year = getYear(new Date());
                                        //bloquear algum dia da semana se necessário
                                        // const weekDay = getDay(date._d);
                                        // if(weekDay === 0 || weekDay === 6){
                                        //     return true;
                                        // }
                                        return date && date.isBefore(new Date(year, month, day));
                                    }}
                                    className="calendar"
                                />
                            </div>
                            <div className={"checks"} >
                                <h2>{this.state.dayName}</h2>
                                <div className={"schedules-options flex-vertical"}>
                                    {
                                        this.state.availableSchedules.length > 0 ? 
                                        <Radio.Group onChange={(e) => this.handleScheduleChange(e)}>
                                        {
                                            this.state.availableSchedules?.map((schedule) => {
                                                const date = schedule.date;
                                                const schedulehour = getHours(parseISO(date));
                                                const auxMinutes = getMinutes(parseISO(date));
                                                const scheduleMinutes = this.parseMinuteTo00(auxMinutes);                       
                                                return (
                                                    <div key={schedule.id} className={"check flex-vertical"}>
                                                        <Radio className="checkbox" value={schedule} disabled={!schedule.available}>                                                           
                                                            <div className="card">
                                                                <div className="group">
                                                                    <span className="hour">{schedulehour}:{scheduleMinutes}</span>
                                                                    <span className="name"> - {this.state.currentService.name}</span>
                                                                </div>
                                                                <div className="group colunm">
                                                                    <span className={`attendance-type-${schedule.type}`}>Atendimento {schedule.type}</span>
                                                                    <span className={`attendance-status-${schedule.status}`}>{schedule.status}</span>
                                                                </div>
                                                            </div>
                                                        </Radio>
                                                    </div>
                                                )
                                            })
                                        }
                                        </Radio.Group>
                                        :
                                        <div className="schedule-placeholder">
                                            <p>Não há consultas disponíveis para este dia...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-vertical justify-end attendance-buttons">
                                <Button onClick={this.handleNextButton} className={"btn btn-primary"} type="primary" disabled={!this.state.selectedSchedule.date} >Próximo</Button>
                                <Button onClick={() => this.props.history.push('/pagamento-geral')} className={"btn btn-primary"} type="primary" disabled={!(this.props.generalCart?.length > 0)} >Carrinho</Button>
                                <Button onClick={() => this.props.history.push('/agendar')} className={"btn btn-primary"} type="primary" >Voltar</Button>
                            </div>
                        </div>
                        <section className={"waitlistmessage"}>
                            <h2 onClick={() => this.handleSwitchWaitListModalState()}>O horário que eu queria já está ocupado...</h2>
                            <Modal
								title={<span className="modal-title">Lista de espera</span>}
								visible={this.state.modalWaitList.visibility}
								onCancel={() => this.handleSwitchWaitListModalState()}
								closable={true}
								footer={null}
                                destroyOnClose={true}
							>
                                <section style={{fontSize: '16px', fontWeight: '400', fontFamily: 'Philosopher', display: 'flex', flexDirection: 'column'}}>
                                    <h2>Caso o horário que você deseja se encontre ocupado no momento, você pode entrar na lista de espera e quando houver uma vaga nós te avisaremos!</h2>
                                    <Button onClick={() => this.handleWaitListRequest()} className={"btn btn-primary"} type="primary" style={{fontSize: '16px', marginTop: '1rem'}}>Entrar na lista de espera</Button>
                                </section>
							</Modal>
                        </section>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userLogin.userInfo,
        generalCart: state.generalCart.schedules
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        generalCartAddRequest: (service) => {
            dispatch(generalCartAddRequest(service));
        },
        generalCartRemoveRequest: (services) => {
            dispatch(generalCartRemoveRequest(services));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAttendance);
