import React, { useState } from 'react';
import Slide from 'react-slick';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const GaleryCarousel = ({ images }) => {

    const [imageIndex, setImageIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineRight
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }
      
    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <AiOutlineLeft
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            />
        );
    }

    const sliderSetings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                }
            }
        ],
        nextArrow: <SampleNextArrow className="slickArrow" />,
        prevArrow: <SamplePrevArrow className="slickArrow" />
    };

    return (
        <div id="galery-carousel" >
            {isOpen && (
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                    setImageIndex((imageIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + images.length - 1) % images.length)
                    }
                />
            )}
            <Slide {...sliderSetings}>
                {
                    images?.map((image, index) => {
                        return (
                            <div key={index}>
                                <img
                                    onClick={() => {setIsOpen(true); setImageIndex(index);}}
                                    className="galery-image"
                                    src={image} 
                                    alt={`imagem${index}`}
                                />
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
    )

}

export default GaleryCarousel;