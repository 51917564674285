import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Slide from 'react-slick';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import RoomCard from '../../components/room-card';
import { Constants } from '../../constants';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import AccessControll from '../../services/access';

const ServiceRooms = () => {

    const [rooms, setRooms] = useState([]);

    const getRooms = useCallback(async () => {
        await axios.get(`${Constants.ApiUrl}/rooms`)
            .then((response) => {
                setRooms(response.data);
            })
    }, [])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "none"}}
            onClick={onClick}
            />
        );
    }
      
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
            />
        );
    }

    const sliderSetings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        cssEase: "linear",
        nextArrow: <SampleNextArrow className="slickArrow" />,
        prevArrow: <SamplePrevArrow className="slickArrow" />
    };

    useEffect(() => {
        getRooms();
        AccessControll('Home Salas');
    }, [getRooms]);

    return (
        <div id="service-rooms">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/rooms-main-image.png")}
                h1={"Um mundo de autoconhecimento aguardando você"}
            />
            <div className="blog-box">
                <div className="header">
                    <h1 className="page-title">Salas de Atendimento</h1>
                    <h3>
                        No Mundo Akar Dispomos de várias salas com diferentes layouts para atender desde a reunião mais formal até um bate-papo descontraído. Todas as salas podem ser utilizadas em qualquer horário do dia ou da noite, inclusive finais de semana e feriados, mediante agendamento prévio.
                    </h3>
                </div>
                <Slide {...sliderSetings}>
                    <div>
                        <img src={require("../../assets/images/sala-01-coworking.jpeg")} alt="foto-da-sala" />
                    </div>
                    <div>
                        <img src={require("../../assets/images/sala-02-coworking.jpeg")} alt="foto-da-sala" />
                    </div>
                </Slide>
                <div className="description">
                    <div>
                        <span>Sem preocupação com manutenção</span>
                        <p>
                            Os espaços contam com limpeza frequente e profissionais que cuidam da organização do ambiente, portanto não é necessário gastar tempo com as atividades cotidianas de manutenção do espaço. 
                        </p>
                    </div>
                    <div>
                    <span>Zero burocracia, é agendar e começar</span>
                        <p>
                            Diferente de quando você aluga uma sala comercial com uma imobiliária, em um espaço de locação de salas por hora a burocracia é quase zero. Você poderá iniciar a atender no mesmo dia em que for conhecer o local, ou simplesmente agendar por telefone ou pela internet.
                        </p>
                    </div>
                </div>
                <h3>Salas disponíveis</h3>
                <div className="flex-horizontal justify-around flex-wrap">
                    {rooms?.map((room) => {
                        return (
                            <RoomCard
                                id={room.id}
                                name={room.name}
                                thumbnail_url={room.thumbnail_url}
                            />
                        )
                    })}
                </div>
            </div>
            
            <Footer backgroundImage={require("../../assets/images/blue-footer.png")} />
        </div>
    );

}

export default ServiceRooms;
