import React, { Component } from 'react';
import { addHours } from 'date-fns';
import { Modal } from "antd";
import { connect } from "react-redux";
import axios from 'axios';
import Button from '../../components/button';
import { Constants } from '../../constants';
import "./style.scss";

class CardGeneralSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addToGoogleCalendarURI: '',
            clientAttended: false,
            loading: false,
          };
    }
    handleSchedulerCancel = () => {
        this.setState({visible: true})
    }

    generateAddToGoogleCalendarURI = (schedule, type) => {

        const convertToDateTIME = (date) => {
            return (new Date(date)).toISOString().replace(/-|:|\.\d\d\d/g,"")
        }

        let details;

        if(type === 'professional'){
            details = encodeURIComponent(`${schedule.service_id.name} com a(o) cliente ${schedule.client_id.name} ${schedule.client_id.last_name}`);
        }else{
            details = encodeURIComponent(`${schedule.service_id.name} com a(o) profissional ${schedule.professional_id.name} ${schedule.professional_id.last_name}`);
        }

        const initialDate = convertToDateTIME(schedule.isoDate);

        const auxEndDate = addHours(new Date(schedule.isoDate), 1).toISOString();
        const endDate = convertToDateTIME(auxEndDate);

        const text =  encodeURIComponent(`Consulta no Mundo Akar`);
        const location = encodeURIComponent(`Mundo Akar ${schedule.type}`);
        const dates = encodeURIComponent(`${initialDate}/${endDate}`);

        const ctz = 'America%2FSao_Paulo';

        const URI = `https://calendar.google.com/calendar/r/eventedit?text=${text}&details=${details}&location=${location}&dates=${dates}&ctz=${ctz}`

        this.setState({
            addToGoogleCalendarURI: URI
        })
    }

    componentDidMount = () => {

        const { service, type } = this.props;
        this.generateAddToGoogleCalendarURI(service, type);
        this.setState({
            clientAttended: service.client_attended
        })
    }

    confirmClientAttended = async (scheduleId) => {
        const user = this.props.userInfo;
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        console.log(user.token);
        this.setState({
            loading: true
        })
        await axios.put(`${Constants.ApiUrl}/schedules/client/confirm/${scheduleId}`,null ,config)
                    .then(() => {
                        this.setState({
                            clientAttended: true
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        this.setState({
            loading: false
        })
    }

    render() {
        const { service, type } = this.props;
        if(type === 'professional'){
            return (
                <div key={service.id} className="general-card">
                    <div className="group">
                        <span className="service-name">{!!service.service_id.name ? service.service_id.name : 'Nome indisponivel'  }</span>
                        {service.is_gift ?
                            <span className="profissional-name">Cliente presenteado: {service.gifted_user_name}</span>
                            :
                            <span className="profissional-name">Cliente: {service.client_id.name}</span>
                        }
                        <span className="data">{service.date}</span>
                    </div>
                    <div className="group">
                        <div className="addToCalendar">
                            <a target="_blank" rel="noopener noreferrer" href={this.state.addToGoogleCalendarURI}>Adicione ao Calendar</a>
                        </div>
                        {   service.type &&
                            <span className={`attendance-type-${service.type}`}>
                                Atendimento {service.type}
                            </span>
                        }
                        {
                            service.zoom_url && 
                            <div className="zoom">
                                <a href={service.zoom_url}>Entre na reunião</a>
                            </div>
                        }
                    </div>
                    <div>
                    <Button 
                        loading={this.state.loading}
                        disabled={this.state.clientAttended}
                        loadingSize={14}
                        onClick={() => {
                        this.confirmClientAttended(service.id);
                        }}
                        className="confirm-client-button"
                        type="primary"
                    >{this.state.clientAttended ? <span>Cliente compareceu.</span> : <span>Confirmar presença do cliente</span>}</Button>
                    </div>
                </div>
            )
        }else{
            return (
                <>
                    <Modal
                        title={<span className="modal-title">Cancelar consulta</span>}
                        visible={this.state.visible}
                        onCancel={() => this.setState({visible: false})}
                        onOk={() => this.setState({visible: false})}
                        closable={true}
                        destroyOnClose={true}
                    >
                     <div>
                      <p>Para cancelamento da sua consulta entre em contato com nossa equipe pelo número 85 99199-6231.</p>
                      <p>Não é possível o reembolso do valor e o reagendamento fica sujeito à comunicação formal com mínimo de 48h úteis de antecedência.</p>  
                     </div>               
                    </Modal>
                    <div key={service.id} className="general-card">
                        <div className="group">
                                <span className="service-name">{!!service.service_id.name ? service.service_id.name : 'Nome indisponivel'  }</span>
                                <span className="profissional-name">{service.professional_id.name}</span>
                                <span className="data">{service.date}</span>
                            </div>
                            <div className="group">
                                <div className="addToCalendar">
                                    <a target="_blank" rel="noopener noreferrer" href={this.state.addToGoogleCalendarURI}>Adicione ao Calendar</a>
                                </div>
                                {   service.type &&
                                 <span className={`attendance-type-${service.type}`}>
                                     Atendimento {service.type}
                                 </span>
                                }
                                {
                                    service.zoom_url && 
                                    <div className="zoom">
                                        <a href={service.zoom_url}>Entre na reunião</a>
                                    </div>
                                }
                            </div>
                        <div></div>
                        {service.cancelabel && (<div className="cancel">
                            <button onClick={this.handleSchedulerCancel} className="cancel-button">Cancelar</button>
                        </div>)}
                        
                    
                    </div>
                </>
            )
        }
        
    }
}

const mapStateToProps = (state) => {
    return {
      userInfo: state.userLogin.userInfo,
    };
  };
  
export default connect(mapStateToProps)(CardGeneralSchedule);