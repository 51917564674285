import React, { useEffect } from 'react';
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import AccessControll from '../../services/access';
import "./style.scss";

const Social  = () => {

    useEffect(() => {
        AccessControll('Regras Social');
    }, []);

    return (
        <div id="social">
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/main-image-withback.png")}
                h1={"Por um mundo mais acolhedor"}
            />

            <section className={"social"}>
                <h3>Mundo Akar Social</h3>
                <p>Um projeto nascido do coração que promove o acesso a 
                várias possibilidades de bem-estar, autocuidado e 
                equilíbrio através de atendimentos em Terapias 
                Integrativas. 
                <br/><br/> 
                Nos reiventamos no isolamento social 
                para permanecer próximos e acessivéis a quem precisa.
                <br/><br/>
                Agora somos presencial e também online!
                <br/><br/> 
                Acolhimento + Cuidado + Voluntariado + Terapias Integrativas + Iniciativa Social = Mundo Akar Social
            </p>
            </section>
            <section className={"how"} >
                <h3>Como funciona?</h3>
                <p>
                    Toda terça-feira realizamos atendimentos terapêuticos 
                    em diversas áreas e abordagens com profissionais 
                    voluntários que unidos no propósito de levar LUZ 
                    ao coração das pessoas, realizam seus atendimentos 
                    por um valor mais acessível.
                    <br/><br/>
                    Os atendimentos podem sem PRESENCIAIS ou ONLINE, INDIVIDUAIS ou EM GRUPO.
                    <br/><br/>
                    Novidade:<br/> Em Breve nossa agenda terá: 1 semana com atendimentos presenciais - 1 semana com atendimentos online
                    <br/><br/>
                    Sempre revezando para possibilitar o acesso de todos e o respeito aos cuidados necessários. 
                    <br/><br/>
                    Os atendimentos online acontecem através da plataforma Zoom e logo que você se inscreve recebe em seu e-mail todas as orientações de acesso. Além de ter um número de suporte caso precise de alguma ajuda.
                </p>
                <div>
                    <section>
                        <h3>Quais terapias fazem parte?</h3>
                        <p>
                            Muitas! Semanalmente mudamos as terapias no intuito de possibilitar o seu encontro com várias técnicas, profissionais e caminhos a seguir.
                            <br/><br/>
                            <strong>Você pode encontrar, por exemplo:</strong> <br/>Escuta Terapêutica, Reiki, Barras de Access, Thetahealing, Tarot, Massagens, Aromaterapia, várias vivências com médicos e outros profissionais. E as possibilidades vão só aumentando!
                        </p>
                    </section>
                    <section>
                    <h3>Como faço para participar?</h3>
                        <p>
                            Os atendimentos individuais tem 30min de duração e os grupos podem variar entre 50m e 1h30m. <br/>Toda semana divulgamos em nossas redes sociais e no <a href="https://www.instagram.com/mundo_akar/?hl=en">@mundoakarsocial</a> a programação de atendimentos. Você pode se inscrever online  por aqui escolhendo até 02 (duas) terapias distintas por pessoa por edição, o profissional e horário mais adequado para o seu atendimento.
                            <br/><br/>
                            E se você é um profissional e deseja participar conosco nesse projeto, faz contato com a gente ou <a href="https://docs.google.com/forms/d/e/1FAIpQLSdrS3k00IYHgTGUxjKkx8pquMcikMwRh1Elq3NlM_nx0tp17Q/viewform">clique aqui</a>.<br/>Você vai sentir que fazer o bem só nos faz bem!
                        </p>
                    </section>
                </div>
            </section>
            <section className={"numbers"}> 
                <h3>Mundo Akar Social em números</h3>
                <div className={"numbers-image"} >
                    <img src={require("../../assets/images/numbers-social.png")} alt=""/>
                </div>
                <div className={"numbers-image-mobile"} >
                    <img src={require("../../assets/images/numbers-social-mobile.png")} alt=""/>
                </div>
            </section>
            <section className={"rules"}>
                <h3>Fique atento!</h3>
                <p>
                    Para garantir a organização necessária para pacientes e profissionais, é importante lembrar que:
                    <br/><br/>
                    O Mundo Akar Social é um projeto viabilizado pelo voluntariado dos terapeutas. As inscrições são limitadas a 02 (duas) terapias distintas por pessoa por edição e agendadas por hora marcada. A utilização é válida exclusivamente para o dia/hora determinado. Não é possível o reembolso do valor e o reagendamento fica sujeito à comunicação formal com mínimo de 24h úteis de antecedência.
                    <br/><br/>
                    Preparamos nossa casa com carinho, cuidados e muito afeto para te acolher bem e te ajudar a cuidar, fortalecer e nutrir bem seu corpo, mente e espírito.
                    <br/><br/>
                    <strong>Como participar em 05 passos:</strong>
                    <br/><br/>
                    01 - Conferir a programação no @mundo_akar ou @mundoakarsocial<br/>
                    02 - Acessar nossa página e escolher o ATENDIMENTO, PROFISSIONAL e HORÁRIO<br/>
                    03 - Pagamento realizado online, com mais facilidade e garantindo seu horário marcado<br/>
                    04 - Recebimento de e-mail com dados sobre o atendimento presencial e orientações sobre acesso a atendimentos online<br/>
                    05 - Esteja preparado para seu atendimento com 05min de antecedência, tanto o atendimento presencial ou on-line
                    <br/><br/>
                    Em caso de dúvidas, entre em contato pelos números - Fixo: (85) 3879.2444 | (85) 3121-0271 ou 98165-1765
                </p>
            </section>
            <Footer backgroundImage={require("../../assets/images/orange-footer.png")} />
        </div>
    );
}

export default Social;